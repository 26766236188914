import React, { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import TrackingContext from './TrackingContext';

const PageTracker = () => {
  const tracking = useContext(TrackingContext);
  const { pathname } = useLocation();
  const prevPathname = useRef<typeof pathname>(null);

  useEffect(() => {
    if (pathname !== prevPathname.current) {
      tracking.trackPageView(pathname);
      prevPathname.current = pathname;
    }
  }, [pathname, tracking]);

  return <></>;
};

export default PageTracker;
