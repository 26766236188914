import React, { useContext, useMemo } from 'react';
import { SessionSlotEmpty, SessionSlotLoading } from 'web/components/SessionSlot';
import SessionSlotsPreview from 'web/components/SessionSlotsPreview';
import TimeZoneContext from 'web/components/timezone/TimeZoneContext';
import useCronofyAvailablePersonalSlots from 'web/hooks/useCronofyAvailablePersonalSlots';
import { SlotTimeRange, SlotTimeZoneTimeRange } from './common';

const AvailableSlots = ({
  slots,
  onSelect,
  onViewCalendar,
  timeZone,
}: {
  slots: Array<{ start: Date; end: Date }>;
  onSelect: (slot: SlotTimeRange) => void;
  onViewCalendar: () => void;
  timeZone: string;
}) => {
  return <SessionSlotsPreview slots={slots} timeZone={timeZone} onSelect={onSelect} onViewCalendar={onViewCalendar} />;
};

const PersonalSessionsSlots = ({
  error,
  loading,
  slots,
  onSelect,
  onViewCalendar,
}: {
  error: Error | string;
  loading: boolean;
  slots: Array<{ start: Date; end: Date }>;
  service: introwise.Service;
  onSelect: (slot: SlotTimeZoneTimeRange) => void;
  onViewCalendar: () => void;
  currency: introwise.Currency;
}) => {
  const { timeZone } = useContext(TimeZoneContext);

  if (error) {
    return <SessionSlotEmpty>Failed to load available slots</SessionSlotEmpty>;
  }

  if (loading) {
    return <SessionSlotLoading />;
  }

  const onSlotSelect = (slot: { start: Date; end: Date }) => onSelect({ ...slot, timezone: timeZone });

  return <AvailableSlots slots={slots} timeZone={timeZone} onSelect={onSlotSelect} onViewCalendar={onViewCalendar} />;
};

const PersonalSessionsSlotsContainer = ({
  expertId,
  pageId,
  service,
  onSelect,
  onViewCalendar,
  currency,
}: {
  expertId: string;
  pageId: string;
  service: introwise.Service;
  onSelect: (slot: SlotTimeZoneTimeRange) => void;
  onViewCalendar: () => void;
  currency: introwise.Currency;
}) => {
  const now = useMemo(() => new Date(), []);

  const [slots, loading, error] = useCronofyAvailablePersonalSlots({
    expertId,
    pageId,
    serviceId: service.id,
    duration: service.duration,
    start: now,
    limit: 3,
  });

  return (
    <PersonalSessionsSlots
      slots={slots}
      loading={loading}
      error={error}
      service={service}
      onSelect={onSelect}
      onViewCalendar={onViewCalendar}
      currency={currency}
    />
  );
};

export { PersonalSessionsSlots };
export default PersonalSessionsSlotsContainer;
