import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { RegisterOptions, useForm, useFormContext } from 'react-hook-form';
import {
  Button,
  FormDescription,
  FormError,
  FormGroup,
  InlineButton,
  Input,
  Label,
  LabelText,
} from 'web/components/elements';
import Spinner from 'web/components/Spinner';
import themeClasses from 'web/styles/themeClasses.css';
import { FormValues } from '.';
import OrderContext from '../OrderContext';

const maxCodeLength = 40;

const codeValidationRules: RegisterOptions = {
  required: 'Required',
  minLength: {
    value: 1,
    message: 'Too short, code cannot be that short',
  },
  maxLength: {
    value: maxCodeLength,
    message: `Too long, code cannot be more than ${maxCodeLength} characters`,
  },
  pattern: /^[A-Za-z0-9]+_?[A-Za-z0-9]+$/,
  setValueAs: (v) => v.trim(),
};

const DiscountCodeInput = ({ initialValue, disabled }: { initialValue: string; disabled: boolean }) => {
  const [state, send] = useContext(OrderContext);
  const {
    register,
    trigger,
    getValues,
    setError,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm<{ code?: string }>({ defaultValues: { code: initialValue } });

  const removingRef = React.useRef(false);

  const code = watch('code');
  const empty = !code;

  const applied = !!state.context.order?.partner || !!state.context.order?.discount;
  const submitting = state.matches('draft.applyingDiscount');

  const onApply = useCallback(async () => {
    const res = await trigger();
    if (res) {
      send('APPLY_DISCOUNT', { code: getValues().code });
    }
  }, [getValues, send, trigger]);
  const onRemove = () => {
    removingRef.current = true;
    send('APPLY_DISCOUNT', { code: null });
  };
  const isPasted = useRef(false);
  const onPaste = () => {
    isPasted.current = true;
  };

  useEffect(() => {
    setError('code', { type: 'manual', message: state.context.discountError });
  }, [setError, state.context.discountError]);

  useEffect(() => {
    clearErrors('code');
  }, [clearErrors, code]);

  useEffect(() => {
    if (code && isPasted.current) {
      isPasted.current = false;
      onApply();
    }
  }, [code, onApply]);

  useEffect(() => {
    // Cleanup form after the discount has been removed
    if (!applied && removingRef.current) {
      removingRef.current = false;
      setValue('code', '');
    }
  }, [setValue, applied]);

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <Label>
          <LabelText>Discount code</LabelText>
          <Input
            {...register('code', codeValidationRules)}
            onPaste={onPaste}
            disabled={applied || submitting || disabled}
          />
        </Label>
        {!empty && (
          <div
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              paddingTop: 20,
              paddingRight: 20,
              paddingBottom: 7,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {applied ? (
              <Button sm secondary onClick={onRemove} disabled={submitting || disabled}>
                {submitting && <Spinner />}
                <span>Remove</span>
              </Button>
            ) : (
              <Button sm secondary onClick={onApply} disabled={submitting || disabled}>
                {submitting && <Spinner />}
                <span>Apply</span>
              </Button>
            )}
          </div>
        )}
      </div>
      {applied && (
        <FormDescription>
          <FontAwesomeIcon icon={faCheck} fixedWidth className={themeClasses({ marginRight: 1 })} />
          <span>Discount code applied</span>
        </FormDescription>
      )}
      {errors.code && <FormError>{errors.code.message}</FormError>}
    </div>
  );
};

const DiscountCodeForm = ({ submitting, initiallyOpen }: { submitting: boolean; initiallyOpen?: boolean }) => {
  const { getValues } = useFormContext<FormValues>();
  const [withCodeInput, setWithCodeInput] = useState(() => !!getValues('code') || !!initiallyOpen);

  return (
    <FormGroup>
      {withCodeInput && <DiscountCodeInput initialValue={getValues('code')} disabled={submitting} />}
      {!withCodeInput && (
        <>
          <InlineButton onClick={() => setWithCodeInput(true)}>Have a discount code?</InlineButton>
        </>
      )}
    </FormGroup>
  );
};

export default DiscountCodeForm;
