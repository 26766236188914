import { PortableTextComponentProps } from '@portabletext/react';
import { TypedObject } from '@portabletext/types';
import React from 'react';
import { FullWidthCaption, FullWidthFigure, FullWidthImage } from 'web/components/elements/FullWidthFigure';
import LazyImg from 'web/components/LazyImg';
import useSanityImageUrlBuilder from 'web/hooks/useSanityImageUrlBuilder';

type PortableTextImageNode = TypedObject & {
  _type: 'image';
  asset?: unknown;
  url?: string;
  alt?: string;
  caption?: string;
  metadata?: {
    dimensions: {
      aspectRatio: number;
      height: number;
      width: number;
    };
    lqip: string;
  };
};

type ImageSerializerProps = PortableTextComponentProps<PortableTextImageNode>;

const isPortableTextImageNode = (node: TypedObject): node is PortableTextImageNode => node._type === 'image';

const ImageSerializer = ({ value }: ImageSerializerProps) => {
  const imgUrl = useSanityImageUrlBuilder();
  const src = value.url || (value.asset ? imgUrl(value.asset).width(720).url() : undefined);
  return (
    <FullWidthFigure>
      <LazyImg
        as={FullWidthImage}
        lqip={value.metadata?.lqip}
        src={src}
        {...(value.metadata?.dimensions.aspectRatio && {
          width: 720,
          height: 720 / value.metadata.dimensions.aspectRatio,
        })}
        alt={value.alt}
      />
      {value.caption && <FullWidthCaption>{value.caption}</FullWidthCaption>}
    </FullWidthFigure>
  );
};

export { isPortableTextImageNode, PortableTextImageNode };
export default ImageSerializer;
