import type { SlotLabelContentArg } from '@fullcalendar/react';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  font-size: 12px;
  width: 56px;
  position: relative;
`;

const StyledDiv = styled.div`
  position: absolute;
  left: 0;
  top: -24px;
`;

const StyledAmPm = styled.span`
  font-size: 10px;
  :before {
    content: ' ';
  }
`;

const timeSlotContent = ({ text }: SlotLabelContentArg) => {
  const amPm = text.substring(text.length - 2, text.length);
  const digit = text.substring(0, text.length - amPm.length);
  return (
    <Container>
      <StyledDiv>
        {digit}
        <StyledAmPm>{amPm}</StyledAmPm>
      </StyledDiv>
    </Container>
  );
};

export default timeSlotContent;
