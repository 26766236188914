import React, { useContext } from 'react';
import BookedPackageContext from 'web/App/BookingPage/packages/BookedPackageContext';
import { InlineButton } from 'web/components/elements';
import useErrorHandler from 'web/hooks/useErrorHandler';
import themeClasses from 'web/styles/themeClasses.css';
import ExpiryNotice from './ExpiryNotice';
import { NoticeCard } from 'web/components/NoticeCard';

const BookedPackageInfo = ({ onManagePackage }: { onManagePackage: () => void }) => {
  const { bookedPackage, loading, error } = useContext(BookedPackageContext);
  useErrorHandler(error);

  return (
    <>
      {loading && <></>}
      {error && <p>Failed to load the package</p>}
      {bookedPackage && (
        <>
          <NoticeCard className={themeClasses({ marginTop: 4 })}>
            <p className={themeClasses({ marginY: 0 })}>
              Congratulations! Your package is active. Look for the included services below to book a session.{' '}
            </p>
            {bookedPackage.expiresAt && (
              <p className={themeClasses({ marginBottom: 0 })}>
                {' '}
                <ExpiryNotice expiresAt={bookedPackage.expiresAt} />
              </p>
            )}
            <p className={themeClasses({ marginBottom: 0 })}>
              <InlineButton onClick={onManagePackage}>See your package</InlineButton>
            </p>
          </NoticeCard>
        </>
      )}
    </>
  );
};

export default BookedPackageInfo;
