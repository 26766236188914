import type FullCalendar from '@fullcalendar/react';
import loadable from '@loadable/component';
import React from 'react';
import { isClientSide } from 'web/utils/ssr';

// FullCalendar might render different result client-side depending on the browser.
// To avoid hydration issues we don't use the server-rendered markup
const FullCalendarLoadable = loadable(() => import('web/components/calendar/common/FullCalendarBase'), {
  fallback: <div style={{ height: '70vh' }} />,
  ssr: false,
});

// There is a very high chance that the calendar will be used by the page importing it as loadable
// preload() is more performant then using webpackPreload, as it puts the component in the loadable cache directly
if (isClientSide) {
  setTimeout(() => FullCalendarLoadable.preload(), 4000);
}

export default FullCalendarLoadable;
export type { FullCalendar };
