import React, { BaseHTMLAttributes } from 'react';
import joinClassNames from 'web/utils/joinClassNames';
import flare, { FlareVariants } from './flare.css';

const Flare = React.forwardRef<
  HTMLSpanElement,
  React.DetailedHTMLProps<BaseHTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & FlareVariants
>(({ children, className, variant, ...props }, ref) => (
  <span className={joinClassNames(className, flare({ variant }))} {...props} ref={ref}>
    {children}
  </span>
));
Flare.displayName = 'Flare';

export default Flare;
