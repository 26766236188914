import { PortableTextComponentProps } from '@portabletext/react';
import { TypedObject } from '@portabletext/types';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import React from 'react';
import {
  aspectRatio,
  FullWidthCaption,
  FullWidthFigure,
  FullWidthIframeWrapper,
  FullWidthImage,
} from 'web/components/elements/FullWidthFigure';
import themeClasses from 'web/styles/themeClasses.css';
import { OembedDataPhoto, OembedDataVideo } from 'web/utils/oembed';

type PortableTextCloudAppNode = TypedObject & {
  _type: 'cloudapp';
  dropId: string;
  url: string;
  oembed: OembedDataVideo | OembedDataPhoto;
  caption?: string;
};

type CloudAppSerializerProps = PortableTextComponentProps<PortableTextCloudAppNode>;

const isPortableTextCloudAppNode = (node: TypedObject): node is PortableTextCloudAppNode => node._type === 'cloudapp';

const CloudAppSerializer = ({ value }: CloudAppSerializerProps) => {
  const { oembed, dropId, caption } = value;
  const cloudAppUrl = `https://share.getcloudapp.com/${dropId}?embed=true`;

  return (
    <FullWidthFigure>
      {oembed?.type === 'video' && (
        <FullWidthIframeWrapper
          dangerouslySetInnerHTML={{
            __html: oembed.html,
          }}
          style={assignInlineVars({
            [aspectRatio]: (oembed.width / oembed.height).toString(),
          })}
        />
      )}
      {oembed?.type === 'photo' && (
        <FullWidthImage
          src={oembed.url}
          alt={oembed.title}
          width={oembed.width}
          height={oembed.height}
          className={themeClasses({ height: 'auto', maxWidth: '100%' })}
        />
      )}
      {!oembed && (
        <FullWidthIframeWrapper>
          <iframe
            src={cloudAppUrl}
            width="560"
            height="315"
            frameBorder="0"
            allowFullScreen={true}
            title="CloudApp Drop"
          />
        </FullWidthIframeWrapper>
      )}
      {caption && <FullWidthCaption>{caption}</FullWidthCaption>}
    </FullWidthFigure>
  );
};

export { isPortableTextCloudAppNode, PortableTextCloudAppNode };
export default CloudAppSerializer;
