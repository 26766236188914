import { logEvent, setUserId } from 'firebase/analytics';
import React, { useContext, useMemo } from 'react';
import useAnalytics from 'web/components/FirebaseContext/useAnalytics';
import useErrorReporter from 'web/hooks/useErrorReporter';
import HubspotContext, { HubspotProvider } from './HubspotContext';
import { RewardfulProvider } from './RewardfulContext';
import SegmentContext, { SegmentProvider } from './SegmentContext';

type TrackingContextType = {
  trackPageView: (pathname: string) => void;
  trackEvent: (eventType: string, eventData?: Record<string, unknown>) => void;
  trackUser: (userId: string, userData?: { email?: string } & Record<string, unknown>) => void;
};

const TrackingContext = React.createContext<TrackingContextType>(null);

const TrackingProvider = ({ children }: { children: React.ReactNode }) => {
  const analytics = useAnalytics();
  const hubspot = useContext(HubspotContext);
  const segment = useContext(SegmentContext);
  const errorReporter = useErrorReporter();
  const value = useMemo<TrackingContextType>(
    () => ({
      trackUser: (userId, userData) => {
        try {
          setUserId(analytics, userId);
          if (userId) {
            hubspot?.setUser({
              email: userData.email,
              id: userId,
            });
            segment?.identify(userId, userData);
          } else {
            hubspot?.reset();
            segment?.reset();
          }
        } catch (err) {
          errorReporter.report(new Error(`Failed to track user: ${err}`));
        }
      },
      trackPageView: (pathname) => {
        try {
          hubspot?.onPathChanged(pathname);
          segment?.page();
        } catch (err) {
          errorReporter.report(new Error(`Failed to track path change: ${err}`));
        }
      },
      trackEvent: (eventType, eventData) => {
        try {
          logEvent(analytics, eventType, eventData);
          segment?.track(eventType, eventData);
        } catch (err) {
          errorReporter.report(new Error(`Failed to track event: ${err}`));
        }
      },
    }),
    [analytics, errorReporter, hubspot, segment],
  );
  return <TrackingContext.Provider value={value}>{children}</TrackingContext.Provider>;
};

const enabledProviders = [TrackingProvider, HubspotProvider, RewardfulProvider, SegmentProvider];

const TrackingProviderWrapper = ({ children }: { children: React.ReactNode }) => (
  <>
    {enabledProviders.reduce(
      (acc, Provider) => (
        <Provider>{acc}</Provider>
      ),
      children,
    )}
  </>
);

export { TrackingProviderWrapper as TrackingProvider };
export default TrackingContext;
