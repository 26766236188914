import React, { ReactNode, useContext, useMemo } from 'react';
import themeVars from 'web/styles/themeVars.css';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import ThemeContext from './ThemeContext';

const CustomThemeProvider = ({ children, theme }: { children: ReactNode; theme: any }) => {
  const [themeClass, themeStyle] = useContext(ThemeContext);
  const newThemeStyle = useMemo(() => assignInlineVars(themeVars, theme), [theme]);
  const newTheme = useMemo(
    () => [themeClass, { ...themeStyle, ...newThemeStyle }, theme] as const,
    [newThemeStyle, themeClass, themeStyle, theme],
  );
  return (
    <ThemeContext.Provider value={newTheme}>
      <div style={newThemeStyle}>{children}</div>
    </ThemeContext.Provider>
  );
};

export default CustomThemeProvider;
