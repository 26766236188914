import { initializeAnalytics } from 'firebase/analytics';
import { FirebaseApp, getApps, initializeApp } from 'firebase/app';
import {
  browserLocalPersistence,
  connectAuthEmulator,
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
} from 'firebase/auth';
import { initializePerformance } from 'firebase/performance';
import React from 'react';
import errorReporter from 'web/utils/error-reporter';
import settings from 'web/utils/settings';
import { isClientSide } from 'web/utils/ssr';
import { shouldUseEmulators } from './common';

const firebaseConfig = {
  apiKey: settings.firebase.apiKey,
  authDomain: settings.firebase.authDomain,
  databaseURL: settings.firebase.databaseURL,
  projectId: settings.firebase.projectId,
  storageBucket: settings.firebase.storageBucket,
  messagingSenderId: settings.firebase.messagingSenderId,
  appId: settings.firebase.appId,
  measurementId: settings.firebase.measurementId,
};

const initFirebase = () => {
  const firebase = initializeApp(
    firebaseConfig,
    isClientSide ? undefined : `[${getApps().length}]`, // server-side multiple apps from different bundles can co-exist
  );

  if (isClientSide) {
    try {
      initializeAnalytics(firebase);
    } catch (err) {
      errorReporter.report(new Error(`Failed to initialize firebase analytics: ${err}`));
    }
  }

  initializeAuth(firebase, {
    persistence: isClientSide
      ? [indexedDBLocalPersistence, browserLocalPersistence]
      : // Could also use in-memory persistence if needed server-side
        undefined,
  });

  if (shouldUseEmulators) {
    connectAuthEmulator(getAuth(firebase), 'http://localhost:9099');
  }

  if (isClientSide) {
    try {
      initializePerformance(firebase);
    } catch (err) {
      errorReporter.report(new Error(`Failed to initialize firebase performance: ${err}`));
    }
  }

  return firebase;
};

const firebase = initFirebase();

const FirebaseContext = React.createContext<FirebaseApp>(firebase);

export { firebase };
export default FirebaseContext;
