import React, { useContext, useMemo, useState } from 'react';
import { InlineButton, LinkStyled } from 'web/components/elements';
import ListUnstyled from 'web/components/elements/ListUnstyled';
import { NoticeCard } from 'web/components/NoticeCard';
import PackageList from 'web/components/packages/PackageList';
import UserContext from 'web/components/UserContext';
import themeClasses from 'web/styles/themeClasses.css';

const HiddenPackages = ({
  packages: packages,
  pageId,
  pageOwnerId,
}: {
  packages: introwise.Package[];
  pageId: string;
  pageOwnerId: string;
}) => {
  const { user } = useContext(UserContext);
  const isPageOwner = user?.uid === pageOwnerId;
  const [visibleHiddenPackage] = useState(() => {
    let res;
    try {
      const savedHiddenPackage = window.sessionStorage.getItem('introwise-hidden-package');
      const [savedPageId, savedPackageId] = savedHiddenPackage.split('/');
      res = savedPageId === pageId && packages.find((s) => s.id === savedPackageId);
    } catch (err) {
      // do nothing
    }
    return res;
  });

  const hiddenPackagesShown = isPageOwner ? packages : visibleHiddenPackage ? [visibleHiddenPackage] : [];

  return hiddenPackagesShown.length === 0 ? (
    <></>
  ) : (
    <NoticeCard className={themeClasses({ marginTop: 4 })}>
      <h4 className={themeClasses({ marginTop: 0 })}>Hidden packages</h4>
      <p>
        {isPageOwner
          ? 'These packages are not visible to your clients on this booking page. Use a direct link to share a hidden package.'
          : 'This package is not shown on this booking page, but was shared with you directly. Use the link below to book it.'}
      </p>
      <ListUnstyled>
        {hiddenPackagesShown.map((pack) => (
          <li key={pack.id}>
            <LinkStyled to={`packages/${pack.id}`}>{pack.title}</LinkStyled>
          </li>
        ))}
      </ListUnstyled>
    </NoticeCard>
  );
};

const PackagesList = ({
  onClick,
  onSelect,
  onPackageRestore,
  page,
}: {
  onClick: (pack: introwise.Package) => void;
  onSelect: (pack: introwise.Package) => void;
  onPackageRestore: () => void;
  page: introwise.Page;
}) => {
  const packagesSorted = useMemo(
    () => (page.packages ? Object.values(page.packages).sort((a, b) => a.order - b.order) : []),
    [page.packages],
  );

  if (!packagesSorted || packagesSorted.length === 0) return <></>;

  const packages = packagesSorted.filter((pack) => !pack.hidden);
  const hiddenPackages = packagesSorted.filter((pack) => pack.hidden);

  return (
    <>
      {packages.length > 0 && (
        <>
          <h3>Packages</h3>
          <PackageList onSelect={onSelect} onClick={onClick} packages={packages} />
          <div className={themeClasses({ marginTop: 4 })}>
            <small>
              Already booked a package? <InlineButton onClick={onPackageRestore}>Click here to restore</InlineButton>
            </small>
          </div>
        </>
      )}
      <HiddenPackages packages={hiddenPackages} pageId={page.id} pageOwnerId={page.ownerId} />
    </>
  );
};

export default PackagesList;
