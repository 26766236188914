import { PortableTextComponentProps } from '@portabletext/react';
import { TypedObject } from '@portabletext/types';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import React from 'react';
import {
  aspectRatio,
  FullWidthCaption,
  FullWidthFigure,
  FullWidthIframeWrapper,
} from 'web/components/elements/FullWidthFigure';
import { OembedDataVideo } from 'web/utils/oembed';

type PortableTextSendsparkNode = TypedObject & {
  _type: 'sendspark';
  shareId: string;
  url: string;
  oembed: OembedDataVideo;
  caption?: string;
};

type SendsparkSerializerProps = PortableTextComponentProps<PortableTextSendsparkNode>;

const isPortableTextSendsparkNode = (node: TypedObject): node is PortableTextSendsparkNode =>
  node._type === 'sendspark';

const SendsparkSerializer = ({ value }: SendsparkSerializerProps) => {
  const { oembed, shareId, caption } = value;

  return (
    <FullWidthFigure>
      {oembed.type === 'video' && (
        <FullWidthIframeWrapper
          dangerouslySetInnerHTML={{
            __html: oembed.html,
          }}
          style={assignInlineVars({
            [aspectRatio]: (oembed.width / oembed.height).toString(),
          })}
        />
      )}
      {!oembed && (
        <>
          <FullWidthIframeWrapper
            style={assignInlineVars({
              [aspectRatio]: (560 / 315).toString(),
            })}
          >
            <iframe
              src={`https://sendspark.com/embed/${shareId}`}
              width="560"
              height="315"
              frameBorder="0"
              allowFullScreen={true}
              title="Sendspark Video"
            />
          </FullWidthIframeWrapper>
        </>
      )}
      {caption && <FullWidthCaption>{caption}</FullWidthCaption>}
    </FullWidthFigure>
  );
};

export { isPortableTextSendsparkNode, PortableTextSendsparkNode };
export default SendsparkSerializer;
