import { collection, doc } from 'firebase/firestore';
import React from 'react';
import { Route, Routes, useParams } from 'react-router';
import { makeFake } from 'web/components/elements/Fake';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import { SessionSlotLoading } from 'web/components/SessionSlot';
import sc from 'web/components/styled';
import useFirestoreDocumentData from 'web/hooks/useFirestoreDocumentData';
import useInitialRouteData from 'web/hooks/useInitialRouteData';
import { firestoreGroupSessionConverter } from 'web/utils/convert';
import BackButton from 'web/components/BackButton';
import { GroupSessionSlot, Title } from './common';
import GroupSessionBooking from './GroupSessionBooking';
import GroupSessionInfo from './GroupSessionInfo';

const SubTitle = sc.small();
const FakeTitle = makeFake(Title, true);
const FakeSubtitle = makeFake(SubTitle);

const GroupSessionLoading = () => (
  <>
    <FakeTitle>Loading...</FakeTitle>
    <FakeSubtitle style={{ marginBottom: 12, display: 'inline-block' }}>
      <div>Loading...</div>
      <div>...</div>
    </FakeSubtitle>
    <div style={{ minHeight: 350 }}>
      <SessionSlotLoading />
    </div>
  </>
);
const GroupSessionNotFound = () => (
  <p>Session doesn&apos;t exist. Please check that you&apos;ve copied the URL correctly.</p>
);
const GroupSessionEnded = () => <p>This session has already ended. Please choose another one.</p>;

const GroupSession = ({
  page,
  onBook,
  onConfirmationBack,
}: {
  page: introwise.Page;
  onBook: (slot: GroupSessionSlot) => void;
  onConfirmationBack: () => void;
}) => {
  const { sessionId } = useParams();

  const initialData = useInitialRouteData<introwise.GroupSession>();

  const firestore = useFirestore();
  const [session, loading, error] = useFirestoreDocumentData(
    doc(collection(firestore, 'sessions'), sessionId).withConverter(firestoreGroupSessionConverter),
    { initialData },
  );

  const isGroup = session && session.type === 'group';
  const hasEnded = session && session.end < new Date();

  return (
    <>
      {error && <p>Failed to load the session.</p>}
      {!error && loading && (
        <>
          <BackButton initialText="To booking page" />
          <GroupSessionLoading />
        </>
      )}
      {!error && !loading && (!session || !isGroup) && (
        <>
          <BackButton initialText="To booking page" />
          <GroupSessionNotFound />
        </>
      )}
      {!error && !loading && session && isGroup && (
        <Routes>
          <Route
            index
            element={
              <>
                {hasEnded && (
                  <>
                    <BackButton initialText="To booking page" initialTo="../../.." />
                    <GroupSessionEnded />
                  </>
                )}
                {!hasEnded && (
                  <>
                    <BackButton initialText="To booking page" initialTo="../../.." />
                    <GroupSessionInfo session={session} page={page} onSelect={onBook} />
                  </>
                )}
              </>
            }
          />
          <Route
            path="book"
            element={
              <>
                {hasEnded && (
                  <>
                    <BackButton initialText="To booking page" initialTo="../../.." />
                    <GroupSessionEnded />
                  </>
                )}
                {!hasEnded && (
                  <GroupSessionBooking session={session} page={page} onConfirmationBack={onConfirmationBack} />
                )}
              </>
            }
          />
        </Routes>
      )}
    </>
  );
};

export default GroupSession;
