const supportedCurrencies: readonly introwise.Currency[] = [
  'AED',
  'AUD',
  'BGN',
  'BRL',
  'CAD',
  'CHF',
  'CZK',
  'DKK',
  'EUR',
  'GBP',
  'HKD',
  'HUF',
  'INR',
  'JPY',
  'NOK',
  'NZD',
  'MXN',
  'PLN',
  'RON',
  'SEK',
  'SGD',
  'THB',
  'USD',
];

const isZeroDecimal = (currency: introwise.Currency) =>
  [
    'BIF',
    'CLP',
    'DJF',
    'GNF',
    'JPY',
    'KMF',
    'KRW',
    'MGA',
    'PYG',
    'RWF',
    'UGX',
    'VND',
    'VUV',
    'XAF',
    'XOF',
    'XPF',
  ].includes(currency);

const isWholeNumber = (value: number) => value % 1 === 0;

const convertToDecimal = (amount: introwise.CurrencyAmount, currency: introwise.Currency) =>
  isZeroDecimal(currency) ? amount : amount / 100;

const convertFromDecimal = (value: number, currency: introwise.Currency): introwise.CurrencyAmount =>
  Math.floor(isZeroDecimal(currency) ? value : value * 100);

const formatter = (currency: introwise.Currency, opts?: Intl.NumberFormatOptions) =>
  Intl.NumberFormat([], { style: 'currency', currency, ...opts });

const formatCurrencyDecimal = (value: number, currency: introwise.Currency) =>
  formatter(currency, { ...(isWholeNumber(value) ? { maximumFractionDigits: 0 } : {}) }).format(value);

const formatCurrencyAmount = (amount: introwise.CurrencyAmount, currency: introwise.Currency) =>
  formatCurrencyDecimal(convertToDecimal(amount, currency), currency);

// TODO: Apart from formatting the currency symbol it could also be important to
// know the position of the symbol (before or after the number). Since we're assuming
// english-style formatting on our website, it always goes before, but when implementing
// proper i18n this could become important.
const formatCurrencySymbol = (currency: introwise.Currency, narrow?: boolean) =>
  formatter(currency, narrow && { currencyDisplay: 'narrowSymbol' })
    .formatToParts()
    .find((part) => part.type === 'currency')?.value || currency;

const formatCurrencyName = (currency: introwise.Currency) =>
  formatter(currency, { currencyDisplay: 'name' })
    .formatToParts()
    .find((part) => part.type === 'currency')?.value || currency;

type ChargeAmount = {
  [currency in introwise.Currency]: number;
};

const minChargeAmount: ChargeAmount = {
  AED: 200,
  AUD: 100,
  BGN: 200,
  BRL: 100,
  CAD: 100,
  CHF: 100,
  CZK: 3000,
  DKK: 500,
  EUR: 100,
  GBP: 100,
  HKD: 800,
  HUF: 30000,
  INR: 100,
  JPY: 100,
  NOK: 600,
  NZD: 100,
  MXN: 1000,
  PLN: 400,
  RON: 400,
  SEK: 600,
  SGD: 100,
  THB: 1000,
  USD: 100,
};

const exchangeRateToUsd: ChargeAmount = {
  AED: 3,
  AUD: 1.5,
  BGN: 1.5,
  BRL: 5,
  CAD: 1.5,
  CHF: 1,
  CZK: 20,
  DKK: 7,
  EUR: 1,
  GBP: 1,
  HKD: 7,
  HUF: 350,
  INR: 80,
  JPY: 100,
  NOK: 10,
  NZD: 1.5,
  MXN: 15,
  PLN: 4,
  RON: 4,
  SEK: 10,
  SGD: 1.5,
  THB: 35,
  USD: 1,
};

export {
  formatCurrencyDecimal,
  formatCurrencyAmount,
  isZeroDecimal,
  convertToDecimal,
  convertFromDecimal,
  formatCurrencySymbol,
  formatCurrencyName,
  minChargeAmount,
  supportedCurrencies,
  exchangeRateToUsd,
};
