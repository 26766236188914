import styled from 'styled-components';

const SessionSlotsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  display: grid;
  row-gap: 8px;
`;

export default SessionSlotsList;
