import { getTimeZones, TimeZone } from '@vvo/tzdb';

const formatTimeZoneLabel = (tzData: TimeZone) => {
  // add offset info
  const hours = Math.floor(Math.abs(tzData.currentTimeOffsetInMinutes) / 60);
  const minutes = Math.floor(Math.abs(tzData.currentTimeOffsetInMinutes) - hours * 60);
  const sign = Math.sign(tzData.currentTimeOffsetInMinutes) >= 0 ? '+' : '-';
  const name = tzData.alternativeName.substring(0, tzData.alternativeName.length - 5); // remove " Time" part
  return `GMT${sign}${(hours < 10 ? '0' : '') + hours}${minutes > 0 ? ':' + minutes : ''} ${name}`;
};

type TimezoneOption = {
  value: string;
  label: string;
  formatLabel: string;
  offset: number;
};

const getTimeZoneOptions = (errorReporter: { report: (error: Error) => void }): TimezoneOption[] => {
  try {
    return getTimeZones().map((tzData) => ({
      value: tzData.name,
      label: tzData.currentTimeFormat,
      formatLabel: formatTimeZoneLabel(tzData),
      offset: tzData.currentTimeOffsetInMinutes,
    }));
  } catch (err) {
    errorReporter.report(new Error(`Failed to generate the list of timezone options: ${err}`));
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return [
      {
        value: timeZone,
        label: timeZone.replace('_', ' '),
        formatLabel: timeZone.replace('_', ' '),
        offset: -new Date().getTimezoneOffset(),
      },
    ];
  }
};

export { TimezoneOption };
export default getTimeZoneOptions;
