import React from 'react';
import joinClassNames from 'web/utils/joinClassNames';
import { formControlWithError } from './formControl.css';
import textArea from './textArea.css';

const TextArea = React.forwardRef<
  HTMLTextAreaElement,
  React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> & {
    hasError?: boolean;
  }
>(({ className, children, hasError, ...inputProps }, ref) => (
  <textarea className={joinClassNames(className, textArea, hasError && formControlWithError)} {...inputProps} ref={ref}>
    {children}
  </textarea>
));

TextArea.displayName = 'TextArea';

export default TextArea;
