import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PayPalButtons } from '@paypal/react-paypal-js';
import React, { useContext } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { FormError, FormGroup } from 'web/components/elements';
import FormDescription from 'web/components/elements/FormDescription';
import themeClasses from 'web/styles/themeClasses.css';
import OrderContext from '../OrderContext';
import { FormValues } from './index';

const paypalValidationRules: RegisterOptions = {
  required: 'Required',
  validate: (paypal: { complete?: boolean }) => !!paypal.complete || 'Please check the PayPal details',
};

const PayPalForm = ({ submitting }: { submitting: boolean }) => {
  const {
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<FormValues>();

  const [state, send] = useContext(OrderContext);
  const order = state.context.order;

  return (
    <>
      <FormGroup>
        <Controller
          name="paypal"
          control={control}
          shouldUnregister
          rules={paypalValidationRules}
          render={({ field: { onChange, value } }) => (
            <>
              <PayPalButtons
                style={{ color: 'white', shape: 'rect', layout: 'horizontal', label: 'pay', tagline: false }}
                disabled={submitting}
                createOrder={async () => {
                  if (order?.payment.gateway !== 'paypal') {
                    throw new Error('Payment gateway is not PayPal');
                  }
                  if (!order?.payment.paypalOrderId) {
                    throw new Error('PayPal order ID is not set');
                  }
                  return order?.payment.paypalOrderId;
                }}
                onClick={(data, actions) => {
                  send('SET_PAYACTION', { payAction: null });
                  clearErrors('paypal');
                  onChange({ complete: false });
                  return actions.resolve();
                }}
                onApprove={async (data, actions) => {
                  send('SET_PAYACTION', { payAction: () => actions.order.capture() });
                  onChange({ complete: true });
                }}
                onError={(err) => {
                  setError('paypal', { type: 'manual', message: `PayPal payment failed :${err}` });
                }}
              />
              {errors.paypal && <FormError>{(errors.paypal as { message?: string }).message}</FormError>}
              <FormDescription>
                {value?.complete ? (
                  <>
                    <FontAwesomeIcon icon={faCheck} fixedWidth className={themeClasses({ marginRight: 1 })} />
                    <span>PayPal payment provided</span>
                  </>
                ) : (
                  'Use your PayPal account or a card to pay'
                )}
              </FormDescription>
            </>
          )}
        />
      </FormGroup>
    </>
  );
};

export default PayPalForm;
