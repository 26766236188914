import type FullCalendar from '@fullcalendar/react';
import React, { useContext, useRef } from 'react';
import { CalendarContainer, CalendarEmpty, CalendarLoading } from 'web/components/calendar/common/styles';
import TimeZoneContext from 'web/components/timezone/TimeZoneContext';
import dayHeaderContent from '../common/dayHeaderContent';
import FullCalendarLoadable from '../common/FullCalendarLoadable';
import timeSlotContent from '../common/timeSlotContent';
import { CalendarProps, ViewType } from '../common/types';
import Toolbar from '../toolbar/Toolbar';
import eventContent from './eventContent';

const Calendar = ({
  dateRange,
  onDateRangeChange,
  events,
  onDateSelect,
  onEventSelect,
  loading,
  className,
}: CalendarProps) => {
  const { timeZone, offset } = useContext(TimeZoneContext);
  const calendarRef = useRef<FullCalendar>(null);

  const handleToolbarPrev = () => {
    calendarRef.current.getApi().prev();
  };

  const handleToolbarNext = () => {
    calendarRef.current.getApi().next();
  };

  const handleToolbarToday = () => {
    // TODO: make scroll after slots loading and TODAY column blinking
    calendarRef.current.getApi().today();
    // Set a timeout to wait for FullCalendar to change the week view
    // before scrolling to the current time. Otherwise the change in
    // the view overrides the scroll position with the default one.
    setTimeout(() => {
      calendarRef.current.getApi().scrollToTime({
        hours: new Date(new Date().getTime() - (-new Date().getTimezoneOffset() - offset) * 60 * 1000).getHours(),
      });
    }, 200);
  };

  const onViewChanged = (viewType: ViewType) => {
    calendarRef.current.getApi().changeView(viewType);
  };

  return (
    <div className={className}>
      <Toolbar
        onToday={handleToolbarToday}
        onPrev={handleToolbarPrev}
        onNext={handleToolbarNext}
        dateRange={dateRange}
        onViewChanged={onViewChanged}
        loading={loading}
        onlyWeekView
      />
      <CalendarContainer>
        {loading && <CalendarLoading />}
        <FullCalendarLoadable
          type="view"
          ref={calendarRef}
          headerToolbar={{
            left: '',
            center: '',
            right: '',
          }}
          height="70vh"
          scrollTime={{ hours: 8 }}
          initialDate={dateRange?.start}
          timeZone={timeZone}
          datesSet={onDateRangeChange}
          initialView="timeGridWeek"
          slotLabelContent={timeSlotContent}
          dayHeaderContent={(args) => dayHeaderContent(args, timeZone)}
          eventContent={eventContent}
          // nowIndicator is not timezone-friendly, it doesn't rerender
          // when the timzone changes. Disable it until we find a fix
          // nowIndicator={true}
          editable={false}
          selectable={true}
          weekends={true}
          allDaySlot={false}
          firstDay={1}
          events={events}
          eventClick={onEventSelect}
          select={onDateSelect}
          unselectAuto={true}
          eventMinHeight={10}
        />
        {!loading && (!events || events.length === 0) && <CalendarEmpty onNext={handleToolbarNext} />}
      </CalendarContainer>
    </div>
  );
};

export default Calendar;
