import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import themeConstants from 'web/styles/themeConstants';
import themeVars from 'web/styles/themeVars.css';
import { AnchorUnstyled, InlineButton, LinkUnstyled } from '../elements';

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 20px;
  background-color: #fff;
  border: 1px solid ${themeConstants.borders.regular};
  border-radius: ${themeConstants.borderRadius.sm};
`;

const CopySvg = ({ className }: { className?: string }) => (
  <svg className={className} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.52698 1.89972C11.709 -0.28727 15.23 -0.682268 17.662 1.21972C20.575 3.49771 20.766 7.74269 18.235 10.2797L16.812 11.7047C16.419 12.0987 15.783 12.0987 15.39 11.7047C14.997 11.3117 14.997 10.6727 15.39 10.2797L16.812 8.85368C18.467 7.19669 18.377 4.44171 16.54 2.90371C14.913 1.54072 12.471 1.80072 10.972 3.30271L9.69898 4.5777C9.30698 4.9717 8.66898 4.9717 8.27698 4.5777C7.88398 4.18471 7.87998 3.54971 8.45398 2.97471L9.52698 1.89972ZM11.2929 7.29298L7.29288 11.293C6.90188 11.684 6.90188 12.316 7.29288 12.7069C7.48788 12.9019 7.74388 12.9999 7.99988 12.9999C8.25588 12.9999 8.51188 12.9019 8.70688 12.7069L12.7069 8.70697C13.0979 8.31597 13.0979 7.68398 12.7069 7.29298C12.3159 6.90198 11.6839 6.90198 11.2929 7.29298ZM10.2802 15.3965L9.00618 16.6675C7.50518 18.1644 5.05818 18.4224 3.43018 17.0655C1.59118 15.5335 1.50018 12.7875 3.15718 11.1345L4.58218 9.71449C4.97518 9.32249 4.97518 8.68649 4.58218 8.2935C4.18918 7.9025 3.55118 7.9025 3.15718 8.2935L1.88218 9.56649C-0.289821 11.7325 -0.670821 15.2165 1.20718 17.6405C3.47818 20.5734 7.73818 20.7714 10.2802 18.2374L11.7052 16.8175C12.0982 16.4245 12.0982 15.7895 11.7052 15.3965C11.3112 15.0035 10.6742 15.0035 10.2802 15.3965Z"
    />
  </svg>
);

const StyledInlineButton = styled(InlineButton)`
  flex: none;
  fill: ${themeVars.color.accentOutline};
  width: 20px;
  height: 20px;
  margin-left: 16px;
`;

const breatheAnimation = keyframes`
  0% { top: 0; opacity: 1; }
  66% { top: 0; opacity: 1; }
  100% { top: -20px; opacity: 0; }
`;

const Popup = styled.div`
  position: absolute;
  right: 0;
  outline: none;
  font-size: 12px;
  animation-name: ${breatheAnimation};
  animation-duration: 3s;
  animation-iteration-count: 1;
  border-radius: 4px;
  background-color: ${themeVars.color.accentOutline};
  color: #fff;
  padding: 0 8px;
`;

const ClippedText = styled.span`
  overflow-wrap: anywhere;
`;

const CopyLink = ({
  to,
  className,
  active,
  external,
}: {
  to: string;
  className?: string;
  active?: boolean;
  external?: boolean;
}) => {
  const { host, origin } = window.location;
  const pageUrl = external ? to.replace('https://', '') : `${host}${to}`;
  const copiedPageUrl = external ? to : `${origin}${to}`;
  const [linkCopied, setLinkCopied] = useState(false);

  const copyToBuffer = async () => {
    if ('clipboard' in navigator && 'writeText' in navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(copiedPageUrl);
        setLinkCopied(true);
        if (!linkCopied) {
          setTimeout(() => {
            setLinkCopied(false);
          }, 3000);
        }
      } catch (err) {
        // do nothing
      }
    }
  };

  return (
    <Container className={className}>
      <ClippedText>
        {active ? (
          external ? (
            <AnchorUnstyled href={to} target="_blank">
              {pageUrl}
            </AnchorUnstyled>
          ) : (
            <LinkUnstyled to={to}>{pageUrl}</LinkUnstyled>
          )
        ) : (
          pageUrl
        )}
      </ClippedText>
      <StyledInlineButton onClick={copyToBuffer}>
        <CopySvg />
      </StyledInlineButton>
      {linkCopied && <Popup>Copied to clipboard!</Popup>}
    </Container>
  );
};

export default CopyLink;
