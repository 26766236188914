import { logEvent, setCurrentScreen } from 'firebase/analytics';
import React, { useEffect } from 'react';
import useAnalytics from 'web/components/FirebaseContext/useAnalytics';

const ScreenTracker = ({ screenName }: { screenName: string }) => {
  const analytics = useAnalytics();
  useEffect(() => {
    setCurrentScreen(analytics, screenName);
    logEvent(analytics, 'screen_view', {
      app_name: 'web',
      screen_name: screenName,
      firebase_screen: screenName,
      firebase_screen_class: screenName,
    });
    return () => setCurrentScreen(analytics, undefined);
  }, [analytics, screenName]);

  return <></>;
};

export default ScreenTracker;
