/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import type { FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';

const snapshotOptions: SnapshotOptions = { serverTimestamps: 'estimate' };

const convertFirestoreSession = (session: introwise.FirestoreReadData<introwise.Session>): introwise.Session => ({
  ...session,
  createdAt: session.createdAt.toDate(),
  start: session.start.toDate(),
  end: session.end.toDate(),
  ...(session.rescheduledAt && { rescheduledAt: session.rescheduledAt.toDate() }),
  ...(session.rescheduledStart && { rescheduledStart: session.rescheduledStart.toDate() }),
  ...(session.rescheduledEnd && { rescheduledEnd: session.rescheduledEnd.toDate() }),
  ...(session.cancelledAt && { cancelledAt: session.cancelledAt.toDate() }),
});

export const firestoreSessionConverter = {
  fromFirestore: (val: QueryDocumentSnapshot) =>
    convertFirestoreSession({
      id: val.id,
      ...(val.data(snapshotOptions) as introwise.FirestoreReadData<introwise.Session>),
    }),
  toFirestore: (val: introwise.Session) => val,
};

const convertFirestoreGroupSession = (
  session: introwise.FirestoreReadData<introwise.GroupSession>,
): introwise.GroupSession => ({
  ...session,
  createdAt: session.createdAt.toDate(),
  start: session.start.toDate(),
  end: session.end.toDate(),
  ...(session.rescheduledAt && { rescheduledAt: session.rescheduledAt.toDate() }),
  ...(session.rescheduledStart && { rescheduledStart: session.rescheduledStart.toDate() }),
  ...(session.rescheduledEnd && { rescheduledEnd: session.rescheduledEnd.toDate() }),
  ...(session.cancelledAt && { cancelledAt: session.cancelledAt.toDate() }),
});

export const firestoreGroupSessionConverter = {
  fromFirestore: (val: QueryDocumentSnapshot) =>
    convertFirestoreGroupSession({
      id: val.id,
      ...(val.data(snapshotOptions) as introwise.FirestoreReadData<introwise.GroupSession>),
    }),
  toFirestore: (val: introwise.GroupSession) => val,
};

const convertFirestorePersonalSession = (
  session: introwise.FirestoreReadData<introwise.PersonalSession>,
): introwise.PersonalSession => ({
  ...session,
  createdAt: session.createdAt.toDate(),
  start: session.start.toDate(),
  end: session.end.toDate(),
  ...(session.rescheduledAt && { rescheduledAt: session.rescheduledAt.toDate() }),
  ...(session.rescheduledStart && { rescheduledStart: session.rescheduledStart.toDate() }),
  ...(session.rescheduledEnd && { rescheduledEnd: session.rescheduledEnd.toDate() }),
  ...(session.cancelledAt && { cancelledAt: session.cancelledAt.toDate() }),
});

export const firestorePersonalSessionConverter = {
  fromFirestore: (val: QueryDocumentSnapshot) =>
    convertFirestorePersonalSession({
      id: val.id,
      ...(val.data(snapshotOptions) as introwise.FirestoreReadData<introwise.PersonalSession>),
    }),
  toFirestore: (val: introwise.PersonalSession) => val,
};

const convertFirestorePackage = (pack: introwise.FirestoreReadData<introwise.Package>): introwise.Package => ({
  ...pack,
  createdAt: pack.createdAt.toDate(),
});

export const firestorePackageConverter = {
  fromFirestore: (val: QueryDocumentSnapshot) =>
    convertFirestorePackage({
      id: val.id,
      ...(val.data(snapshotOptions) as introwise.FirestoreReadData<introwise.Package>),
    }),
  toFirestore: (val: introwise.Package) => val,
};

const convertFirestoreBookedPackage = (
  pack: introwise.FirestoreReadData<introwise.BookedPackage>,
): introwise.BookedPackage => ({
  ...pack,
  createdAt: pack.createdAt.toDate(),
  ...(pack.expiresAt && { expiresAt: pack.expiresAt.toDate() }),
});

export const firestoreBookedPackageConverter = {
  fromFirestore: (val: QueryDocumentSnapshot) =>
    convertFirestoreBookedPackage({
      id: val.id,
      ...(val.data(snapshotOptions) as introwise.FirestoreReadData<introwise.BookedPackage>),
    }),
  toFirestore: (val: introwise.BookedPackage) => val,
};

const convertFirestorePage = (page: introwise.FirestoreReadData<introwise.Page>): introwise.Page => ({
  ...page,
  ...(page.createdAt && { createdAt: page.createdAt.toDate() }),
});

export const firestorePageConverter = {
  fromFirestore: (val: QueryDocumentSnapshot) =>
    convertFirestorePage({
      id: val.id,
      ...(val.data(snapshotOptions) as introwise.FirestoreReadData<introwise.Page>),
    }),
  toFirestore: (val: introwise.Page) => val,
};

const convertConnectedAccounts = (accounts: introwise.FirestoreReadData<introwise.User>['connectedAccounts']) =>
  Object.keys(accounts).reduce(
    (acc, key) => ({ ...acc, [key]: { ...accounts[key], connectedAt: accounts[key].connectedAt.toDate() } }),
    {} as introwise.User['connectedAccounts'],
  );

const convertFirestoreUser = (user: introwise.FirestoreReadData<introwise.User>): introwise.User => ({
  ...user,
  ...(user.createdAt && { createdAt: user.createdAt.toDate() }),
  ...(user.invite && {
    invite: {
      ...user.invite,
      ...(user.invite.createdAt && { createdAt: user.invite.createdAt.toDate() }),
      ...(user.invite.expireAt && { expireAt: user.invite.expireAt.toDate() }),
      ...(user.invite.acceptedAt && { acceptedAt: user.invite.acceptedAt.toDate() }),
    },
  }),
  ...(user.connectedAccounts && {
    connectedAccounts: convertConnectedAccounts(user.connectedAccounts),
  }),
});

export const firestoreUserConverter = {
  fromFirestore: (val: QueryDocumentSnapshot) =>
    convertFirestoreUser({
      id: val.id,
      ...(val.data(snapshotOptions) as introwise.FirestoreReadData<introwise.User>),
    }),
  toFirestore: (val: introwise.User) => val,
};

const convertFirestoreBooking = (booking: introwise.FirestoreReadData<introwise.Booking>): introwise.Booking => ({
  ...booking,
  ...(booking.createdAt && { createdAt: booking.createdAt.toDate() }),
});

export const firestoreBookingConverter = {
  fromFirestore: (val: QueryDocumentSnapshot) =>
    convertFirestoreBooking({
      id: val.id,
      ...(val.data(snapshotOptions) as introwise.FirestoreReadData<introwise.Booking>),
    }),
  toFirestore: (val: introwise.Booking) => val,
};

const convertFirestorePayment = (payment: introwise.FirestoreReadData<introwise.Payment>): introwise.Payment => ({
  ...payment,
  ...(payment.createdAt && { createdAt: payment.createdAt.toDate() }),
  ...(payment.refundedAt && { refundedAt: payment.refundedAt.toDate() }),
});

export const firestorePaymentConverter = {
  fromFirestore: (val: QueryDocumentSnapshot) =>
    convertFirestorePayment({
      id: val.id,
      ...(val.data(snapshotOptions) as introwise.FirestoreReadData<introwise.Payment>),
    }),
  toFirestore: (val: introwise.Payment) => val,
};

const convertFirestorePartner = (partner: introwise.FirestoreReadData<introwise.Partner>): introwise.Partner => ({
  ...partner,
  createdAt: partner.createdAt.toDate(),
});

export const firestorePartnerConverter: FirestoreDataConverter<introwise.Partner> = {
  fromFirestore: (val: QueryDocumentSnapshot<introwise.FirestoreReadData<introwise.Partner>>) =>
    convertFirestorePartner({
      id: val.id,
      ...val.data(snapshotOptions),
    }),
  toFirestore: (val: introwise.Partner) => val,
};

const convertFirestorePartnerCode = (
  partner: introwise.FirestoreReadData<introwise.PartnerCode>,
): introwise.PartnerCode => ({
  ...partner,
  createdAt: partner.createdAt.toDate(),
  ...(partner.lastRedeemedAt && { lastRedeemedAt: partner.lastRedeemedAt.toDate() }),
});

export const firestorePartnerCodeConverter: FirestoreDataConverter<introwise.PartnerCode> = {
  fromFirestore: (val: QueryDocumentSnapshot<introwise.FirestoreReadData<introwise.PartnerCode>>) =>
    convertFirestorePartnerCode({
      id: val.id,
      ...val.data(snapshotOptions),
    }),
  toFirestore: (val: introwise.PartnerCode) => val,
};

export const firestoreBalanceConverter: FirestoreDataConverter<introwise.Balance> = {
  fromFirestore: (val: QueryDocumentSnapshot<introwise.FirestoreReadData<introwise.Balance>>) => ({
    id: val.id,
    ...val.data(snapshotOptions),
  }),
  toFirestore: (val: introwise.Balance) => val,
};

const convertFirestoreTransaction = (
  transaction: introwise.FirestoreReadData<introwise.Transaction>,
): introwise.Transaction => ({
  ...transaction,
  createdAt: transaction.createdAt.toDate(),
  ...(transaction.availableOn && { availableOn: transaction.availableOn.toDate() }),
});

export const firestoreTransactionConverter: FirestoreDataConverter<introwise.Transaction> = {
  fromFirestore: (val: QueryDocumentSnapshot<introwise.FirestoreReadData<introwise.Transaction>>) =>
    convertFirestoreTransaction({
      id: val.id,
      ...val.data(snapshotOptions),
    }),
  toFirestore: (val: introwise.Transaction) => val,
};

const convertFirestoreDiscountCode = (
  discountCode: introwise.FirestoreReadData<introwise.DiscountCode>,
): introwise.DiscountCode => ({
  ...discountCode,
  createdAt: discountCode.createdAt.toDate(),
  ...(discountCode.lastRedeemedAt && { lastRedeemedAt: discountCode.lastRedeemedAt.toDate() }),
});

export const firestoreDiscountCodeConverter: FirestoreDataConverter<introwise.DiscountCode> = {
  fromFirestore: (val: QueryDocumentSnapshot<introwise.FirestoreReadData<introwise.DiscountCode>>) =>
    convertFirestoreDiscountCode({
      id: val.id,
      ...val.data(snapshotOptions),
    }),
  toFirestore: (val: introwise.DiscountCode) => val,
};

const convertFirestoreAvailabilityPeriod = (period: introwise.FirestoreReadData<introwise.AvailabilityPeriod>) => ({
  ...period,
  start: period.start.toDate(),
  end: period.end.toDate(),
});

export const firestoreAvailabilityRuleConverter: FirestoreDataConverter<introwise.AvailabilityRules> = {
  fromFirestore: (val) => ({ id: val.id, ...(val.data() as introwise.FirestoreReadData<introwise.AvailabilityRules>) }),
  toFirestore: (val) => val,
};

export const firestoreAvailabilityPeriodConverter: FirestoreDataConverter<introwise.AvailabilityPeriod> = {
  fromFirestore: (val: QueryDocumentSnapshot<introwise.FirestoreReadData<introwise.AvailabilityPeriod>>) =>
    convertFirestoreAvailabilityPeriod({
      id: val.id,
      ...val.data(),
    }),
  toFirestore: (val) => val,
};

const convertAppSumoActivation = (
  activation: introwise.FirestoreReadData<introwise.AppSumoActivation>,
): introwise.AppSumoActivation => ({
  ...activation,
  createdAt: activation.createdAt.toDate(),
  ...(activation.cancelledAt && { cancelledAt: activation.cancelledAt.toDate() }),
  ...(activation.claimedAt && { claimedAt: activation.claimedAt.toDate() }),
  ...(activation.updatedAt && { updatedAt: activation.updatedAt.toDate() }),
});

export const firestoreAppSumoActivationConverter: FirestoreDataConverter<introwise.AppSumoActivation> = {
  fromFirestore: (val: QueryDocumentSnapshot<introwise.FirestoreReadData<introwise.AppSumoActivation>>) =>
    convertAppSumoActivation({
      id: val.id,
      ...val.data(),
    }),
  toFirestore: (val) => val,
};

export const firestoreCallsUsageConverter: FirestoreDataConverter<introwise.CallsUsage> = {
  fromFirestore: (val: QueryDocumentSnapshot<introwise.FirestoreReadData<introwise.CallsUsage>>) => ({
    id: val.id,
    ...val.data(),
  }),
  toFirestore: (val) => val,
};

export const firestoreCallsUsageByMonthConverter: FirestoreDataConverter<introwise.CallsUsageByMonth> = {
  fromFirestore: (val: QueryDocumentSnapshot<introwise.FirestoreReadData<introwise.CallsUsageByMonth>>) => ({
    id: val.id,
    ...val.data(),
  }),
  toFirestore: (val) => val,
};

export const firestoreCallsUsageCreditsBalanceConverter: FirestoreDataConverter<introwise.CallsUsageCreditsBalance> = {
  fromFirestore: (val: QueryDocumentSnapshot<introwise.FirestoreReadData<introwise.CallsUsageCreditsBalance>>) => ({
    id: val.id,
    ...val.data(),
  }),
  toFirestore: (val) => val,
};

export const firestoreResourceRecordingConverter: FirestoreDataConverter<introwise.ResourceRecording> = {
  fromFirestore: (val: QueryDocumentSnapshot<introwise.FirestoreReadData<introwise.ResourceRecording>>) => ({
    id: val.id,
    ...val.data(),
    createdAt: val.data().createdAt.toDate(),
    ...(val.data().updatedAt && { updatedAt: val.data().updatedAt.toDate() }),
  }),
  toFirestore: (val) => val,
};

export const firestoreCallBackgroundConverter: FirestoreDataConverter<introwise.CallBackground> = {
  fromFirestore: (val: QueryDocumentSnapshot<introwise.FirestoreReadData<introwise.CallBackground>>) => ({
    id: val.id,
    ...val.data(),
    createdAt: val.data({ serverTimestamps: 'estimate' }).createdAt.toDate(),
  }),
  toFirestore: (val) => val,
};

export const firestoreCallFileConverter: FirestoreDataConverter<introwise.CallFile> = {
  fromFirestore: (val: QueryDocumentSnapshot<introwise.FirestoreReadData<introwise.CallFile>>) => ({
    id: val.id,
    ...val.data(),
    createdAt: val.data({ serverTimestamps: 'estimate' }).createdAt.toDate(),
  }),
  toFirestore: (val) => val,
};

export const firestoreClientConverter: FirestoreDataConverter<introwise.Client> = {
  fromFirestore: (val: QueryDocumentSnapshot<introwise.FirestoreReadData<introwise.Client>>) => ({
    id: val.id,
    ...val.data(),
    createdAt: val.data({ serverTimestamps: 'estimate' }).createdAt.toDate(),
    ...(val.data().lastActiveAt && { lastActiveAt: val.data().lastActiveAt.toDate() }),
    ...(val.data().lastSessionAt && { lastSessionAt: val.data().lastSessionAt.toDate() }),
  }),
  toFirestore: (val) => val,
};

export const firestoreNoteConverter: FirestoreDataConverter<introwise.Note> = {
  fromFirestore: (val: QueryDocumentSnapshot<introwise.FirestoreReadData<introwise.Note>>) => ({
    id: val.id,
    ...val.data(),
    createdAt: val.data({ serverTimestamps: 'estimate' }).createdAt.toDate(),
    ...(val.data().updatedAt && { updatedAt: val.data().updatedAt.toDate() }),
  }),
  toFirestore: (val) => val,
};

export const firestoreWorkflowConverter: FirestoreDataConverter<introwise.Workflow> = {
  fromFirestore: (val: QueryDocumentSnapshot<introwise.FirestoreReadData<introwise.Workflow>>) => ({
    id: val.id,
    ...val.data(),
    createdAt: val.data({ serverTimestamps: 'estimate' }).createdAt.toDate(),
  }),
  toFirestore: (val) => val,
};

export const firestoreWorkflowActionConverter: FirestoreDataConverter<introwise.WorkflowAction> = {
  fromFirestore: (val: QueryDocumentSnapshot<introwise.FirestoreReadData<introwise.WorkflowAction>>) => ({
    id: val.id,
    ...val.data(),
    createdAt: val.data({ serverTimestamps: 'estimate' }).createdAt.toDate(),
  }),
  toFirestore: (val) => val,
};

export const firestoreWorkflowScheduleConverter: FirestoreDataConverter<introwise.WorkflowSchedule> = {
  fromFirestore: (val: QueryDocumentSnapshot<introwise.FirestoreReadData<introwise.WorkflowSchedule>>) => ({
    id: val.id,
    ...val.data(),
    createdAt: val.data({ serverTimestamps: 'estimate' }).createdAt.toDate(),
    ...(val.data().updatedAt && { updatedAt: val.data().updatedAt.toDate() }),
    ...(val.data().lastTriggerAt && { lastTriggerAt: val.data().lastTriggerAt.toDate() }),
    executions: Object.fromEntries(
      Object.entries(val.data().executions).map(([key, value]) => [
        key,
        {
          ...value,
          executionAt: value.executionAt.toDate(),
        },
      ]),
    ),
  }),
  toFirestore: (val) => val,
};

const convertPaymentPlanPayment = (
  payment: introwise.FirestoreReadData<introwise.PaymentPlanPayment>,
): introwise.PaymentPlanPayment => ({
  ...payment,
  createdAt: payment.createdAt.toDate(),
  dueAt: payment.dueAt.toDate(),
  ...(payment.nextAttemptAt && { nextAttemptAt: payment.nextAttemptAt.toDate() }),
  ...(payment.processedAt && { processedAt: payment.processedAt.toDate() }),
});

export const firestorePaymentPlanConverter: FirestoreDataConverter<introwise.PaymentPlan> = {
  fromFirestore: (val: QueryDocumentSnapshot<introwise.FirestoreReadData<introwise.PaymentPlan>>) => ({
    id: val.id,
    ...val.data(),
    createdAt: val.data({ serverTimestamps: 'estimate' }).createdAt.toDate(),
    ...(val.data().completedAt && { completedAt: val.data().completedAt.toDate() }),
    ...(val.data().cancelledAt && { cancelledAt: val.data().cancelledAt.toDate() }),
    payments: Object.fromEntries(
      Object.entries(val.data().payments).map(([key, value]) => [key, convertPaymentPlanPayment(value)]),
    ),
  }),
  toFirestore: (val) => val,
};

export const firestoreTagConverter: FirestoreDataConverter<introwise.Tag> = {
  fromFirestore: (val: QueryDocumentSnapshot<introwise.FirestoreReadData<introwise.Tag>>) => ({
    id: val.id,
    ...val.data(),
    createdAt: val.data({ serverTimestamps: 'estimate' }).createdAt.toDate(),
  }),
  toFirestore: (val) => val,
};

export const firestoreLocationConverter: FirestoreDataConverter<introwise.Location> = {
  fromFirestore: (val: QueryDocumentSnapshot<introwise.FirestoreReadData<introwise.Location>>) => ({
    id: val.id,
    ...val.data(),
    createdAt: val.data({ serverTimestamps: 'estimate' }).createdAt.toDate(),
  }),
  toFirestore: (val) => val,
};

export const firestoreServiceConverter: FirestoreDataConverter<introwise.Service> = {
  fromFirestore: (val: QueryDocumentSnapshot<introwise.FirestoreReadData<introwise.Service>>) => ({
    id: val.id,
    ...val.data(),
    createdAt: val.data({ serverTimestamps: 'estimate' }).createdAt.toDate(),
  }),
  toFirestore: (val) => val,
};
