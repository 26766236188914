import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { useContext } from 'react';
import { shouldUseEmulators } from './common';
import FirebaseContext, { firebase } from './FirebaseContext';

if (shouldUseEmulators) {
  connectFunctionsEmulator(getFunctions(firebase), 'localhost', 5001);
}

const useFunctions = () => {
  const firebase = useContext(FirebaseContext);
  return getFunctions(firebase);
};

export default useFunctions;
