import React, { useLayoutEffect } from 'react';
import { isClientSide } from 'web/utils/ssr';

const ScrollToTopOnMountImpl = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <></>;
};

const ScrollToTopOnMount = () => (isClientSide ? <ScrollToTopOnMountImpl /> : <></>);

export default ScrollToTopOnMount;
