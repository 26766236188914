import { TypedObject } from '@portabletext/types';
import React from 'react';
import { OembedDataVideo } from 'web/utils/oembed';
import {
  aspectRatio,
  FullWidthCaption,
  FullWidthFigure,
  FullWidthIframe,
  FullWidthIframeWrapper,
} from 'web/components/elements/FullWidthFigure';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { PortableTextComponentProps } from '@portabletext/react';

type PortableTextYouTubeNode = TypedObject & {
  _type: 'youtube';
  /** @deprecated */
  id: string;
  videoId: string;
  caption?: string;
  url: string;
  oembed?: OembedDataVideo;
};

const isPortableTextYouTubeNode = (node: TypedObject): node is PortableTextYouTubeNode => node._type === 'youtube';

type YouTubeSerializerProps = PortableTextComponentProps<PortableTextYouTubeNode>;

const YouTubeSerializer = ({ value }: YouTubeSerializerProps) => {
  const { oembed, videoId, id, caption } = value;
  const youtubeUrl = `https://www.youtube.com/embed/${videoId || id}`;

  return (
    <FullWidthFigure>
      {oembed?.type === 'video' && (
        <FullWidthIframeWrapper
          dangerouslySetInnerHTML={{
            __html: oembed.html,
          }}
          style={assignInlineVars({
            [aspectRatio]: (oembed.width / oembed.height).toString(),
          })}
        />
      )}
      {!oembed && (
        <FullWidthIframeWrapper>
          <FullWidthIframe
            width="560"
            height="315"
            src={youtubeUrl}
            title="YouTube video player"
            loading="lazy"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </FullWidthIframeWrapper>
      )}
      {caption && <FullWidthCaption>{caption}</FullWidthCaption>}
    </FullWidthFigure>
  );
};

export { isPortableTextYouTubeNode, PortableTextYouTubeNode };
export default YouTubeSerializer;
