import { collection, doc } from 'firebase/firestore';
import React, { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import { useDocumentData } from 'web/hooks/firebase';
import useErrorHandler from 'web/hooks/useErrorHandler';
import { firestoreBookedPackageConverter } from 'web/utils/convert';

type BookedPackageContextType = {
  bookedPackage?: introwise.BookedPackage;
  loading: boolean;
  error: Error;
  disable: () => void;
  onPackageBook: (id: string) => void;
  isServiceAvailable: (serviceId: string, date?: Date) => boolean;
  isSeriesAvailable: (seriesId: string, date?: Date) => boolean;
  isSessionBooked: (sessionId: string) => boolean;
};

const BookedPackageContext = React.createContext<BookedPackageContextType>({
  bookedPackage: null,
  loading: false,
  error: null,
  disable: null,
  onPackageBook: null,
  isServiceAvailable: null,
  isSeriesAvailable: null,
  isSessionBooked: null,
});

const saveBookedPackageId = (pageId: string, bookedPackageId: string) => {
  try {
    window.sessionStorage.setItem('introwise-booked-package', `${pageId}/${bookedPackageId}`);
  } catch (err) {
    // do nothing
  }
};

const getBookedPackageId = (pageId: string) => {
  try {
    const sessionPackageId = window.sessionStorage.getItem('introwise-booked-package');
    if (sessionPackageId) {
      const [savedPageId, savedPackageId] = sessionPackageId.split('/');
      return savedPageId === pageId ? savedPackageId : null;
    }
  } catch (err) {
    // do nothing
  }
};

export const isServiceAvailableFromPackage = (
  bookedPackage: introwise.BookedPackage,
  serviceId: string,
  date?: Date,
) => {
  if (!bookedPackage) return false;

  if (bookedPackage.expiresAt) {
    if (new Date() > bookedPackage.expiresAt) {
      return false;
    }
    if (date && date > bookedPackage.expiresAt) {
      return false;
    }
  }

  const bookedService = bookedPackage.booked.personal[serviceId];

  return bookedService && bookedService.available > 0;
};

const BookedPackageProvider = ({ pageId, children }: { pageId: string; children: React.ReactNode }) => {
  const location = useLocation();
  const firestore = useFirestore();
  const [bookedPackageId, setBookedPackageId] = useState<string>(() => {
    const urlPackageId = new URLSearchParams(location.search).get('packageId');
    if (urlPackageId) {
      saveBookedPackageId(pageId, urlPackageId);
      return urlPackageId;
    } else {
      const savedPackageId = getBookedPackageId(pageId);
      if (savedPackageId) {
        return savedPackageId;
      }
    }
    return null;
  });
  const [bookedPackage, loading, error] = useDocumentData(
    bookedPackageId &&
      doc(collection(firestore, 'bookedPackages'), bookedPackageId).withConverter(firestoreBookedPackageConverter),
  );

  useErrorHandler(error);

  const onPackageBook = useCallback(
    (id: string) => {
      setBookedPackageId(id);
      saveBookedPackageId(pageId, id);
    },
    [pageId],
  );

  const isServiceAvailable = useCallback(
    (serviceId: string, date?: Date) => isServiceAvailableFromPackage(bookedPackage, serviceId, date),
    [bookedPackage],
  );

  const isSeriesAvailable = useCallback(
    (seriesId: string, date?: Date) => {
      if (!bookedPackage) return false;

      if (bookedPackage.expiresAt) {
        if (new Date() > bookedPackage.expiresAt) {
          return false;
        }
        if (date && date > bookedPackage.expiresAt) {
          return false;
        }
      }

      const bookedSeries = bookedPackage.booked.groupSeries[seriesId];

      return bookedSeries && bookedSeries.available > 0;
    },
    [bookedPackage],
  );

  const isSessionBooked = useCallback(
    (sessionId: string) => {
      if (!bookedPackage) return false;

      return bookedPackage.booked.sessionIds.includes(sessionId);
    },
    [bookedPackage],
  );

  const disable = useCallback(() => setBookedPackageId(null), []);

  const contextValue = useMemo(
    () => ({
      bookedPackage,
      loading,
      error,
      disable,
      onPackageBook,
      isServiceAvailable,
      isSeriesAvailable,
      isSessionBooked,
    }),
    [bookedPackage, loading, error, disable, onPackageBook, isServiceAvailable, isSeriesAvailable, isSessionBooked],
  );

  return <BookedPackageContext.Provider value={contextValue}>{children}</BookedPackageContext.Provider>;
};

export { BookedPackageProvider };
export default BookedPackageContext;
