import { httpsCallable } from 'firebase/functions';
import { useCallback } from 'react';
import useFunctions from 'web/components/FirebaseContext/useFunctions';

const useDiscountCode = () => {
  const functions = useFunctions();

  const verifyDiscountCode = useCallback(
    async (code: string, pageId: string, currency: introwise.Currency) => {
      const result = await httpsCallable<
        { code: string; currency: introwise.Currency; pageId: string },
        {
          discountCodeId: string;
          discount: introwise.DiscountCodeFixed | introwise.DiscountCodePercentage;
          error?: string;
        }
      >(
        functions,
        'pagesDiscountCodesVerify',
      )({ code, currency, pageId });
      return result.data;
    },
    [functions],
  );

  return verifyDiscountCode;
};

export default useDiscountCode;
