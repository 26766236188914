import React, { useContext } from 'react';
import BackButton from 'web/components/BackButton';
import { LinkButton } from 'web/components/elements';
import ScreenTracker from 'web/components/ScreenTracker';
import themeClasses from 'web/styles/themeClasses.css';
import { formatSessionDateLong, formatSessionTime } from 'web/utils/dateFormat';
import { Title } from '../common';
import ChangeBookingContext from './ChangeBookingContext';

const ChangeBookingInfo = ({ continueTo, page }: { continueTo: string; page: introwise.Page }) => {
  const { session, bookedPackage } = useContext(ChangeBookingContext);
  const notPersonal = session.type !== 'personal';
  const cancelled = session.status === 'cancelled';
  const now = new Date();
  const inThePast = session.start <= now;

  const canCancel =
    !page.cancellationPolicy?.cancellationDisabled &&
    (!page.cancellationPolicy?.cancellationMinNotice ||
      now.getTime() < session.start.getTime() - page.cancellationPolicy?.cancellationMinNotice * 60 * 1000);

  const canReschedule =
    !page.cancellationPolicy?.reschedulingDisabled &&
    (!page.cancellationPolicy?.reschedulingMinNotice ||
      now.getTime() < session.start.getTime() - page.cancellationPolicy?.reschedulingMinNotice * 60 * 1000) &&
    (!bookedPackage?.expiresAt || bookedPackage?.expiresAt > now);

  return (
    <>
      <ScreenTracker screenName="ChangeBooking" />
      <BackButton initialTo="../.." initialText="To booking page" />
      <Title>Change your booking</Title>
      {cancelled ? (
        <>
          <p>This session is already cancelled.</p>
          <p>You cannot make any changes to this session. But feel free to book another one.</p>
          <p>
            <LinkButton to={continueTo} size="md">
              Book another session
            </LinkButton>
          </p>
        </>
      ) : notPersonal ? (
        <>
          <p>This session cannot be cancelled or rescheduled.</p>
          <p>You cannot make any changes to this session. But feel free to book another one.</p>
          <p>
            <LinkButton to={continueTo} size="md">
              Book another session
            </LinkButton>
          </p>
        </>
      ) : inThePast ? (
        <>
          <p>
            Session started on <b>{formatSessionDateLong(session.start)}</b> at{' '}
            <b>{formatSessionTime(session.start)}</b>
          </p>
          <p>This session cannot be rescheduled or cancelled as it&apos;s already in the past.</p>
          <p>
            <LinkButton to={continueTo} size="md">
              Book another session
            </LinkButton>
          </p>
        </>
      ) : !canCancel && !canReschedule ? (
        <>
          <p>
            Session is scheduled for{' '}
            <b>
              {formatSessionDateLong(session.start)}, {formatSessionTime(session.start)}
            </b>
            . It can no longer be cancelled or rescheduled.
          </p>
          <p>
            <LinkButton to={continueTo} size="md">
              Book another session
            </LinkButton>
          </p>
        </>
      ) : (
        <>
          <p>
            Session is scheduled for{' '}
            <b>
              {formatSessionDateLong(session.start)}, {formatSessionTime(session.start)}
            </b>
            .{' '}
            {canCancel && canReschedule
              ? 'You can cancel or reschedule this session.'
              : canCancel
              ? 'You can cancel this session.'
              : 'You can reschedule this session.'}
          </p>
          <p>What would you like to do?</p>
          <div className={themeClasses({ display: 'flex', gap: 4, flexWrap: 'wrap' })}>
            {canCancel && (
              <LinkButton to="cancel" size="md" variant="secondary">
                Cancel
              </LinkButton>
            )}
            {canReschedule && (
              <LinkButton to="reschedule" size="md" variant="secondary">
                Reschedule
              </LinkButton>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ChangeBookingInfo;
