import React, { useLayoutEffect, useRef } from 'react';
import ClientSideOnly from './ClientSideOnly';

const ScrollIntoViewOnMountImpl = ({ alignToTop }: { alignToTop?: boolean }) => {
  const elemRef = useRef<HTMLDivElement>();
  useLayoutEffect(() => {
    elemRef.current.scrollIntoView(alignToTop);
  }, [alignToTop]);

  return <div ref={elemRef} />;
};

const ScrollIntoViewOnMount = ({ alignToTop }: { alignToTop?: boolean }) => {
  return (
    <ClientSideOnly>
      <ScrollIntoViewOnMountImpl alignToTop={alignToTop} />
    </ClientSideOnly>
  );
};

export default ScrollIntoViewOnMount;
