import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AnchorUnstyled, LinkUnstyled } from 'web/components/elements';
import Logo from 'web/components/Logo';
import UserContext from 'web/components/UserContext';
import settings from 'web/utils/settings';
import {
  content,
  footer,
  footerContainer,
  footerGrid,
  footerLink,
  footerNavUl,
  footerPowered,
  layout,
} from './bookingPageLayout.css';

const BookingPageFooter = ({ onCustomDomain }: { onCustomDomain?: boolean }) => {
  const { user } = useContext(UserContext);

  const appUrl = settings.customDomain.appUrl;

  const renderLink = (to: string, text: string) =>
    onCustomDomain ? (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a className={footerLink} href={`${appUrl}${to}`} target="_blank">
        {text}
      </a>
    ) : (
      <Link className={footerLink} to={to}>
        {text}
      </Link>
    );

  const logo = <Logo style={{ fontSize: 16 }} gradient={{ from: '#ffc79c', to: '#ff6771' }} />;

  const renderLogo = () =>
    onCustomDomain ? (
      <AnchorUnstyled href={appUrl} target="_blank">
        {logo}
      </AnchorUnstyled>
    ) : (
      <LinkUnstyled to="/" onClick={() => window.scrollTo(0, 0)} onKeyPress={() => window.scrollTo(0, 0)}>
        {logo}
      </LinkUnstyled>
    );

  return (
    <footer className={footer}>
      <div className={footerContainer}>
        <div className={footerGrid}>
          <div className={footerPowered}>
            <div>Powered by</div>
            {renderLogo()}
          </div>
          <div />
          <nav>
            <ul className={footerNavUl}>
              <li>{renderLink('/contact', 'Support')}</li>
              <li>{renderLink('/terms-of-service', 'Terms')}</li>
              <li>{renderLink('/privacy-policy', 'Privacy')}</li>
              <li>{renderLink(user ? '/home' : '/login', user ? 'Dashboard' : 'Sign in')}</li>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  );
};

const BookingPageLayout = ({
  children,
  withoutFooter,
  backgroundColor,
  onCustomDomain,
}: {
  children: React.ReactNode;
  withoutFooter?: boolean;
  backgroundColor?: string;
  onCustomDomain?: boolean;
}) => (
  <div className={layout} {...(backgroundColor ? { style: { backgroundColor } } : {})}>
    <main className={content}>{children}</main>
    {!withoutFooter && <BookingPageFooter onCustomDomain={onCustomDomain} />}
  </div>
);

export default BookingPageLayout;
