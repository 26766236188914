import React from 'react';
import joinClassNames from 'web/utils/joinClassNames';
import inputPrefix from './inputPrefix.css';

const InputPrefix = React.forwardRef<
  HTMLSpanElement,
  React.DetailedHTMLProps<React.BaseHTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & { hasError?: boolean }
>(({ children, className, ...props }, ref) => (
  <span className={joinClassNames(className, inputPrefix)} {...props} ref={ref}>
    {children}
  </span>
));

InputPrefix.displayName = 'InputPrefix';
export default InputPrefix;
