import imageUrlBuilder from '@sanity/image-url';
import { useMemo } from 'react';
import useSanity from './useSanity';

const useSanityImageUrlBuilder = () => {
  const client = useSanity();
  return useMemo(() => {
    const builder = imageUrlBuilder(client);
    return (source: unknown) => builder.image(source);
  }, [client]);
};

export default useSanityImageUrlBuilder;
