import React from 'react';
import { Control, Controller, FieldPath, FieldPathValue, FieldValues } from 'react-hook-form';
import { usePhoneInput } from 'react-international-phone';
import { FormError, FormGroup, Input, Label, LabelText } from 'web/components/elements';
import { phone } from 'phone';

const PhoneInput = ({
  onBlur,
  onChange,
  value,
  hasError,
}: React.InputHTMLAttributes<HTMLInputElement> & {
  onChange: (phone: string) => void;
  value: string;
  hasError?: boolean;
}) => {
  const { inputValue, handlePhoneValueChange, inputRef } = usePhoneInput({
    value,
    defaultCountry: 'us',
    disableDialCodeAndPrefix: true,
    onChange: (data) => {
      onChange(data.phone);
    },
  });

  return (
    <Input value={inputValue} onChange={handlePhoneValueChange} onBlur={onBlur} ref={inputRef} hasError={hasError} />
  );
};

const PhoneNumberField = <TFieldValues extends FieldValues>({
  control,
  name,
  initialValue,
}: {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
  initialValue?: FieldPathValue<TFieldValues, FieldPath<TFieldValues>> | null;
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={initialValue}
      render={({ field: { onChange, onBlur, value }, formState: { errors } }) => (
        <FormGroup>
          <Label>
            <LabelText>Phone number</LabelText>
            <PhoneInput onChange={onChange} onBlur={onBlur} value={value} hasError={!!errors[name]} />
          </Label>
          {errors[name] && <FormError>{errors[name].message as string}</FormError>}
        </FormGroup>
      )}
      rules={{
        required: 'Phone number is required',
        validate: (value: string) => phone(value).isValid || 'Invalid phone number',
      }}
    />
  );
};

export default PhoneNumberField;
