import React, { useContext } from 'react';
import {
  calculateAmountWithDiscount,
  Discount,
  formatDiscountReadable,
  formatPriceReadable,
  PackageProduct,
  ServiceProduct,
  SessionChangeProduct,
  SessionProduct,
  SidebarContainer,
} from 'web/App/BookingPage/common';
import TimeBlock from 'web/App/BookingPage/TimeBlock';
import TimeZoneContext from 'web/components/timezone/TimeZoneContext';
import themeVars from 'web/styles/themeVars.css';
import CheckoutContext from './CheckoutContext';
import BookedPackageContext from './packages/BookedPackageContext';

const SessionSidebar = ({
  page,
  product,
  discount,
}: {
  page: introwise.Page;
  product: ServiceProduct | SessionProduct;
  discount: Discount;
}) => {
  const { isServiceAvailable, isSeriesAvailable } = useContext(BookedPackageContext);
  const includedInPackage =
    (product?.type === 'service' && isServiceAvailable(product.serviceId, product.start)) ||
    (product?.type === 'session' && isSeriesAvailable(product.session.seriesId, product.session.start));
  const { timeZone } = useContext(TimeZoneContext);

  const { title, price, currency, start, end } =
    product.type === 'service'
      ? {
          title: product.service.title,
          price: product.service.price,
          currency: page.currency,
          start: product.start,
          end: new Date(product.start.getTime() + product.service.duration * 60 * 1000),
        }
      : {
          title: product.session.title,
          price: product.session.price,
          currency: product.session.currency,
          start: product.session.start,
          end: product.session.end,
        };

  return (
    <div>
      <div>
        <h3 style={{ marginBottom: 0 }}>{title}</h3>
        <span style={{ color: themeVars.color.halfMuted }}>with {page.displayName}</span>
      </div>
      <TimeBlock start={start} end={end} timeZone={timeZone} />
      <p>
        <b>
          {includedInPackage
            ? 'Free'
            : formatPriceReadable(calculateAmountWithDiscount(price, currency, discount), currency)}
        </b>
        <br />
        <small>
          {includedInPackage && '(included in the package)'}
          {discount && formatDiscountReadable(discount)}
        </small>
      </p>
    </div>
  );
};

const PackageSidebar = ({
  page,
  product,
  discount,
}: {
  page: introwise.Page;
  product: PackageProduct;
  discount: Discount;
}) => {
  const { price, currency, paymentPlan } = product.package;
  const firstMonthlyPayment = paymentPlan?.type === 'monthly';
  return (
    <div>
      <div>
        <h3 style={{ marginBottom: 0 }}>{product.package.title}</h3>
        <span style={{ color: themeVars.color.halfMuted }}>with {page.displayName}</span>
      </div>
      <p>
        <b>{formatPriceReadable(calculateAmountWithDiscount(price, currency, discount), currency)}</b>
        <br />
        <small>
          {firstMonthlyPayment && '(first monthly payment)'}
          {discount && formatDiscountReadable(discount)}
        </small>
      </p>
    </div>
  );
};

const SessionChangeSidebar = ({ page, product }: { page: introwise.Page; product: SessionChangeProduct }) => {
  const { start, end, title } = product.session;
  const { timeZone } = useContext(TimeZoneContext);
  return (
    <div>
      <div>
        <h3 style={{ marginBottom: 0 }}>{title}</h3>
        <span style={{ color: themeVars.color.halfMuted }}>with {page.displayName}</span>
      </div>
      <TimeBlock start={start} end={end} timeZone={timeZone} />
    </div>
  );
};

const Sidebar = ({ page }: { page: introwise.Page }) => {
  const [product, , discount] = useContext(CheckoutContext);

  return (
    <SidebarContainer>
      {(product?.type === 'service' || product?.type === 'session') && (
        <SessionSidebar page={page} product={product} discount={discount} />
      )}
      {product?.type === 'package' && <PackageSidebar page={page} product={product} discount={discount} />}
      {product?.type === 'session-change' && <SessionChangeSidebar page={page} product={product} />}
    </SidebarContainer>
  );
};

export default Sidebar;
