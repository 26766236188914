import { onAuthStateChanged, ParsedToken, signInWithCustomToken, signOut, User as FirebaseUser } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import React, { useContext, useEffect, useReducer } from 'react';
import useErrorReporter from 'web/hooks/useErrorReporter';
import { firestoreUserConverter } from 'web/utils/convert';
import FirebaseContext from './FirebaseContext';
import useFirestoreLazy from './FirebaseContext/useFirestoreLazy';
import useAuth from './FirebaseContext/useAuth';
import useFunctions from './FirebaseContext/useFunctions';

const initialContext = {
  loggingIn: true,
  user: undefined as FirebaseUser | undefined,
  loading: true,
  userData: undefined as introwise.User | undefined,
  claims: undefined as ParsedToken | undefined,
  claimsLoading: true,
};

const UserContext = React.createContext(initialContext);
type UserContextType = typeof initialContext;

type Action =
  | {
      type: 'login';
      user: FirebaseUser;
    }
  | {
      type: 'load-data';
      userData: introwise.User;
    }
  | {
      type: 'logout';
    }
  | {
      type: 'load-claims';
      claims: ParsedToken;
    };

const reducer = (state: UserContextType, action: Action): UserContextType => {
  switch (action.type) {
    case 'login':
      if (action.user === state.user) {
        return state;
      }
      return {
        ...state,
        loggingIn: false,
        loading: true,
        user: action.user,
        claims: undefined,
        claimsLoading: true,
      };
    case 'load-data':
      return {
        ...state,
        loading: false,
        userData: action.userData,
      };
    case 'load-claims':
      return {
        ...state,
        claims: action.claims,
        claimsLoading: false,
      };
    case 'logout':
      return {
        user: undefined,
        loggingIn: false,
        loading: false,
        userData: undefined,
        claims: undefined,
        claimsLoading: false,
      };
  }
};

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const auth = useAuth();
  const firebase = useContext(FirebaseContext);
  const lazyFirestore = useFirestoreLazy();
  const functions = useFunctions();
  const errorReporter = useErrorReporter();
  const [state, dispatch] = useReducer(reducer, {
    user: auth.currentUser,
    loggingIn: true,
    loading: true,
    userData: undefined,
    claims: undefined,
    claimsLoading: true,
  });

  useEffect(() => {
    return onAuthStateChanged(
      auth,
      (nextUser) => {
        if (nextUser) {
          dispatch({
            type: 'login',
            user: nextUser,
          });
        } else {
          dispatch({
            type: 'logout',
          });
        }
      },
      (err) => errorReporter.report(`Error while handling auth state: ${err}`),
    );
  }, [errorReporter, auth]);

  useEffect(() => {
    if (state.user?.uid && lazyFirestore) {
      return lazyFirestore.onSnapshot(
        lazyFirestore.doc(
          lazyFirestore.collection(lazyFirestore.getFirestore(firebase), 'users').withConverter(firestoreUserConverter),
          state.user?.uid,
        ),
        (snapshot) => {
          if (!snapshot.exists) {
            // Freshly created user accounts won't have a user document created - we ignore the error in this case
            if (Date.parse(state.user.metadata.creationTime) < Date.now() - 5000) {
              errorReporter.report("Error while loading user data: user doc doesn't exist.");
            }
          } else if (!snapshot.data()) {
            errorReporter.report('Error while loading user data: user doc is empty.');
          } else {
            dispatch({
              type: 'load-data',
              userData: snapshot.data(),
            });
          }
        },
        (err) => errorReporter.report(`Error while loading user data: ${err}`),
      );
    }
  }, [errorReporter, firebase, lazyFirestore, state.user]);

  useEffect(() => {
    const checkAdmin = async () => {
      try {
        const idTokenResult = await state.user.getIdTokenResult();
        dispatch({
          type: 'load-claims',
          claims: idTokenResult.claims,
        });
      } catch (err) {
        errorReporter.report(`Error while getting user claims: ${err}`);
      }
    };
    if (state.user) {
      void checkAdmin();
    }
  }, [errorReporter, state.user]);

  useEffect(() => {
    if (state.claims && state.claims.suUid && state.claims.suExp) {
      const timeoutMs = new Date(Number(state.claims.suExp)).getTime() - Date.now();
      const timeoutId = setTimeout(() => {
        const logout = async () => {
          // Try to switch back to the original user
          try {
            const res = await httpsCallable<unknown, { token: string }>(
              functions,
              'authLoginSubstituteUser',
            )({
              uid: state.claims.suUid,
            });
            const { token } = res.data;
            await signInWithCustomToken(auth, token);
          } catch (err) {
            errorReporter.report(`Failed to switch back from a substituted user on timeout: ${err}`);
            console.error(`Failed to switch back from a substituted user on timeout. Logging out instead.`);
            await signOut(auth);
            // we don't check error here, just let the app crash
          }
        };
        void logout();
      }, timeoutMs);
      return () => clearTimeout(timeoutId);
    }
  }, [errorReporter, auth, state.claims, functions]);

  useEffect(() => {
    errorReporter.setUser(state.user?.uid || undefined);
  }, [errorReporter, state.user?.uid]);

  return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
};

export { UserProvider };
export default UserContext;
