import type { Plugin } from '@segment/analytics-next';
import settings from 'web/utils/settings';

declare global {
  interface Window {
    signals?: {
      page: () => void;
      identify: (data: { email: string }) => void;
    };
  }
}

export const CommonRoomPlugin: Plugin = {
  name: 'Common Room Signals SDK',
  type: 'destination',
  version: '1.0.0',

  isLoaded: () => !!window.signals,

  load: async () => {
    if (typeof window === 'undefined') return;
    if (typeof window.signals !== 'undefined') return;
    if (!settings.commonRoom.siteId) return;
    const script = document.createElement('script');
    script.src = `https://cdn.cr-relay.com/v1/site/${settings.commonRoom.siteId}/signals.js`;
    script.async = true;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.signals = Object.assign(
      [],
      ['page', 'identify', 'form'].reduce(function (acc, method) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        acc[method] = function () {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line prefer-rest-params
          signals.push([method, arguments]);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return signals;
        };
        return acc;
      }, {}),
    );
    document.head.appendChild(script);
  },

  page: async (ctx) => {
    window.signals?.page();
    return ctx;
  },

  identify: async (ctx) => {
    window.signals?.identify({ email: ctx.event.traits.email });
    return ctx;
  },
};

export default CommonRoomPlugin;
