import sc from 'web/components/styled';
import { label, labelText } from './label.css';

const LabelText = sc.span(labelText);
LabelText.displayName = 'LabelText';

const Label = sc.label(label);
Label.displayName = 'Label';

export default Label;
export { LabelText };
