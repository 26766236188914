import React, { useContext } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import ManagePackage from 'web/App/BookingPage/packages/ManagePackage';
import Package from 'web/App/BookingPage/packages/Package';
import PackageRestore from 'web/App/BookingPage/packages/PackageRestore';
import catchAllRouteElement from 'web/components/catchAllRouteElement';
import BookingPageUrlContext from './BookingPageUrlContext';
import ChangeBookingCancel from './ChangeBooking/ChangeBookingCancel';
import ChangeBookingContainer from './ChangeBooking/ChangeBookingContainer';
import ChangeBookingInfo from './ChangeBooking/ChangeBookingInfo';
import ChangeBookingReschedule from './ChangeBooking/ChangeBookingReschedule';
import { Main, Slot } from './common';
import ExpertInfo from './ExpertInfo';
import GroupSession from './GroupSession';
import GroupSessionsAll from './GroupSessionsAll';
import ManagePackagePayments from './packages/ManagePackagePayments';
import PackageUse from './packages/PackageUse';
import PersonalSessionBooking from './PersonalSessionBooking';
import PersonalSessionsCalendar from './PersonalSessionsCalendar';
import SeriesInfo from './series/SeriesInfo';
import ServiceCalendar from './ServiceCalendar';
import ServiceInfo from './ServiceInfo';
import Sidebar from './Sidebar';

const MainController = ({ page }: { page: introwise.Page }) => {
  const navigate = useNavigate();
  const pageRootUrl = useContext(BookingPageUrlContext);

  const onGroupSessionSelect = (session: introwise.GroupSession) => navigate(`${pageRootUrl}sessions/${session.id}`);

  const onSlotBook = (slot: Slot) => {
    navigate(
      slot.isGroup === true
        ? `${pageRootUrl}sessions/${slot.sessionId}/book`
        : `${pageRootUrl}services/${slot.serviceId}/book?slotStart=${encodeURIComponent(slot.start.toISOString())}`,
    );
  };

  const onConfirmationBack = () => navigate(pageRootUrl);

  return (
    <>
      <Sidebar page={page} />
      <Main>
        <Routes>
          <Route
            index
            element={
              <>
                <ExpertInfo
                  page={page}
                  onViewPersonal={(serviceId) => navigate(`${pageRootUrl}calendar?serviceId=${serviceId}`)}
                  onViewGroup={() => navigate(`${pageRootUrl}sessions`)}
                  onPackageClick={(pack) => {
                    navigate(`${pageRootUrl}packages/${pack.id}`);
                  }}
                  onPackageRestore={() => {
                    navigate(`${pageRootUrl}packages/restore`);
                  }}
                  onPackageSelect={(pack) => {
                    navigate(`${pageRootUrl}packages/${pack.id}/checkout`);
                  }}
                  onClickGroup={onGroupSessionSelect}
                  onSelect={onSlotBook}
                  onManagePackage={() => navigate(`${pageRootUrl}packages/manage`)}
                />
              </>
            }
          />
          <Route path="sessions">
            <Route
              index
              element={<GroupSessionsAll page={page} onClick={onGroupSessionSelect} onSelect={onSlotBook} />}
            />
            <Route
              path=":sessionId/*"
              element={<GroupSession page={page} onConfirmationBack={onConfirmationBack} onBook={onSlotBook} />}
            />
          </Route>
          <Route path="calendar" element={<PersonalSessionsCalendar page={page} onSelect={onSlotBook} />} />
          <Route path="services">
            <Route index element={<Navigate to={pageRootUrl} replace />} />
            <Route path=":serviceId" element={<ServiceInfo page={page} onSelect={onSlotBook} />} />
            <Route path=":serviceId/calendar" element={<ServiceCalendar page={page} onSelect={onSlotBook} />} />
            <Route
              path=":serviceId/book"
              element={<PersonalSessionBooking page={page} onConfirmationBack={onConfirmationBack} />}
            />
          </Route>
          <Route path="series">
            <Route index element={<Navigate to={pageRootUrl} replace />} />
            <Route path=":seriesId" element={<SeriesInfo page={page} onSelect={onSlotBook} />} />
          </Route>
          <Route path="packages">
            <Route index element={<Navigate to={pageRootUrl} replace />} />
            <Route path="restore" element={<PackageRestore pageId={page.id} />} />
            <Route path="manage">
              <Route
                index
                element={
                  <ManagePackage
                    page={page}
                    onSelect={onSlotBook}
                    onViewCalendar={(serviceId) => navigate(`${pageRootUrl}calendar?serviceId=${serviceId}`)}
                    onViewSeries={(series) => navigate(`${pageRootUrl}series/${series.id}`)}
                  />
                }
              />
              <Route path="payments" element={<ManagePackagePayments page={page} />} />
            </Route>
            <Route path="use" element={<PackageUse />} />
            <Route
              path=":packageId/*"
              element={
                <Package
                  page={page}
                  onConfirmationBack={onConfirmationBack}
                  onSelect={(pack) => {
                    navigate(`${pageRootUrl}packages/${pack.id}/checkout`);
                  }}
                  onPackageRestore={() => {
                    navigate(`${pageRootUrl}packages/restore`);
                  }}
                />
              }
            />
          </Route>
          <Route path="bookings/:sessionId/:bookingId" element={<ChangeBookingContainer />}>
            <Route index element={<ChangeBookingInfo continueTo={pageRootUrl} page={page} />} />
            <Route path="cancel" element={<ChangeBookingCancel continueTo={pageRootUrl} />} />
            <Route path="reschedule" element={<ChangeBookingReschedule continueTo={pageRootUrl} page={page} />} />
          </Route>
          {catchAllRouteElement}
        </Routes>
      </Main>
    </>
  );
};

export default MainController;
