import React, { BaseHTMLAttributes } from 'react';
import joinClassNames from 'web/utils/joinClassNames';
import formFootnote from './formFootnote.css';

const FormFootnote = ({
  hasError,
  children,
  className,
  ...props
}: React.DetailedHTMLProps<BaseHTMLAttributes<HTMLDivElement>, HTMLDivElement> & { hasError?: boolean }) => (
  <div className={joinClassNames(className, formFootnote({ hasError }))} {...props}>
    {children}
  </div>
);

export default FormFootnote;
