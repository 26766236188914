import React from 'react';
import ListUnstyled from 'web/components/elements/ListUnstyled';
import PackageSlot from 'web/components/packages/PackageSlot';
import themeClasses from 'web/styles/themeClasses.css';

const PackageList = ({
  onClick,
  onSelect,
  packages,
}: {
  onClick?: (pack: introwise.Package) => void;
  onSelect?: (pack: introwise.Package) => void;
  packages: introwise.Package[];
}) => {
  return (
    <ListUnstyled className={themeClasses({ display: 'grid', rowGap: 2 })}>
      {packages.map((pack) => (
        <li key={pack.id}>
          <PackageSlot
            pack={pack}
            onClick={onClick && (() => onClick(pack))}
            buttonOnClick={onSelect && (() => onSelect(pack))}
            buttonText="Book"
            buttonPrimary
          />
        </li>
      ))}
    </ListUnstyled>
  );
};

export default PackageList;
