import { PortableTextMarkComponentProps } from '@portabletext/react';
import React, { createContext, useContext } from 'react';
import { AnchorStyled, LinkStyled } from '../elements';

const LinksContext = createContext<{ userGenerated: boolean }>({ userGenerated: false });

const isMailToLink = (href: string) => href.startsWith('mailto:');
const isExternalUrl = (href: string) => href.startsWith('http');

type LinkNode = {
  _type: 'link';
  href: string;
};

const Link = ({ href, children }: { href: string; children: React.ReactNode }) => {
  const { userGenerated } = useContext(LinksContext);
  if (isExternalUrl(href)) {
    return (
      <AnchorStyled
        href={href}
        target="_blank"
        rel={['noreferrer', ...(userGenerated ? ['nofollow', 'ugc'] : [])].join(' ')}
      >
        {children}
      </AnchorStyled>
    );
  } else if (isMailToLink(href)) {
    return <AnchorStyled href={href}>{children}</AnchorStyled>;
  } else {
    return <LinkStyled to={href}>{children}</LinkStyled>;
  }
};

const LinkSerializer = ({ value, children }: PortableTextMarkComponentProps<LinkNode>) => {
  const { href } = value;
  return <Link href={href}>{children}</Link>;
};

export { LinksContext };
export default LinkSerializer;
