import React, { useContext } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router';
import { DescriptionText, formatDurationMins, Slot, SlotTimeZoneTimeRange, Title } from 'web/App/BookingPage/common';
import BookedPackageContext from 'web/App/BookingPage/packages/BookedPackageContext';
import BackButton from 'web/components/BackButton';
import { InlineButton, LinkStyled } from 'web/components/elements';
import { NoticeCard } from 'web/components/NoticeCard';
import ScreenTracker from 'web/components/ScreenTracker';
import themeClasses from 'web/styles/themeClasses.css';
import BookingPageUrlContext from '../BookingPageUrlContext';
import GroupSessionsSlots from '../GroupSessionsSlots';
import PersonalSessionsSlots from '../PersonalSessionsSlots';
import ExpiryNotice from './ExpiryNotice';

const PersonalSessions = ({
  expertId,
  pageId,
  service,
  currency,
  onViewCalendar,
  onSelect,
}: {
  expertId: string;
  pageId: string;
  service: introwise.Service;
  currency: introwise.Currency;
  onViewCalendar: () => void;
  onSelect: (slot: Slot) => void;
}) => {
  const onSlotSelect = (slot: SlotTimeZoneTimeRange) =>
    onSelect({ ...slot, serviceId: service.id, isGroup: false, price: service.price, currency, title: service.title });

  return (
    <>
      <PersonalSessionsSlots
        expertId={expertId}
        pageId={pageId}
        service={service}
        onSelect={onSlotSelect}
        onViewCalendar={onViewCalendar}
        currency={currency}
      />
    </>
  );
};

const Service = ({
  page,
  serviceId,
  bookedSessions,
  packageSessions,
  onSelect,
  onViewCalendar,
}: {
  page: introwise.Page;
  serviceId: string;
  bookedSessions: introwise.BookedPackage['booked']['personal'][0];
  packageSessions: introwise.PackagePersonalSessions[0];
  onSelect: (slot: Slot) => void;
  onViewCalendar: () => void;
}) => {
  const service = page.services[serviceId];
  return (
    <>
      <h3>{service.title}</h3>
      <p>
        <b>
          {bookedSessions.available}/{packageSessions.count} available
        </b>
        <span>&nbsp;· </span>
        {formatDurationMins(service.duration)}
      </p>
      <p>{service.description}</p>
      <PersonalSessions
        expertId={page.ownerId}
        pageId={page.id}
        service={service}
        currency={page.currency}
        onSelect={onSelect}
        onViewCalendar={onViewCalendar}
      />
    </>
  );
};

const ManagePersonalSessions = ({
  page,
  bookedPackage,
  onSelect,
  onViewCalendar,
}: {
  page: introwise.Page;
  bookedPackage: introwise.BookedPackage;
  onSelect: (slot: Slot) => void;
  onViewCalendar: (serviceId: string) => void;
}) => {
  return (
    <>
      {bookedPackage.package.personalSessions && (
        <div>
          {Object.keys(bookedPackage.package.personalSessions).map((serviceId) => (
            <Service
              key={serviceId}
              page={page}
              serviceId={serviceId}
              bookedSessions={bookedPackage.booked.personal[serviceId]}
              packageSessions={bookedPackage.package.personalSessions[serviceId]}
              onSelect={onSelect}
              onViewCalendar={() => onViewCalendar(serviceId)}
            />
          ))}
        </div>
      )}
    </>
  );
};

const Series = ({
  page,
  seriesId,
  bookedSeries,
  includedSeries,
  onSelect,
  onViewAll,
}: {
  page: introwise.Page;
  seriesId: string;
  bookedSeries: introwise.BookedPackage['booked']['groupSeries'][0];
  includedSeries: introwise.PackageGroupSessionSeries[0];
  onSelect: (slot: Slot) => void;
  onViewAll: (series: introwise.Series) => void;
}) => {
  const series = page.series[seriesId];
  return (
    <>
      <h3>{series.title}</h3>
      <p>
        <b>
          {bookedSeries.available}/{includedSeries.count} available
        </b>
        <span>&nbsp;· </span>
        {formatDurationMins(series.duration)}
      </p>
      <p>{series.description}</p>
      <GroupSessionsSlots
        expertId={page.ownerId}
        pageId={page.id}
        seriesId={seriesId}
        onSelect={onSelect}
        onViewAll={() => onViewAll(series)}
        limit={3}
        hideTitle
        freeSlotsInline
      />
    </>
  );
};

const ManageGroupSeries = ({
  page,
  bookedPackage,
  onSelect,
  onViewSeries,
}: {
  page: introwise.Page;
  bookedPackage: introwise.BookedPackage;
  onSelect: (slot: Slot) => void;
  onViewSeries: (series: introwise.Series) => void;
}) => {
  return (
    <>
      {bookedPackage.package.groupSessionSeries && (
        <div>
          {Object.keys(bookedPackage.package.groupSessionSeries).map((seriesId) => (
            <Series
              key={seriesId}
              page={page}
              seriesId={seriesId}
              bookedSeries={bookedPackage.booked.groupSeries[seriesId]}
              includedSeries={bookedPackage.package.groupSessionSeries[seriesId]}
              onSelect={onSelect}
              onViewAll={onViewSeries}
            />
          ))}
        </div>
      )}
    </>
  );
};

const ManagePackage = ({
  page,
  onSelect,
  onViewCalendar,
  onViewSeries,
}: {
  page: introwise.Page;
  onSelect: (slot: Slot) => void;
  onViewCalendar: (serviceId: string) => void;
  onViewSeries: (series: introwise.Series) => void;
}) => {
  const { bookedPackage, loading, disable } = useContext(BookedPackageContext);
  const pageRootUrl = useContext(BookingPageUrlContext);
  const navigate = useNavigate();
  const location = useLocation();

  if (loading) {
    return <>Loading package...</>;
  }

  if (!bookedPackage) return <Navigate to={pageRootUrl} />;

  return (
    <>
      <ScreenTracker screenName="ExpertInfo" />
      <BackButton
        onClick={() => {
          // This is a workaround for the initial navigation, when after consuming the package id
          // the back button points to 'use' route again
          if ((location.state as { afterUse?: boolean } | undefined)?.afterUse) {
            navigate('..', { replace: true });
          } else {
            navigate(-1);
          }
        }}
      />
      <Title>{bookedPackage.package.title}</Title>
      {bookedPackage.package.description && <DescriptionText text={bookedPackage.package.description} />}
      <NoticeCard>
        <p className={themeClasses({ marginY: 0 })}>
          These are the services in your package. You can book sessions using your available package credits, or pay to
          purchase extra sessions.
        </p>
        {bookedPackage.expiresAt && (
          <p className={themeClasses({ marginBottom: 0 })}>
            <ExpiryNotice expiresAt={bookedPackage.expiresAt} />
          </p>
        )}
        {bookedPackage.paymentPlanScheduledId && (
          <p className={themeClasses({ marginBottom: 0 })}>
            This package is on a payment plan. <LinkStyled to="payments">View your payments</LinkStyled>
          </p>
        )}
      </NoticeCard>
      <ManagePersonalSessions
        page={page}
        bookedPackage={bookedPackage}
        onSelect={onSelect}
        onViewCalendar={onViewCalendar}
      />
      <ManageGroupSeries page={page} bookedPackage={bookedPackage} onSelect={onSelect} onViewSeries={onViewSeries} />
      <div>
        <p>
          Don&apos;t want to book all at once? Look for a special link in your confirmation email to use the remainder
          of your package later.
        </p>
        <InlineButton onClick={disable}>Don&apos;t use the package now</InlineButton>
      </div>
    </>
  );
};

export default ManagePackage;
