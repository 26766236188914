import { DayHeaderContentArg } from '@fullcalendar/react';
import { DateTime } from 'luxon';
import React from 'react';
import styled from 'styled-components';
import themeConstants from 'web/styles/themeConstants';
import themeVars from 'web/styles/themeVars.css';

const Container = styled.div<{ isListView: boolean }>`
  ${themeConstants.typography.regular};
  background-color: ${themeVars.calendar.primaryBackground};
  font-size: 12px;
  line-height: 1.2;
  display: flex;
  justify-content: center;
  ${({ isListView }) =>
    isListView
      ? `
      height: 40px; 
      align-items: center; 
      font-size: 14px;`
      : `flex-direction: column;`}
`;

const dayFormat = (date: Date, isListView: boolean, zone: string) => {
  const dt = DateTime.fromJSDate(date, { zone }).setLocale('en');
  return isListView ? dt.toLocaleString({ month: 'long', day: 'numeric' }) : dt.toLocaleString({ day: 'numeric' });
};

const weekDayFormat = (date: Date, zone: string) => {
  const dt = DateTime.fromJSDate(date, { zone }).setLocale('en');
  return dt.toLocaleString({ weekday: 'short' }).toUpperCase();
};

const TodayStyled = styled.span<{ isToday: boolean }>`
  font-size: 16px;
  ${({ isToday }) => isToday && `color: ${themeVars.calendar.todayHeader}; font-weight: bold;`}

  ${themeConstants.media.lg} {
    white-space: nowrap;
  }
`;

const StyledWeekDay = styled.span<{ isToday: boolean; isListView: boolean }>`
  ${({ isToday }) => isToday && `color: ${themeVars.calendar.todayHeader};`}
  ${({ isListView, isToday }) =>
    isListView
      ? isToday && `font-weight: bold;`
      : `font-size: 10px;
    letter-spacing: 1px;
    font-weight: 500;`}
`;

const dayHeaderContent = ({ date, isToday, view }: DayHeaderContentArg, timeZone: string) => {
  const isListView = view.type === 'listWeek';
  return (
    <Container isListView={isListView}>
      <TodayStyled isToday={isToday}>{dayFormat(date, isListView, timeZone)}</TodayStyled>
      <StyledWeekDay isToday={isToday} isListView={isListView}>
        {weekDayFormat(date, timeZone)}
      </StyledWeekDay>
    </Container>
  );
};

export default dayHeaderContent;
