export const isSameDay = (date1: Date, date2: Date) =>
  date1.getDate() == date2.getDate() &&
  date1.getMonth() == date2.getMonth() &&
  date1.getFullYear() == date2.getFullYear();

export const isToday = (date: Date) => isSameDay(date, new Date());
export const isTomorrow = (date: Date) => {
  const tomorrow = new Date();
  tomorrow.setDate(new Date().getDate() + 1);
  return isSameDay(date, tomorrow);
};

export const formatSessionDate = (date: Date) => date.toLocaleDateString('en', { month: 'short', day: 'numeric' });
export const formatSessionDateLong = (date: Date) =>
  date.toLocaleDateString('en', { month: 'short', day: 'numeric', year: 'numeric' });

export const formatSessionTime = (date: Date) =>
  date.toLocaleTimeString('en', {
    hour: 'numeric',
    minute: 'numeric',
  });

export const formatSessionDuration = (start: Date, end: Date) => {
  const diff = end.getTime() - start.getTime();
  const mins = Math.floor(diff / 1000 / 60);
  if (mins === 60) {
    return '1 hour';
  } else {
    return `${mins} mins`;
  }
};

export const formatDurationMins = (mins: number) => {
  if (mins < 60) {
    return `${mins} mins`;
  }
  if (mins === 60) {
    return `1 hour`;
  }
  if (mins > 60 && mins % 30 === 0) {
    return `${mins / 60} hours`;
  }
  return `${Math.floor(mins / 60)} h ${mins % 60} mins`;
};

export const formatSessionDateTimeString = (start: Date, end: Date) => {
  const startDay = formatSessionDate(start);
  const startTime = formatSessionTime(start);
  const endDay = formatSessionDate(end);
  const endTime = formatSessionTime(end);
  return startDay + ', ' + startTime + ' — ' + (startDay !== endDay ? endDay + ', ' : '') + endTime;
};

export const formatSessionStartEndTime = (start: Date, end: Date) =>
  `${formatSessionTime(start)} – ${formatSessionTime(end)}`;
