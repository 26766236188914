import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useContext, useEffect } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { FormError, FormGroup } from 'web/components/elements';
import FormDescription from 'web/components/elements/FormDescription';
import PaymentElementInput from 'web/components/form-fields/PaymentElementInput';
import themeClasses from 'web/styles/themeClasses.css';
import OrderContext from '../OrderContext';
import PayActionClientError from '../OrderContext/PayActionClientError';
import { FormValues } from './index';

const cardValidationRules: RegisterOptions = {
  required: 'Required',
  validate: (card: { complete?: boolean }) => !!card.complete || 'Please check the card details',
};

const StripeForm = ({ submitting }: { submitting: boolean }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<FormValues>();
  const [, send] = useContext(OrderContext);
  const stripe = useStripe();
  const elements = useElements();
  useEffect(() => {
    const payAction = async (order: introwise.Order) => {
      const returnUrlStr = window.location.href;
      const email = order.client?.email;
      const name =
        order.client?.firstName && order.client?.lastName
          ? `${order.client?.firstName} ${order.client?.lastName}`
          : null;
      const res = await stripe.confirmPayment({
        elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              ...(email && { email }),
              ...(name && { name }),
            },
          },
          return_url: returnUrlStr,
        },
        redirect: 'if_required',
      });
      if (res.error) {
        if (
          res.error.type === 'card_error' ||
          res.error.type === 'validation_error' ||
          (res.error.type === 'invalid_request_error' && res.error.code === 'payment_intent_authentication_failure')
        ) {
          throw new PayActionClientError(res.error.message);
        } else {
          throw new Error(res.error.message);
        }
      }
    };
    send('SET_PAYACTION', { payAction });
    return () => {
      send('SET_PAYACTION', { payAction: null });
    };
  }, [elements, send, stripe]);
  return (
    <>
      <FormGroup>
        <Controller
          name="card"
          control={control}
          shouldUnregister
          rules={cardValidationRules}
          render={({ field: { onChange } }) => (
            <PaymentElementInput
              onChange={(event) => {
                onChange({ complete: event.complete });
              }}
              disabled={submitting}
              hasErrors={!!errors.card}
            />
          )}
        />
        {errors.card && <FormError>{(errors.card as { message: string }).message}</FormError>}
        <FormDescription className={themeClasses({ marginTop: 1 })}>
          <FontAwesomeIcon icon={faLock} fixedWidth /> We handle your card details securely using{' '}
          <a
            href="https://stripe.com/customers"
            style={{ color: 'inherit' }}
            target="_blank"
            rel="noreferrer noopener nofollow"
          >
            Stripe
          </a>
          , a global online payment processing trusted by millions of businesses.
        </FormDescription>
      </FormGroup>
    </>
  );
};

export default StripeForm;
