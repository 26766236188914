import React from 'react';
import { RadioButton, RadioButtonGroup } from 'web/components/elements';

const ServiceSelector = ({
  services,
  defaultValue,
  onChange: onSelect,
}: {
  services: introwise.Service[];
  onChange: (id: string) => void;
  defaultValue: string;
}) => {
  return (
    <>
      <RadioButtonGroup role="radiogroup">
        {services.map((service) => (
          <RadioButton
            key={service.id}
            value={service.id}
            onSelect={onSelect}
            defaultChecked={service.id === defaultValue}
          >
            {service.title}
          </RadioButton>
        ))}
      </RadioButtonGroup>
    </>
  );
};

export default ServiceSelector;
