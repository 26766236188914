import { CollectionReference, FirestoreError, onSnapshot, Query, QuerySnapshot } from 'firebase/firestore';
import { useEffect, useMemo } from 'react';
import useLoadingState from './useLoadingState';
import useMemoQuery from './useMemoQuery';

const useCollectionData = <T,>(ref: Query<T> | CollectionReference<T> | null) => {
  const { error, loading, reset, setError, setValue, value } = useLoadingState<QuerySnapshot<T>, FirestoreError>();
  const memoQuery = useMemoQuery(ref, reset);
  useEffect(() => {
    if (memoQuery) {
      return onSnapshot(memoQuery, setValue, setError);
    } else {
      setValue(undefined);
    }
  }, [memoQuery, setError, setValue]);
  return useMemo(() => [value?.docs.map((doc) => doc.data()), loading, error] as const, [value, loading, error]);
};

export default useCollectionData;
