import { useCallback, useMemo, useReducer } from 'react';

export type LoadingState<T, E> = {
  error?: E;
  loading: boolean;
  reset: () => void;
  setError: (error: E) => void;
  setValue: (value?: T) => void;
  value?: T;
};

type ReducerState<T, E> = {
  error?: E;
  loading: boolean;
  value?: T;
};

type ErrorAction<E> = { type: 'error'; error: E };
type ResetAction = { type: 'reset' };
type ValueAction<T> = { type: 'value'; value: T };
type ReducerAction<T, E> = ErrorAction<E> | ResetAction | ValueAction<T>;

const defaultState = {
  loading: true,
  value: undefined as unknown,
  error: undefined as unknown,
};

const reducer = <T, E>(state: ReducerState<T, E>, action: ReducerAction<T, E>): ReducerState<T, E> => {
  switch (action.type) {
    case 'error':
      return {
        ...state,
        error: action.error,
        loading: false,
        value: undefined,
      };
    case 'reset':
      return defaultState as ReducerState<T, E>;
    case 'value':
      return {
        ...state,
        error: undefined,
        loading: false,
        value: action.value,
      };
    default:
      return state;
  }
};

const useLoadingState = <T, E>(): LoadingState<T, E> => {
  const [state, dispatch] = useReducer<React.Reducer<ReducerState<T, E>, ReducerAction<T, E>>>(
    reducer,
    defaultState as ReducerState<T, E>,
  );

  const reset = useCallback(() => dispatch({ type: 'reset' }), []);

  const setError = useCallback((error: E) => {
    dispatch({ type: 'error', error });
  }, []);

  const setValue = useCallback((value?: T) => {
    dispatch({ type: 'value', value });
  }, []);

  return useMemo(
    () => ({
      error: state.error,
      loading: state.loading,
      reset,
      setError,
      setValue,
      value: state.value,
    }),
    [state.error, state.loading, reset, setError, setValue, state.value],
  );
};

export default useLoadingState;
