import React from 'react';
import joinClassNames from 'web/utils/joinClassNames';
import { radioButton, radioButtonInner, radioButtonInput } from './radioButton.css';

const RadioButton = ({
  value,
  onSelect,
  defaultChecked,
  children,
  sm,
  disabled,
}: {
  value: string;
  onSelect: (value: string) => void;
  defaultChecked?: boolean;
  children: React.ReactNode;
  sm?: boolean;
  disabled?: boolean;
}) => (
  <label className={radioButton}>
    <input
      type="radio"
      className={radioButtonInput}
      name="service"
      value={value}
      onChange={(event) => onSelect(event.target.value)}
      defaultChecked={!!defaultChecked}
      disabled={disabled}
    />
    <div className={joinClassNames(radioButtonInner({ size: sm ? 'sm' : 'lg' }))}>{children}</div>
  </label>
);

export default RadioButton;
