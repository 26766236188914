import { FirebaseApp } from '@firebase/app';
import {
  connectFirestoreEmulator,
  getFirestore as getFirestoreImpl,
  initializeFirestore,
  doc,
  collection,
  onSnapshot,
} from 'firebase/firestore';
import { shouldUseEmulators } from './common';

let isInitialized = false;

const getFirestore = (firebase: FirebaseApp) => {
  if (shouldUseEmulators && !isInitialized) {
    initializeFirestore(firebase, {
      // Long polling is necessary when running in a Cypress environment.
      // It might have performance implications, but when working with local
      // emulators those are negligible.
      experimentalForceLongPolling: true,
    });
    connectFirestoreEmulator(getFirestoreImpl(firebase), 'localhost', 8080);
    isInitialized = true;
  }
  return getFirestoreImpl(firebase);
};

export { doc, collection, getFirestore, onSnapshot };
