import React from 'react';
import themeConstants from 'web/styles/themeConstants';
import joinClassNames from 'web/utils/joinClassNames';
import sc from 'web/components/styled';
import { fake, fakeAnimated } from './fake.css';

const Fake = React.forwardRef<
  HTMLDivElement,
  React.BaseHTMLAttributes<HTMLDivElement> & { height?: keyof typeof themeConstants.spaces; animated?: boolean }
>(({ children, className, style, height, animated, ...props }, ref) => (
  <div
    className={joinClassNames(className, fake, animated && fakeAnimated)}
    style={{ ...(height && { height: themeConstants.spaces[height] }), ...style }}
    {...props}
    ref={ref}
  >
    {children}
  </div>
));

Fake.displayName = 'Fake';

// eslint-disable-next-line react/display-name
export const makeFake = <P,>(component: React.ComponentType<P>, animated?: boolean) =>
  sc(component)(joinClassNames(fake, animated && fakeAnimated));
export default Fake;
