import { PaymentElement } from '@stripe/react-stripe-js';
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import React, { useState } from 'react';
import themeClasses from 'web/styles/themeClasses.css';
import joinClassNames from 'web/utils/joinClassNames';
import Fake from '../elements/Fake';
import { cardElementInputVariants } from './cardElementInput.css';

const FakeCardFields = () => (
  <div className={themeClasses({ display: 'grid', gridTemplateColumns: 2, gap: 4, marginTop: 4 })}>
    {[1, 2, 3, 4].map((i) => (
      <Fake height={16} animated key={i}>
        Loading...
      </Fake>
    ))}
  </div>
);

const FakePaymentElement = () => (
  <>
    <Fake height={16} animated>
      Loading...
    </Fake>
    <FakeCardFields />
  </>
);

const PaymentElementInput = ({
  onChange,
  disabled,
  hasErrors,
}: {
  onChange: (event: StripePaymentElementChangeEvent) => void;
  disabled?: boolean;
  hasErrors?: boolean;
}) => {
  const [ready, setReady] = useState(false);
  const [focused, setFocused] = useState(false);
  return (
    <label>
      <PaymentElement
        onChange={onChange}
        onReady={() => setReady(true)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        className={joinClassNames(
          themeClasses({ borderRadius: 'sm' }),
          hasErrors && !focused ? cardElementInputVariants['invalid'] : '',
        )}
        options={{
          readOnly: disabled,
          terms: {
            card: 'never',
          },
        }}
      />
      {!ready && <FakePaymentElement />}
    </label>
  );
};

export default PaymentElementInput;
