import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';
import themeClasses from 'web/styles/themeClasses.css';
import themeConstants from 'web/styles/themeConstants';
import themeVars from 'web/styles/themeVars.css';
import { formatCurrencyAmount } from 'web/utils/currency';
import { Button } from './elements';

type SessionSlot = {
  id?: string;

  /** Optional title for the session */
  title?: string;
  /** Price in decimals .01, e.g. cents */
  price: number;
  /** Currency code */
  currency: introwise.Currency;
  /** E.g. 'per session' or 'per person' */
  units: string;
  isAvailableFromPackage?: boolean;

  start: Date;
  end: Date;

  hidden?: boolean;
};

const InfoSide = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: center;
`;

const ButtonSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    min-width: 100%;
  }
`;

const Card = styled.div`
  display: flex;

  padding: 14px 20px;

  border-radius: ${themeConstants.borderRadius.sm};
  background-color: ${themeVars.backgrounds.sessionSlot};

  ${InfoSide} {
    flex-grow: 1;
  }

  ${ButtonSide} {
    margin-left: 20px;
    width: 100px;
    flex: none;
  }
`;

const Title = styled.div`
  ${themeConstants.typography.h4}
`;

const formatTimeRange = (start: Date, end: Date, timeZone?: string) =>
  start.toLocaleDateString('en', { timeZone: timeZone ? timeZone : undefined, month: 'short', day: 'numeric' }) +
  ', ' +
  start.toLocaleTimeString('en', {
    timeZone: timeZone ? timeZone : undefined,
    hour: 'numeric',
    minute: 'numeric',
  }) +
  ` — ` +
  end.toLocaleTimeString('en', { timeZone: timeZone ? timeZone : undefined, hour: 'numeric', minute: 'numeric' });

const LinkUnstyled = styled(Link)`
  &,
  &:focus,
  &:active,
  &:visited {
    color: inherit;
    text-decoration: none;
  }
`;

const SessionSlot = ({
  slot,
  timeZone,
  onClick,
  linkTo,
  buttonOnClick,
  buttonText,
  buttonPrimary,
  subButtonText,
  disabled,
}: {
  slot: SessionSlot;
  timeZone?: string;
  linkTo?: LinkProps['to'];
  onClick?: () => void;
  buttonOnClick?: () => void;
  buttonText?: string;
  buttonPrimary?: boolean;
  subButtonText?: string;
  disabled?: boolean;
}) => {
  const renderInfo = () => (
    <>
      {slot.title && (
        <Title>
          {slot.hidden && (
            <FontAwesomeIcon
              icon={faEyeSlash}
              fixedWidth
              className={themeClasses({ marginRight: 2 })}
              style={{ fontSize: '0.75em', verticalAlign: 'baseline' }}
              title="Hidden session"
            />
          )}
          {slot.title}
        </Title>
      )}
      <div>{formatTimeRange(slot.start, slot.end, timeZone)}</div>
      <div>
        <small>
          {slot.isAvailableFromPackage ? (
            <>
              <b>{formatCurrencyAmount(0, slot.currency)}</b> (included in the package)
            </>
          ) : (
            <>
              <b>{formatCurrencyAmount(slot.price, slot.currency)}</b> {slot.units}
            </>
          )}
        </small>
      </div>
    </>
  );
  return (
    <Card>
      <InfoSide>
        <div>
          {linkTo ? (
            <LinkUnstyled to={linkTo}>{renderInfo()}</LinkUnstyled>
          ) : onClick ? (
            <div style={{ cursor: 'pointer' }} role="button" onClick={onClick} onKeyPress={onClick} tabIndex={0}>
              {renderInfo()}
            </div>
          ) : (
            renderInfo()
          )}
        </div>
      </InfoSide>
      {buttonOnClick && (
        <ButtonSide>
          <Button primary={buttonPrimary} secondary={!buttonPrimary} onClick={buttonOnClick} disabled={disabled}>
            {buttonText}
          </Button>
          {subButtonText && (
            <div>
              <small>{subButtonText}</small>
            </div>
          )}
        </ButtonSide>
      )}
    </Card>
  );
};

const SessionSlotLoading = styled(Card)`
  ${themeConstants.fake.loading}
  ${themeConstants.fake.animated}
  min-height: 80px;
`;

const SessionSlotEmpty = styled.div`
  display: flex;
  padding: 14px 20px;
  border-radius: ${themeConstants.borderRadius.sm};
  background-color: #f5f5f5;
`;

export { SessionSlotLoading, SessionSlotEmpty };
export default SessionSlot;
