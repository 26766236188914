import { collection, limit, query, where } from 'firebase/firestore';
import React, { useLayoutEffect, useMemo } from 'react';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import { useOriginUrl } from 'web/components/OriginUrlContext';
import PageLoadingFallback from 'web/components/PageLoadingFallback';
import useFirestoreCollectionData from 'web/hooks/useFirestoreCollectionData';
import useInitialRouteData from 'web/hooks/useInitialRouteData';
import { firestorePageConverter } from 'web/utils/convert';
import toPunycode from 'web/utils/toPunycode';
import { BookingPageWithData } from './index';

const BookingPageCustomDomain = () => {
  const origin = useOriginUrl();
  const hostname = useMemo(() => new URL(origin).hostname, [origin]);
  const firestore = useFirestore();
  const initialPage = useInitialRouteData<introwise.Page>();
  const currentDomainPunycode = useMemo(() => toPunycode(hostname), [hostname]);
  const [snapshot, loading, error] = useFirestoreCollectionData(
    query(
      collection(firestore, 'pages'),
      where('customDomainsPunycodes', 'array-contains', currentDomainPunycode),
      limit(1),
    ).withConverter(firestorePageConverter),
    {
      initialData: initialPage && [initialPage],
    },
  );

  const page = snapshot && snapshot.length === 1 ? snapshot[0] : null;
  const primaryDomain = page && page.customDomains[0];
  const primaryDomainPunycode = page && page.customDomainsPunycodes[0];

  const shouldRedirect = primaryDomain && primaryDomainPunycode !== currentDomainPunycode;

  useLayoutEffect(() => {
    if (shouldRedirect) {
      const url = new URL(window.location.href);
      url.hostname = toPunycode(primaryDomain);
      window.location.href = url.href;
    }
  }, [shouldRedirect, primaryDomain]);

  return shouldRedirect ? (
    <PageLoadingFallback delay={0} />
  ) : (
    <BookingPageWithData page={page} loading={loading} error={error} onCustomDomain />
  );
};
export default BookingPageCustomDomain;
