import { PayPalScriptProvider, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import React, { useEffect, useRef } from 'react';
import settings from 'web/utils/settings';

const PaypalPaymentUpdater = ({ currency, merchantId }: { currency: string; merchantId?: string }) => {
  const [{ options }, dispatch] = usePayPalScriptReducer();

  const prevCurrencyRef = useRef('');
  const prevMerchantIdRef = useRef('');
  useEffect(() => {
    if (prevCurrencyRef.current !== currency || prevMerchantIdRef.current !== merchantId) {
      prevCurrencyRef.current = currency;
      prevMerchantIdRef.current = merchantId;
      dispatch({
        type: 'resetOptions',
        value: {
          ...options,
          currency,
          'merchant-id': merchantId,
        },
      });
    }
  }, [currency, dispatch, options, merchantId]);

  return <></>;
};

const PayPalProvider = ({
  children,
  currency,
  merchantId,
}: {
  children: React.ReactNode;
  currency: string;
  merchantId?: string;
}) => (
  <PayPalScriptProvider
    options={{
      'client-id': merchantId ? settings.payPal.platformClientId : settings.payPal.clientId,
      commit: true,
      currency,
      ...(merchantId && { 'merchant-id': merchantId, 'data-partner-attribution-id': settings.payPal.platformBnCode }),
    }}
  >
    {children}
  </PayPalScriptProvider>
);

export { PaypalPaymentUpdater };
export default PayPalProvider;
