const settings = {
  stripe: {
    apiKey: process.env.STRIPE_API_KEY,
    clientId: process.env.STRIPE_CLIENT_ID,
  },
  firebase: {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    appId: process.env.FIREBASE_APP_ID,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    measurementId: process.env.FIREBASE_MEASUREMENT_ID,
  },
  appcheck: {
    enabled: process.env.APPCHECK_ENABLED === 'true',
    recaptchaSiteKey: process.env.APPCHECK_RECAPTCHA_SITE_KEY,
  },
  stackdriver: {
    enabled: process.env.STACKDRIVER_ENABLED === 'true',
    projectId: process.env.STACKDRIVER_PROJECT_ID,
    apiKey: process.env.STACKDRIVER_API_KEY,
  },
  debug: {
    useEmulators: false,
    logDaily: process.env.DEBUG_LOG_DAILY === 'true',
  },
  hubspot: {
    hubId: process.env.HUBSPOT_HUB_ID,
    subscriptionFormId: process.env.HUBSPOT_SUBSCRIPTION_FORM_ID,
    freeTrialFormId: process.env.HUBSPOT_FREE_TRIAL_FORM_ID,
  },
  rewardful: {
    apiKey: process.env.REWARDFUL_API_KEY,
  },
  google: {
    clientId: process.env.GOOGLE_CLIENT_ID,
  },
  sanity: {
    projectId: 'j16ts6f2',
    dataset: 'production',
  },
  disqus: {
    shortname: 'introwise',
  },
  zoom: {
    clientId: process.env.ZOOM_CLIENT_ID,
  },
  algolia: {
    appId: process.env.ALGOLIA_APP_ID,
    apiKey: process.env.ALGOLIA_API_KEY,
    pagesIndex: process.env.ALGOLIA_PAGES_INDEX,
  },
  customDomain: {
    appUrl: process.env.CUSTOM_DOMAIN_APP_URL,
    cname: process.env.CUSTOM_DOMAIN_CNAME,
    ipAddress: process.env.CUSTOM_DOMAIN_IP_ADDRESS,
  },
  payPal: {
    clientId: process.env.PAYPAL_CLIENT_ID,
    platformClientId: process.env.PAYPAL_PLATFORM_CLIENT_ID,
    platformBnCode: process.env.PAYPAL_PLATFORM_BN_CODE,
  },
  microsoft: {
    clientId: process.env.MICROSOFT_CLIENT_ID,
  },
  frill: {
    widgetKey: process.env.FRILL_WIDGET_KEY,
  },
  segment: {
    writeKey: process.env.SEGMENT_WRITE_KEY,
  },
  zapier: {
    clientId: process.env.ZAPIER_CLIENT_ID,
  },
  commonRoom: {
    siteId: process.env.COMMON_ROOM_SITE_ID,
  },
};

export default settings;
