import React, { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LinkStyled, LinkUnstyled } from 'web/components/elements';
import ListUnstyled from 'web/components/elements/ListUnstyled';
import { NoticeCard } from 'web/components/NoticeCard';
import UserContext from 'web/components/UserContext';
import themeClasses from 'web/styles/themeClasses.css';
import { DescriptionText, formatDurationMins, formatPriceReadable, Slot } from '../common';
import GroupSessionsSlots from '../GroupSessionsSlots';

const HiddenSeries = ({
  hiddenSeries,
  pageId,
  pageOwnerId,
}: {
  hiddenSeries: introwise.Series[];
  pageId: string;
  pageOwnerId: string;
}) => {
  const { user } = useContext(UserContext);
  const isPageOwner = user?.uid === pageOwnerId;
  const [visibleHiddenSeries] = useState(() => {
    let res;
    try {
      const savedHiddenSeries = window.sessionStorage.getItem('introwise-hidden-series');
      const [savedPageId, savedSeriesId] = savedHiddenSeries.split('/');
      res = savedPageId === pageId && hiddenSeries.find((s) => s.id === savedSeriesId);
    } catch (err) {
      // do nothing
    }
    return res;
  });

  const hiddenSeriesShown = isPageOwner ? hiddenSeries : visibleHiddenSeries ? [visibleHiddenSeries] : [];

  return hiddenSeriesShown.length === 0 ? (
    <></>
  ) : (
    <NoticeCard className={themeClasses({ marginTop: 4 })}>
      <h4 className={themeClasses({ marginTop: 0 })}>Hidden series</h4>
      <p>
        {isPageOwner
          ? 'These series are not visible to your clients on this booking page. Use a direct link to share a hidden series.'
          : 'This series is not shown on this booking page, but was shared with you directly. Use the link below to book it.'}
      </p>
      <ListUnstyled>
        {hiddenSeriesShown.map((series) => (
          <li key={series.id}>
            <LinkStyled to={`series/${series.id}`}>{series.title}</LinkStyled>
          </li>
        ))}
      </ListUnstyled>
    </NoticeCard>
  );
};

const SeriesList = ({ page, onGroupSelect }: { page: introwise.Page; onGroupSelect: (slot: Slot) => void }) => {
  const navigate = useNavigate();

  const allSeries = useMemo(
    () => (page.series ? Object.values(page.series).sort((a, b) => a.order - b.order) : []),
    [page.series],
  );

  if (allSeries.length === 0) {
    return <></>;
  }

  const shownSeries = allSeries.filter((series) => !series.hidden);
  const hiddenSeries = allSeries.filter((series) => series.hidden);

  return (
    <>
      {shownSeries.map((series) => (
        <div key={series.id} className={themeClasses({ marginTop: 8 })}>
          <h3 id={series.id}>
            <LinkUnstyled to={`series/${series.id}`}>{series.title}</LinkUnstyled>
          </h3>
          <p>
            <b>{formatPriceReadable(series.price, page.currency)}</b>
            {series.price ? ' per person,' : ','} {formatDurationMins(series.duration)}
          </p>
          <DescriptionText text={series.description} />
          <GroupSessionsSlots
            pageId={page.id}
            expertId={page.ownerId}
            seriesId={series.id}
            onSelect={onGroupSelect}
            onViewAll={() => navigate(`series/${series.id}`)}
            hideTitle
            limit={3}
            freeSlotsInline
          />
        </div>
      ))}
      <HiddenSeries hiddenSeries={hiddenSeries} pageId={page.id} pageOwnerId={page.ownerId} />
    </>
  );
};

export default SeriesList;
