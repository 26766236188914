import React from 'react';
import joinClassNames from 'web/utils/joinClassNames';

// TODO: can we reuse this function for both intrinsics and components?
const intrinsicElement = <P extends keyof JSX.IntrinsicElements>(
  component: P,
  styledClassName?: string,
  styledStyle?: React.CSSProperties,
): React.FunctionComponent<JSX.IntrinsicElements[P]> =>
  // eslint-disable-next-line react/display-name
  React.forwardRef(({ children, className, style, ...props }, ref) =>
    React.createElement(
      component,
      {
        ...props,
        className: joinClassNames(styledClassName, className),
        style: { ...styledStyle, ...style },
        ref,
      },
      children,
    ),
  );

type StyledProxy = {
  [K in keyof JSX.IntrinsicElements]: (
    className?: string,
    style?: React.CSSProperties,
  ) => React.FunctionComponent<JSX.IntrinsicElements[K]>;
};

type StyleableProps = { children?: React.ReactNode; className?: string; style?: React.CSSProperties };
type StyledFunction = {
  <P extends StyleableProps>(component: React.ComponentType<P>): (
    className?: string,
    style?: React.CSSProperties,
  ) => React.ForwardRefExoticComponent<P>;
};

type Styled = StyledFunction & StyledProxy;

const sc = new Proxy(
  (<P,>(component: React.ComponentType<P>) =>
    (styledClassName?: string, styledStyle?: React.CSSProperties) =>
      // eslint-disable-next-line react/display-name
      React.forwardRef(({ className, style, children, ...props }: P & StyleableProps, ref) =>
        React.createElement(
          component,
          {
            ...props,
            className: joinClassNames(styledClassName, className),
            ...((styledStyle || style) && { style: { ...styledStyle, ...style } }),
            ref,
          } as unknown as P,
          children,
        ),
      )) as StyledFunction,
  {
    get: (_, name: keyof JSX.IntrinsicElements) => (className?: string, style?: React.CSSProperties) =>
      intrinsicElement(name, className, style),
  },
) as Styled;

export default sc;
