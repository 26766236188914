import { doc, increment, runTransaction, serverTimestamp } from 'firebase/firestore';
import React, { useCallback, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import BackButton from 'web/components/BackButton';
import BookingCalendar from 'web/components/calendar/BookingCalendar';
import {
  Button,
  FormError,
  FormFootnote,
  FormGroup,
  Label,
  LabelText,
  LinkButton,
  LinkStyled,
  TextArea,
} from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import FormValueLength from 'web/components/form-fields/FormValueLength';
import { NoticeCard } from 'web/components/NoticeCard';
import ScreenTracker from 'web/components/ScreenTracker';
import TimeZone from 'web/components/timezone/TimeZone';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import themeClasses from 'web/styles/themeClasses.css';
import { firestorePersonalSessionConverter } from 'web/utils/convert';
import { formatSessionDate, formatSessionDateLong, formatSessionTime } from 'web/utils/dateFormat';
import { SlotTimeRange, SlotTimeZoneTimeRange, Title } from '../common';
import { isServiceAvailableFromPackage } from '../packages/BookedPackageContext';
import ChangeBookingContext from './ChangeBookingContext';

type FormValues = {
  message: string;
};

const maxMessageLength = 800;

const getDaysRemaining = (date: Date) => (date.getTime() - Date.now()) / (1000 * 3600 * 24);

const ReschedulingForm = ({
  onSubmit,
  submitting,
  page,
  serviceId,
  bookedPackage,
}: {
  onSubmit: (values: FormValues & SlotTimeRange) => void;
  submitting: boolean;
  page: introwise.Page;
  serviceId: string;
  bookedPackage: introwise.BookedPackage;
}) => {
  const { register, control, handleSubmit } = useForm<FormValues>({ defaultValues: { message: '' } });
  const { services, currency } = page;
  const { duration, price } = services[serviceId];
  const [slotSelected, setSlotSelected] = useState<SlotTimeZoneTimeRange>();

  const slotPrice = useCallback(
    ({ start }: { start: Date }) => {
      if (bookedPackage) {
        return isServiceAvailableFromPackage(bookedPackage, serviceId, start) ? 0 : price;
      } else {
        return price;
      }
    },
    [bookedPackage, serviceId, price],
  );

  const submitValues = useCallback(
    (values: FormValues) => {
      if (slotSelected) {
        onSubmit({ ...values, ...slotSelected });
      } else {
        throw new Error(`Slot is required`);
      }
    },
    [onSubmit, slotSelected],
  );

  return (
    <form onSubmit={handleSubmit(submitValues)}>
      <fieldset disabled={submitting}>
        {!slotSelected && (
          <>
            {bookedPackage?.expiresAt && (
              <NoticeCard className={themeClasses({ marginTop: 4 })}>
                <p className={themeClasses({ marginY: 0 })}>
                  This session was booked using a package that expires in{' '}
                  <b>
                    {((days) => (days < 1 ? `less than a day` : days < 1.5 ? `one day` : `${Math.round(days)} days`))(
                      getDaysRemaining(bookedPackage?.expiresAt),
                    )}
                  </b>
                  . Sessions from this package must be scheduled for before{' '}
                  {formatSessionDate(bookedPackage?.expiresAt)}, {formatSessionTime(bookedPackage?.expiresAt)}.
                </p>
              </NoticeCard>
            )}
            <BookingCalendar
              expertId={page.ownerId}
              pageId={page.id}
              serviceId={serviceId}
              price={slotPrice}
              currency={currency}
              duration={duration}
              onSelect={setSlotSelected}
              endDateLimit={bookedPackage?.expiresAt}
              experimentalTimeSlots={page.features?.experimentalTimeSlots}
            />
            <TimeZone />
          </>
        )}
        {slotSelected && (
          <>
            <FormGroup>
              You&apos;re rescheduling this session to <b>{formatSessionDateLong(slotSelected.start)}</b> at{' '}
              <b>{formatSessionTime(slotSelected.start)}</b>.
            </FormGroup>
            <FormGroup>You can include a message with your rescheduling.</FormGroup>
            <FormGroup>
              <Label>
                <LabelText>Message (optional)</LabelText>
                <TextArea
                  name="message"
                  rows={4}
                  {...register('message', {
                    setValueAs: (v) => v.trim(),
                    maxLength: {
                      value: maxMessageLength,
                      message: `Too long, please limit the message to ${maxMessageLength} characters`,
                    },
                  })}
                />
              </Label>
              <FormFootnote>
                <FormValueLength control={control} name="message" maxLength={maxMessageLength} />
              </FormFootnote>
            </FormGroup>
            <FormGroup>
              <Button type="submit" variant="primary" className={themeClasses({ width: '100%' })}>
                Confirm rescheduling
              </Button>
            </FormGroup>
          </>
        )}
      </fieldset>
    </form>
  );
};

const ChangeBookingReschedule = ({ continueTo, page }: { continueTo: string; page: introwise.Page }) => {
  const { session, booking, bookedPackage, retrieve } = useContext(ChangeBookingContext);
  const firestore = useFirestore();

  const [error, setError] = useErrorStateHandler();

  const [isRescheduled, setIsRescheduled] = useState(false);
  const [rescheduledTo, setRescheduledTo] = useState<SlotTimeRange>(null);
  const [submitting, setSubmitting] = useState(false);

  const isServiceStillAvailable = !!page.services?.[session.serviceId];

  const reschedule = async ({ message, start, end }: FormValues & SlotTimeRange) => {
    setSubmitting(true);

    try {
      const sessionRef = doc(firestore, 'sessions', session.id).withConverter(firestorePersonalSessionConverter);
      await runTransaction(firestore, async (t) => {
        const sessionDoc = await t.get(sessionRef);
        if (!sessionDoc.exists) {
          throw new Error(`Session doesn't exist`);
        }

        if (sessionDoc.data().status === 'cancelled') {
          throw new Error(`Session is already cancelled`);
        }
        return t.update(sessionRef, {
          start,
          end,
          rescheduledAt: serverTimestamp(),
          rescheduledBy: booking.id,
          rescheduledStart: session.start,
          rescheduledEnd: session.end,
          reschedulingMessage: message,
          iCalSequence: increment(1),
        });
      });

      setIsRescheduled(true);
      setRescheduledTo({ start, end });
      await retrieve();
    } catch (err) {
      setError(err);
    }

    setSubmitting(false);
  };

  return (
    <>
      <ScreenTracker screenName="ChangeBookingCancel" />
      <BackButton initialTo=".." />
      <Title>Reschedule your session</Title>
      {isRescheduled ? (
        <>
          <p>
            Session has been rescheduled to <b>{formatSessionDateLong(rescheduledTo.start)}</b> at{' '}
            <b>{formatSessionTime(rescheduledTo.start)}</b>.
          </p>
          <p>We&apos;ve notified the expert and have sent you a rescheduling confirmation.</p>
          <p>
            <LinkButton size="md" to={continueTo}>
              Book another session
            </LinkButton>
          </p>
        </>
      ) : isServiceStillAvailable ? (
        <>
          <ReschedulingForm
            onSubmit={reschedule}
            submitting={submitting}
            page={page}
            serviceId={session.serviceId}
            bookedPackage={bookedPackage}
          />
          {error && <FormError>Something went wrong. Please try again later.</FormError>}
        </>
      ) : (
        <>
          <p>
            This service is no longer available and this session cannot be rescheduled. You can{' '}
            <LinkStyled to={continueTo}>book another session</LinkStyled>.
          </p>
        </>
      )}
    </>
  );
};

export default ChangeBookingReschedule;
