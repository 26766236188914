import { PortableTextComponentProps } from '@portabletext/react';
import { TypedObject } from '@portabletext/types';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import React from 'react';
import {
  FullWidthCaption,
  FullWidthFigure,
  FullWidthIframeWrapper,
  aspectRatio,
} from 'web/components/elements/FullWidthFigure';
import { OembedDataVideo } from 'web/utils/oembed';

type PortableTextVimeoNode = TypedObject & {
  _type: 'vimeo';
  videoId: string;
  url: string;
  oembed: OembedDataVideo;
  caption?: string;
};

const isPortableTextVimeoNode = (node: TypedObject): node is PortableTextVimeoNode => node._type === 'vimeo';

type VimeoSerializerProps = PortableTextComponentProps<PortableTextVimeoNode>;

const VimeoSerializer = ({ value: { oembed, videoId, caption } }: VimeoSerializerProps) => (
  <FullWidthFigure>
    {oembed?.type === 'video' && (
      <FullWidthIframeWrapper
        dangerouslySetInnerHTML={{
          __html: oembed.html,
        }}
        style={assignInlineVars({
          [aspectRatio]: (oembed.width / oembed.height).toString(),
        })}
      />
    )}
    {!oembed && (
      <FullWidthIframeWrapper>
        <iframe
          src={`https://player.vimeo.com/video/${videoId}`}
          width="560"
          height="315"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={true}
          title="Vimeo video"
        />
      </FullWidthIframeWrapper>
    )}
    {caption && <FullWidthCaption>{caption}</FullWidthCaption>}
  </FullWidthFigure>
);

export { isPortableTextVimeoNode, PortableTextVimeoNode };
export default VimeoSerializer;
