import React, { useContext, useState } from 'react';
import OrderForm, { OrderFormFake } from 'web/App/BookingPage/OrderForm';
import BookedPackageContext from 'web/App/BookingPage/packages/BookedPackageContext';
import { LinkStyled } from 'web/components/elements';
import GenericReactModal from 'web/components/GenericReactModal';
import ScreenTracker from 'web/components/ScreenTracker';
import ScrollIntoViewOnMount from 'web/components/ScrollIntoViewOnMount';
import Spinner from 'web/components/Spinner';
import TimeZoneContext from 'web/components/timezone/TimeZoneContext';
import themeClasses from 'web/styles/themeClasses.css';
import BackButton from 'web/components/BackButton';
import BookingPageUrlContext from './BookingPageUrlContext';
import { BookingDetails, Title } from './common';
import Confirmation from './Confirmation';
import { BookingError, RetryDialog, RetryPaymentDialog } from './orderCommon';
import OrderContext, { OrderProvider } from './OrderContext';
import CheckoutContext from 'web/App/BookingPage/CheckoutContext';

export const PackageNotice = ({ bookedPackage }: { bookedPackage: introwise.BookedPackage }) => {
  const pageRootUrl = useContext(BookingPageUrlContext);

  return (
    <>
      <p>
        Congratulations! This session is included in{' '}
        <LinkStyled to={`${pageRootUrl}packages/manage`}>your package</LinkStyled>.
      </p>
      <p>
        This session will be booked for{' '}
        <b>
          {bookedPackage.client.firstName} {bookedPackage.client.lastName}
        </b>{' '}
        using an email <b>{bookedPackage.client.email}</b>.
      </p>
    </>
  );
};

const UniversalSessionBookingForm = ({
  discountCodeEnabled,
  discountCodeOpen,
  displayName,
}: {
  discountCodeEnabled: boolean;
  discountCodeOpen?: boolean;
  displayName: string;
}) => {
  const { timeZone: timezone } = useContext(TimeZoneContext);
  const { bookedPackage } = useContext(BookedPackageContext);
  const [state, send] = useContext(OrderContext);
  const [product] = useContext(CheckoutContext);
  const { order, error } = state.context;

  const [initialFormValues] = useState<{
    firstName: string;
    lastName: string;
    email: string;
    message: string;
    code: string;
  } | null>(() =>
    order
      ? {
          firstName: order.client?.firstName,
          lastName: order.client?.lastName,
          email: order.client?.email,
          ...(order.client?.phoneNumber && { phoneNumber: order.client?.phoneNumber }),
          message: order.message,
          code: order.discount ? order.discount.code : order.partner ? order.partner.code : undefined,
        }
      : null,
  );

  const isSessionPaid = order?.amountSubtotal > 0;
  const isSessionFromPackage = !!order?.package;

  const paymentGateway = order?.payment?.gateway;
  const withPaymentDue = order?.amountDue === 0 || paymentGateway === 'none' || isSessionFromPackage;
  const isPartnerOrder = order?.partner;
  const withoutPaymentWithConfirmation =
    paymentGateway === 'none' && order?.amountTotal !== 0 && !isSessionFromPackage && !isPartnerOrder;
  const withoutClient = isSessionFromPackage;
  const withCode = !isSessionFromPackage && discountCodeEnabled && isSessionPaid;
  const withPhone = product.type === 'service' && !!product.service.collectPhoneNumber;

  const orderReady = !state.matches('preparing');
  const submitting = state.matches('submitting');

  const book = async (bookingDetails: BookingDetails) => {
    const order: Pick<introwise.Order, 'client' | 'message'> = {
      client: isSessionFromPackage
        ? {
            email: bookedPackage.client.email,
            firstName: bookedPackage.client.firstName,
            lastName: bookedPackage.client.lastName,
            ...(bookedPackage.client.phoneNumber && { phoneNumber: bookedPackage.client.phoneNumber }),
            timezone,
          }
        : {
            firstName: bookingDetails.firstName,
            lastName: bookingDetails.lastName,
            email: bookingDetails.email,
            phoneNumber: bookingDetails.phoneNumber,
            timezone,
          },
      message: bookingDetails.message,
    };
    send('SUBMIT', {
      order,
    });
  };

  return (
    <>
      <ScreenTracker screenName="BookingForm" />
      <ScrollIntoViewOnMount />
      <BackButton />
      <Title>Booking details</Title>
      <GenericReactModal isOpen={state.matches('submitting.confirmedUnpaid')}>
        <RetryPaymentDialog onRetry={() => send('RETRY')} error={error} />
      </GenericReactModal>
      <GenericReactModal isOpen={state.matches('submitting.confirmRetry')}>
        <RetryDialog onRetry={() => send('RETRY')} error={error} />
      </GenericReactModal>
      {isSessionFromPackage && <PackageNotice bookedPackage={bookedPackage} />}
      <OrderForm
        defaultValues={initialFormValues}
        onSubmit={book}
        submitting={submitting}
        bookErrorMessage={error}
        paymentGateway={paymentGateway}
        withoutPayment={withPaymentDue}
        withoutPaymentWithConfirmation={withoutPaymentWithConfirmation}
        withoutClient={withoutClient}
        withCode={withCode}
        withCodeOpen={discountCodeOpen}
        withPhone={withPhone}
        orderReady={orderReady}
        displayName={displayName}
      />
    </>
  );
};

const LoadingSpinner = () => (
  <div
    className={themeClasses({ display: 'grid', justifyContent: 'center', alignItems: 'center' })}
    style={{ height: 400 }}
  >
    <Spinner />
  </div>
);

const SessionBookingFormWithOrder = ({
  page,
  onConfirmationBack,
}: {
  page: introwise.Page;
  onConfirmationBack: () => void;
}) => {
  const [state, send] = useContext(OrderContext);
  const { order, error } = state.context;

  const isPagePartnerEligible = page.partnersIds?.length > 0;
  const isPageWithDiscounts = !!page.hasDiscounts;

  if (state.matches('error')) {
    return <BookingError error={error} />;
  } else if (state.matches('completed')) {
    return (
      <Confirmation
        email={order.client.email}
        bookedSessionId={(order.fulfillment as introwise.OrderSessionFulfillment).sessionId}
        onBack={onConfirmationBack}
      />
    );
  } else if (state.matches('preparing')) {
    return (
      <>
        <ScreenTracker screenName="BookingForm" />
        <ScrollIntoViewOnMount />
        <BackButton />
        <Title>Booking details</Title>
        <OrderFormFake />
      </>
    );
  } else if (state.matches('loading')) {
    return (
      <>
        <GenericReactModal isOpen={state.matches('loading.loadRetry')}>
          <RetryDialog onRetry={() => send('RETRY')} error={error} />
        </GenericReactModal>
        <LoadingSpinner />
      </>
    );
  } else {
    return (
      <UniversalSessionBookingForm
        displayName={page.displayName}
        discountCodeEnabled={isPagePartnerEligible || isPageWithDiscounts}
        discountCodeOpen={isPagePartnerEligible}
      />
    );
  }
};

const SessionBookingForm = ({
  page,
  onConfirmationBack,
  orderId,
}: {
  page: introwise.Page;
  onConfirmationBack: () => void;
  orderId?: string;
}) => {
  const { loading } = useContext(BookedPackageContext);

  // Wait for the booked package to be fully loaded before initializing the order
  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <OrderProvider page={page} orderId={orderId}>
      <SessionBookingFormWithOrder page={page} onConfirmationBack={onConfirmationBack} />
    </OrderProvider>
  );
};

export default SessionBookingForm;
