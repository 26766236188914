import { doc, getDoc } from 'firebase/firestore';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import Spinner from 'web/components/Spinner';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import {
  firestoreBookedPackageConverter,
  firestoreBookingConverter,
  firestorePersonalSessionConverter,
} from 'web/utils/convert';
import { SessionChangeProduct } from '../common';
import useCheckoutInit from '../useCheckoutInit';
import ChangeBookingContext, { ChangeBookingContextType } from './ChangeBookingContext';

const ChangeBookingCheckoutInit = ({ session }: { session: introwise.PersonalSession }) => {
  const product: SessionChangeProduct = useMemo(
    () => ({
      type: 'session-change',
      sessionId: session.id,
      session,
    }),
    [session],
  );

  useCheckoutInit(product);

  return <></>;
};

const ChangeBookingContainerImpl = ({ sessionId, bookingId }: { sessionId: string; bookingId: string }) => {
  const firestore = useFirestore();
  const [retrievalError, setRetrievalError] = useErrorStateHandler();
  const [retrieving, setRetrieving] = useState(true);
  const [value, setValue] = useState<ChangeBookingContextType>();

  const retrieve = useCallback(async () => {
    try {
      const [sessionDoc, bookingDoc] = await Promise.all([
        getDoc(doc(firestore, 'sessions', sessionId).withConverter(firestorePersonalSessionConverter)),
        getDoc(doc(firestore, 'sessions', sessionId, 'bookings', bookingId).withConverter(firestoreBookingConverter)),
      ]);
      if (!sessionDoc.exists()) {
        throw new Error(`Session ${sessionId} not found`);
      }
      if (!bookingDoc.exists()) {
        throw new Error(`Booking ${bookingId} not found`);
      }
      let bookedPackageDoc;
      if (bookingDoc.data().paymentId === 'package') {
        bookedPackageDoc = await getDoc(
          doc(firestore, 'bookedPackages', bookingDoc.data().bookedPackageId).withConverter(
            firestoreBookedPackageConverter,
          ),
        );
      }
      setValue({
        session: sessionDoc.data(),
        booking: bookingDoc.data(),
        bookedPackage: bookedPackageDoc?.data(),
        retrieve,
      });
    } catch (err) {
      setRetrievalError(err);
    }
  }, [bookingId, firestore, sessionId, setRetrievalError]);

  useEffect(() => {
    const retrieveOnMount = async () => {
      setRetrieving(true);
      await retrieve();
      setRetrieving(false);
    };
    void retrieveOnMount();
  }, [retrieve]);

  return (
    <>
      {retrieving && <Spinner />}
      {!retrieving && retrievalError && <>Error: {retrievalError.message}</>}
      {!retrieving && !retrievalError && (
        <ChangeBookingContext.Provider value={value}>
          <ChangeBookingCheckoutInit session={value?.session} />
          <Outlet />
        </ChangeBookingContext.Provider>
      )}
    </>
  );
};

const ChangeBookingContainer = () => {
  const { sessionId, bookingId } = useParams();

  if (sessionId && bookingId) {
    return <ChangeBookingContainerImpl sessionId={sessionId} bookingId={bookingId} />;
  } else {
    return <p>Invalid scheduling action</p>;
  }
};

export default ChangeBookingContainer;
