import settings from 'web/utils/settings';
import { isClientSide } from 'web/utils/ssr';

type ErrorReporter = {
  report: (error: Error | string, fatal?: boolean) => void;
  setUser: (userId: string) => void;
};

const consoleReporter = {
  report: console.error,
  setUser: () => {
    // do nothing
  },
};

const shouldBeReported = (error: Error | string) => {
  const message = typeof error === 'string' ? error : error.message || '';
  if (/Failed to register a ServiceWorker/.exec(message) && /bot/i.exec(window.navigator.userAgent)) {
    return false;
  }
  return true;
};

const getVersion = () => {
  try {
    return process.env.__INTROWISE_VERSION__;
  } catch {
    return 'undefined';
  }
};

const getStackdriverReporter = () => {
  try {
    let savedUser: string | null = null;
    const stub = {
      report: console.error,
      setUser: (userId: string) => {
        savedUser = userId;
      },
    };
    import('stackdriver-errors-js').then(({ default: StackdriverErrorReporter }) => {
      const stackdriverReporter = new StackdriverErrorReporter();
      stackdriverReporter.start({
        key: settings.stackdriver.apiKey,
        projectId: settings.stackdriver.projectId,
        version: getVersion(),
      });
      stackdriverReporter.setUser(savedUser);
      const report = (error: Error | string) => {
        if (shouldBeReported(error)) {
          stackdriverReporter.report(error);
        }
      };
      const setUser = (userId: string) => stackdriverReporter.setUser(userId);
      stub.report = report;
      stub.setUser = setUser;
    });
    return stub;
  } catch (err) {
    console.error(`Failed to setup stackdriver error reporter: `, err);
    return consoleReporter;
  }
};

const errorReporter: ErrorReporter =
  settings.stackdriver.enabled && isClientSide ? getStackdriverReporter() : consoleReporter;

export default errorReporter;
