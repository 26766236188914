import sc from '../styled';
import { detailsDd, detailsDt, detailsSummary } from './accordion.css';

const Details = sc.details();
const Summary = sc.summary(detailsSummary);
const Dl = sc.dl();
const Dt = sc.dt(detailsDt);
const Dd = sc.dd(detailsDd);

export default { Details, Summary, Dl, Dt, Dd };
