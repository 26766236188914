import React, { useMemo } from 'react';
import { Discount, Product } from './common';

const CheckoutContext = React.createContext<
  readonly [
    product: Product | null,
    setProduct: (product: Product) => void,
    discount: Discount | null,
    setDiscount: (discount: Discount) => void,
  ]
>([
  null,
  () => {
    // do nothing
  },
  null,
  () => {
    // do nothing
  },
] as const);

const CheckoutProvider = ({ children }: { children: React.ReactNode }) => {
  const [product, setProduct] = React.useState<Product>(null);
  const [discount, setDiscount] = React.useState<Discount>(null);

  const value = useMemo(() => [product, setProduct, discount, setDiscount] as const, [product, discount]);
  return <CheckoutContext.Provider value={value}>{children}</CheckoutContext.Provider>;
};

export { CheckoutProvider };
export default CheckoutContext;
