import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

const BackButtonContext = React.createContext({ hasBackNavigation: false });

const BackButtonProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const [initLocationKey] = useState(location.key);
  return (
    <BackButtonContext.Provider value={{ hasBackNavigation: location.key !== initLocationKey }}>
      {children}
    </BackButtonContext.Provider>
  );
};

export { BackButtonProvider };
export default BackButtonContext;
