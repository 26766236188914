import React from 'react';
import themeClasses from 'web/styles/themeClasses.css';
import themeVars from 'web/styles/themeVars.css';
import { Button } from './elements';
import sc from './styled';

const Card = sc.div(
  themeClasses({
    display: 'grid',
    borderRadius: 'sm',
    gridTemplateColumns: 4,
    gap: 2,
  }),
  {
    background: themeVars.backgrounds.sessionSlot,
    padding: '14px 20px',
  },
);

const SlotButton = sc(Button)(undefined, { padding: 8 });

type SessionSlot = {
  start: Date;
  end: Date;
  isAvailableFromPackage?: boolean;
};

const SessionSlotsPreview = ({
  slots,
  timeZone,
  onSelect,
  onViewCalendar,
}: {
  slots: SessionSlot[];
  timeZone?: string;
  onSelect: (slot: SessionSlot) => void;
  onViewCalendar: () => void;
}) => {
  const slotsSlice = slots.slice(0, 3);
  const slotsExtra = [1, 2, 3, 4].slice(0, 3 - slotsSlice.length);
  return (
    <Card>
      {slots.map((slot) => (
        <SlotButton key={slot.start.getTime()} variant="secondary" size="sm" onClick={() => onSelect(slot)}>
          {slot.start.toLocaleDateString('en', { month: 'short', day: 'numeric', timeZone: timeZone })}
          <br />
          {slot.start.toLocaleTimeString('en', { hour: 'numeric', minute: '2-digit', timeZone: timeZone })}
        </SlotButton>
      ))}
      {slotsExtra.length === 3 ? (
        <SlotButton variant="secondary" size="sm" disabled style={{ gridArea: '1 / 1 / 2 / -2' }}>
          No slots
          <br />
          available soon
        </SlotButton>
      ) : (
        slotsExtra.map((key) => (
          <SlotButton key={key} variant="secondary" size="sm" disabled>
            No more
            <br />
            slots
          </SlotButton>
        ))
      )}
      <SlotButton variant="primary" size="sm" onClick={onViewCalendar} style={{ gridArea: '1 / -1 / 2 / -2' }}>
        View calendar
      </SlotButton>
    </Card>
  );
};

export default SessionSlotsPreview;
