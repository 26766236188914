import React from 'react';
import { useLocation, RouterProps } from 'react-router';
import useErrorReporter from 'web/hooks/useErrorReporter';
import { Button } from 'web/components/elements';
import BodyBackground from 'web/styles/BodyBackground';
import PageLoadingFallback from 'web/components/PageLoadingFallback';

const chunkErrorStorageKey = 'introwiseRefreshOnChunkLoadFailed';
const isErrorPermissionDenied = (error: Error & { code?: string }) => error.code === 'permission-denied';

type Props = {
  location: RouterProps['location'];
  errorReporter: typeof import('web/utils/error-reporter').default;
  children: React.ReactNode;
};
type State = {
  hasError: boolean;
  chunkError: boolean;
  failedOnChunkReload: boolean;
  isPermissionDenied: boolean;
};

class ErrorBoundary extends React.Component<Props, State> {
  unlisten: () => void;

  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      chunkError: false,
      failedOnChunkReload: false,
      isPermissionDenied: false,
    };

    this.unlisten = null;
  }

  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      chunkError: error.name === 'ChunkLoadError',
      failedOnChunkReload: false,
      isPermissionDenied: isErrorPermissionDenied(error),
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { hasError, chunkError, failedOnChunkReload } = this.state;
    if (chunkError && !failedOnChunkReload) {
      try {
        const storage = window.sessionStorage;
        if (!storage.getItem(chunkErrorStorageKey)) {
          storage.setItem(chunkErrorStorageKey, 'true');
          window.location.reload();
        } else {
          storage.removeItem(chunkErrorStorageKey);
          this.setState((prev) => ({ ...prev, failedOnChunkReload: true }));
        }
      } catch (e) {
        this.setState((prev) => ({ ...prev, failedOnChunkReload: true }));
        // ignore session storage exceptions
        // if chunk errors occurred here with storage exceptions
        // than show something-went-wrong page immediately without reload
      }
    }

    if (hasError && prevProps.location !== this.props.location) {
      this.setState((prev) => ({
        ...prev,
        hasError: false,
      }));
    }
  }

  componentDidCatch(error: Error) {
    // TODO: should we report all errors?
    try {
      this.props.errorReporter.report(error, true);
    } catch (e) {
      console.error('Failed to log the error in ErrorBoundary: ', e);
    }
  }

  render() {
    const { hasError, chunkError, failedOnChunkReload, isPermissionDenied } = this.state;
    const { children } = this.props;
    if (chunkError && !failedOnChunkReload) {
      return <PageLoadingFallback />;
    }

    if (hasError) {
      return (
        <>
          <BodyBackground color="#fff" />
          <h1 style={{ textAlign: 'center' }} data-cy="something-went-wrong">
            Something went wrong
          </h1>
          <div
            style={{
              position: 'relative',
              width: '800px',
              maxWidth: '100%',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <div
              style={{
                paddingBottom: '75%',
                background: 'url(/broken-robot.svg) center/cover no-repeat',
              }}
            />
          </div>
          {isPermissionDenied ? (
            <div style={{ textAlign: 'center' }}>
              <p>You should not have access here.</p>
            </div>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <p>We will fix this soon. Retrying again may help.</p>
              <Button
                secondary
                sm
                onClick={() => {
                  window.location.reload(false);
                }}
              >
                Refresh page
              </Button>
            </div>
          )}
        </>
      );
    }
    return children;
  }
}

const ErrorBoundaryContainer = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const errorReporter = useErrorReporter();
  return (
    <ErrorBoundary location={location} errorReporter={errorReporter}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryContainer;
