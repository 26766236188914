import React, { useContext, useMemo, useState } from 'react';
import BookedPackageInfo from 'web/App/BookingPage/packages/BookedPackageInfo';
import PackagesList from 'web/App/BookingPage/packages/PackagesList';
import { LinkStyled, LinkUnstyled } from 'web/components/elements';
import ListUnstyled from 'web/components/elements/ListUnstyled';
import { NoticeCard } from 'web/components/NoticeCard';
import ScreenTracker from 'web/components/ScreenTracker';
import TimeZone from 'web/components/timezone/TimeZone';
import UserContext from 'web/components/UserContext';
import themeClasses from 'web/styles/themeClasses.css';
import { DescriptionText, formatDurationMins, formatPriceReadable, Slot, SlotTimeZoneTimeRange, Title } from './common';
import GroupSessionsSlots from './GroupSessionsSlots';
import BookedPackageContext from './packages/BookedPackageContext';
import { PersonalSessionsSlots } from './PersonalSessionsSlots';
import SeriesList from './series/SeriesList';
import useAvailableSlotsBatch, { AvailableSlotsBatch } from './useAvailableSlotsBatch';

const ServicesSlotsWithData = ({
  page,
  services,
  loading,
  error,
  availableSlots,
  onViewFull,
  onSelect,
}: {
  page: introwise.Page;
  services: introwise.Service[];
  loading: boolean;
  error: Error | null;
  availableSlots: AvailableSlotsBatch | null;
  onViewFull: (serviceId?: string) => void;
  onSelect: (slot: Slot) => void;
}) => {
  return (
    <>
      {services.map((service, idx) => (
        <div key={service.id} className={themeClasses({ marginTop: 8 })}>
          <h3 id={service.id}>
            <LinkUnstyled to={`services/${service.id}`}>{service.title}</LinkUnstyled>
          </h3>
          <p>
            <b>{formatPriceReadable(service.price, page.currency)}</b>
            {service.price ? ' for' : ','} {formatDurationMins(service.duration)}
          </p>
          <DescriptionText text={service.description} />
          <PersonalSessionsSlots
            error={error}
            loading={loading}
            slots={availableSlots?.[idx]}
            service={service}
            onSelect={(slot: SlotTimeZoneTimeRange) =>
              onSelect({
                ...slot,
                serviceId: service.id,
                isGroup: false,
                price: service.price,
                currency: page.currency,
                title: service.title,
              })
            }
            onViewCalendar={() => onViewFull(service.id)}
            currency={page.currency}
          />
        </div>
      ))}
    </>
  );
};

const ServicesSlotsContainer = ({
  page,
  services,
  onViewFull,
  onSelect,
}: {
  page: introwise.Page;
  services: introwise.Service[];
  onViewFull: (serviceId?: string) => void;
  onSelect: (slot: Slot) => void;
}) => {
  const [availableSlots, loading, error] = useAvailableSlotsBatch({
    expertId: page.ownerId,
    pageId: page.id,
    services,
  });
  return <ServicesSlotsWithData {...{ page, services, loading, error, availableSlots, onViewFull, onSelect }} />;
};

const HiddenServices = ({
  services,
  pageId,
  pageOwnerId,
}: {
  services: introwise.Service[];
  pageId: string;
  pageOwnerId: string;
}) => {
  const { user } = useContext(UserContext);
  const isPageOwner = user?.uid === pageOwnerId;
  const [visibleHiddenService] = useState(() => {
    let res;
    try {
      const savedHiddenService = window.sessionStorage.getItem('introwise-hidden-service');
      const [savedPageId, savedServiceId] = savedHiddenService.split('/');
      res = savedPageId === pageId && services.find((s) => s.id === savedServiceId);
    } catch (err) {
      // do nothing
    }
    return res;
  });

  const hiddenServicesShown = isPageOwner ? services : visibleHiddenService ? [visibleHiddenService] : [];

  return hiddenServicesShown.length === 0 ? (
    <></>
  ) : (
    <NoticeCard className={themeClasses({ marginTop: 4 })}>
      <h4 className={themeClasses({ marginTop: 0 })}>Hidden services</h4>
      <p>
        {isPageOwner
          ? 'These services are not visible to your clients on this booking page. Use a direct link to share a hidden service.'
          : 'This service is not shown on this booking page, but was shared with you directly. Use the link below to book it.'}
      </p>
      <ListUnstyled>
        {hiddenServicesShown.map((service) => (
          <li key={service.id}>
            <LinkStyled to={`services/${service.id}`}>{service.title}</LinkStyled>
          </li>
        ))}
      </ListUnstyled>
    </NoticeCard>
  );
};

const Services = ({
  page,
  onViewFull,
  onSelect,
}: {
  page: introwise.Page;
  onViewFull: (serviceId?: string) => void;
  onSelect: (slot: Slot) => void;
}) => {
  const { isServiceAvailable } = useContext(BookedPackageContext);

  const allServices = useMemo(
    () => (page.services ? Object.values(page.services).sort((a, b) => a.order - b.order) : []),
    [page.services],
  );

  if (allServices.length === 0) {
    return <></>;
  }

  const services = allServices.filter((service) => !service.hidden || isServiceAvailable(service.id));
  const hiddenServices = allServices.filter((service) => service.hidden && !isServiceAvailable(service.id));

  return (
    <>
      {services.length > 0 && <ServicesSlotsContainer {...{ page, services, onViewFull, onSelect }} />}
      {hiddenServices.length > 0 && (
        <HiddenServices services={hiddenServices} pageId={page.id} pageOwnerId={page.ownerId} />
      )}
    </>
  );
};

const GroupSessions = ({
  page,
  onViewGroup,
  onClickGroup,
  onSelect,
}: {
  page: introwise.Page;
  onViewGroup: () => void;
  onClickGroup: (session: introwise.Session) => void;
  onSelect: (slot: Slot) => void;
}) => (
  <>
    <div className={themeClasses({ marginTop: 8 })}>
      <GroupSessionsSlots
        pageId={page.id}
        expertId={page.ownerId}
        seriesId={null}
        onClick={onClickGroup}
        onSelect={onSelect}
        onViewAll={onViewGroup}
        limit={20}
        expand={true}
        hideEmpty={true}
      />
    </div>
  </>
);

const ExpertInfo = ({
  page,
  onViewPersonal,
  onPackageClick,
  onPackageSelect,
  onPackageRestore,
  onViewGroup,
  onClickGroup,
  onSelect,
  onManagePackage,
}: {
  page: introwise.Page;
  onViewPersonal: (serviceId?: string) => void;
  onPackageClick: (pack: introwise.Package) => void;
  onPackageSelect: (pack: introwise.Package) => void;
  onPackageRestore: () => void;
  onViewGroup: () => void;
  onClickGroup: (session: introwise.Session) => void;
  onSelect: (slot: Slot) => void;
  onManagePackage: () => void;
}) => {
  return (
    <>
      <ScreenTracker screenName="ExpertInfo" />
      <Title>{page.displayName}</Title>
      {page.headline && <h3>{page.headline}</h3>}
      {page.description && <DescriptionText text={page.description} />}
      <PackagesList
        page={page}
        onClick={onPackageClick}
        onSelect={onPackageSelect}
        onPackageRestore={onPackageRestore}
      />
      <BookedPackageInfo onManagePackage={onManagePackage} />
      <GroupSessions page={page} onClickGroup={onClickGroup} onViewGroup={onViewGroup} onSelect={onSelect} />
      <SeriesList page={page} onGroupSelect={onSelect} />
      <Services page={page} onViewFull={onViewPersonal} onSelect={onSelect} />
      <TimeZone />
    </>
  );
};

export default ExpertInfo;
