import themeClasses from 'web/styles/themeClasses.css';
import sc from '../styled';
import { figcaption, iframeWrapped, iframeWrapper, aspectRatio, width } from './fullWidthFigure.css';

const FullWidthFigure = sc.figure(themeClasses({ margin: 0 }));
const FullWidthCaption = sc.figcaption(figcaption);
const FullWidthImage = sc.img(themeClasses({ width: '100%', height: 'auto', display: 'block' }));
const FullWidthIframeWrapper = sc.div(iframeWrapper);
const FullWidthIframe = sc.iframe(iframeWrapped);

export {
  FullWidthFigure,
  FullWidthCaption,
  FullWidthImage,
  FullWidthIframeWrapper,
  FullWidthIframe,
  aspectRatio,
  width,
};
