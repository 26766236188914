import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { useNavigate, To, useLinkClickHandler, useHref } from 'react-router-dom';
import BackButtonContext from 'web/components/BackButtonContext';
import { AnchorStyled } from 'web/components/elements';
import themeClasses from 'web/styles/themeClasses.css';
import joinClassNames from 'web/utils/joinClassNames';

const BackButton = ({
  initialText,
  initialTo,
  className,
  onClick,
}: {
  initialText?: string;
  initialTo?: To;
  className?: string;
  // @deprecated
  onClick?: () => void;
}) => {
  const navigate = useNavigate();
  const { hasBackNavigation } = useContext(BackButtonContext);
  const handleClickBack = () => navigate(-1);
  const handleClickInitial = useLinkClickHandler(initialTo || '..');
  const href = useHref(initialTo || '..');
  return (
    <AnchorStyled
      href={href}
      className={joinClassNames(className ?? themeClasses({ marginTop: 5 }), themeClasses({ display: 'inline-block' }))}
      onClick={(event) => {
        if (onClick) {
          event.preventDefault();
          onClick();
        } else if (hasBackNavigation) {
          event.preventDefault();
          handleClickBack();
        } else {
          handleClickInitial(event);
        }
      }}
    >
      <FontAwesomeIcon icon={faAngleLeft} fixedWidth />
      {hasBackNavigation ? 'Back' : initialText || 'Back'}
    </AnchorStyled>
  );
};

export default BackButton;
