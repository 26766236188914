import React, { useEffect, useMemo } from 'react';
import settings from 'web/utils/settings';
import { isClientSide } from 'web/utils/ssr';

type RewardfulSourceArgs = ['source', string];
type RewardfulArgs = RewardfulSourceArgs;

type RewardfulFunction = {
  (...args: RewardfulSourceArgs): void;
};

type RewardfulObject = {
  referral: string | '';
  affiliate:
    | false
    | {
        id: string;
        name: string;
        first_name: string;
        last_name: string;
        token: string;
      };
  campaign:
    | false
    | {
        id: string;
        name: string;
      };
  coupon:
    | false
    | {
        id: string;
        name: string;
        duration: string;
        duration_in_months: number;
        percent_off: number;
        amount_off: number;
        currency: string;
      };
};

declare global {
  interface Window {
    _rwq: 'rewardful';
    Rewardful: RewardfulObject;
    rewardful: RewardfulFunction & { q: RewardfulArgs[] };
  }
}

const RewardfulContext = React.createContext<{
  track: RewardfulFunction;
  current: () => RewardfulObject | undefined;
} | null>(null);

// How does Rewardful work
// - Affiliate partner shares a special link with embeded query parameters
// - A prospect clicks a link
// - On context provider creation a script is loaded and reads the query parameters and creates a tracking cookie
// - Any subsequent visits from the same prospect will be associated with the affiliate partner through that cookie
// - Once a prospect signs up a referral id is passed to the signup function and saved on the Stripe customer.
// - Conversion events from that Stripe customer are attributed to the affiliate partner.
// - The tracking cookie is valid for 60 days by default.
const RewardfulProvider = ({ children }: { children: React.ReactNode }) => {
  if (isClientSide && !window.rewardful && settings.rewardful.apiKey) {
    window._rwq = 'rewardful';
    window.rewardful = Object.assign(
      (...args: RewardfulArgs) => {
        (window.rewardful.q = window.rewardful.q || []).push(args);
      },
      {
        q: [],
      },
    );
  }
  useEffect(() => {
    const sriptId = 'rewardful-script-loader';
    if (window.rewardful && !document.getElementById(sriptId)) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = sriptId;
      script.src = 'https://r.wdfl.co/rw.js';
      script.setAttribute('data-rewardful', settings.rewardful.apiKey);
      document.body.appendChild(script);
    }
  }, []);
  const value = useMemo(
    () =>
      isClientSide && window.rewardful
        ? {
            track: (...args: RewardfulArgs) => window.rewardful(...args),
            current: () => window.Rewardful,
          }
        : null,
    [],
  );
  return <RewardfulContext.Provider value={value}>{children}</RewardfulContext.Provider>;
};

export { RewardfulProvider };
export default RewardfulContext;
