import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useErrorReporter from 'web/hooks/useErrorReporter';
import settings from 'web/utils/settings';

type IdentifyType = {
  email: string;
  id: string;
};

type HubspotContextType = {
  onPathChanged: (path: string) => void;
  setUser: ({ email, id }: IdentifyType) => void;
  reset: () => void;
  openChat: () => void;
  chatReady: boolean;
} | null;

const HubspotContext = React.createContext<HubspotContextType>(null);

type Hsq = Array<['trackPageView'] | ['setPath', string] | ['identify', IdentifyType] | ['revokeCookieConsent']>;

declare global {
  interface Window {
    _hsq?: Hsq;
    hsConversationsOnReady?: (() => void)[];
    HubSpotConversations?: {
      widget: {
        open: () => void;
        load: () => void;
        refresh: () => void;
        status: () => {
          loaded: boolean;
        };
      };
    };
  }
}

const HubspotProvider = ({ children }: { children: React.ReactNode }) => {
  const errorReporter = useErrorReporter();
  const state = useRef({
    isIdentify: false,
    paths: [],
  });

  const [chatReady, setChatReady] = useState(false);

  const appendHsqPath = useCallback((path: string) => {
    window._hsq?.push(['setPath', path]);
    window._hsq?.push(['trackPageView']);
  }, []);

  const setUser = useCallback(
    ({ email, id }: { email: string; id: string }) => {
      window._hsq?.push([
        'identify',
        {
          email,
          id,
        },
      ]);
      state.current.paths.forEach((p, i) => {
        if (i === 0) return; // exclude first auto call `trackPageView` after script loads
        appendHsqPath(p);
      });
      state.current.isIdentify = true;
      state.current.paths = [];
    },
    [appendHsqPath],
  );

  const reset = useCallback(() => {
    if (state.current.isIdentify) {
      window._hsq?.push(['revokeCookieConsent']);
    }
  }, []);

  useEffect(() => {
    const hubId = settings.hubspot.hubId;
    window._hsq = window._hsq || [];
    window.hsConversationsOnReady = [
      () => {
        setChatReady(true);
      },
    ];
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'hs-script-loader';
    script.src = `https://js.hs-scripts.com/${hubId}.js`;
    document.body.appendChild(script);
  }, []);

  const value = useMemo(
    () => ({
      onPathChanged: (path: string) => {
        if (state.current.isIdentify) {
          appendHsqPath(path);
        } else if (state.current.paths.length === 0 || state.current.paths[state.current.paths.length - 1] !== path) {
          state.current.paths.push(path);
        }

        const chatWidget = window.HubSpotConversations?.widget;
        if (chatWidget) {
          if (chatWidget.status().loaded) {
            // Refresh forces chat widget to reread the path and reinitialize
            // with the right chatflow, or unload if no chatflows exist for that path
            chatWidget.refresh();
          } else {
            // Once unloaded, chat widgets needs to be reloaded on path change
            // It's safe to call load multiple times
            chatWidget.load();
          }
        }
      },
      setUser,
      openChat: () => {
        const chatWidget = window.HubSpotConversations?.widget;

        if (chatWidget?.status().loaded) {
          chatWidget.open();
        } else {
          // TODO: we might want to propagate this error to the caller, e.g. to show an error in the UI
          errorReporter.report(new Error(`Failed to open Hubspot Chat: widget is not initialized on this page`));
        }
      },
      chatReady,
      reset,
    }),
    [setUser, chatReady, appendHsqPath, errorReporter, reset],
  );

  return <HubspotContext.Provider value={value}>{children}</HubspotContext.Provider>;
};

export { HubspotProvider };
export default HubspotContext;
