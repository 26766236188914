import { httpsCallable } from 'firebase/functions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DateRange } from 'web/components/calendar/common/types';
import useFunctions from 'web/components/FirebaseContext/useFunctions';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';

type RangeSerialized = {
  start: string;
  end: string;
};

type DebugData<T> = {
  sessionsBusyRanges: T[];
  calendarBusyRanges: T[];
  sessionsBusyRangesWithRules: T[];
  calendarBusyRangesWithRules: T[];
  recurringRanges: T[];
  periodsRanges: T[];
};

const deserializeRange = ({ start, end }: RangeSerialized): DateRange => ({
  start: new Date(start),
  end: new Date(end),
});

const deserializeDebugData = (debugData: DebugData<RangeSerialized>): DebugData<DateRange> => ({
  sessionsBusyRanges: debugData.sessionsBusyRanges.map(deserializeRange),
  calendarBusyRanges: debugData.calendarBusyRanges.map(deserializeRange),
  sessionsBusyRangesWithRules: debugData.sessionsBusyRangesWithRules.map(deserializeRange),
  calendarBusyRangesWithRules: debugData.calendarBusyRangesWithRules.map(deserializeRange),
  recurringRanges: debugData.recurringRanges.map(deserializeRange),
  periodsRanges: debugData.periodsRanges.map(deserializeRange),
});

const useCronofyAvailablePersonalSlots = ({
  expertId,
  duration,
  pageId,
  serviceId,
  limit,
  endDateLimit,
  start,
  debug,
}: {
  expertId: string;
  duration: number;
  pageId?: string;
  serviceId?: string;
  limit?: number;
  endDateLimit?: Date;
  start?: Date;
  debug?: boolean;
}) => {
  const functions = useFunctions();
  const endDateLimitMillis = endDateLimit?.getTime();
  const getDateRangeWithLimits = useCallback(
    (dateRange: DateRange) => {
      if (endDateLimitMillis && dateRange.end.getTime() > endDateLimitMillis) {
        return { start: dateRange.start, end: new Date(endDateLimitMillis) };
      } else {
        return dateRange;
      }
    },
    [endDateLimitMillis],
  );

  const [slots, setSlots] = useState<Array<DateRange>>();
  const [debugData, setDebugData] = useState<DebugData<DateRange>>();
  const [dateRange, setDateRange] = useState<DateRange>(
    start ? getDateRangeWithLimits({ start, end: new Date(start.getTime() + 60 * 24 * 60 * 60 * 1000) }) : undefined,
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useErrorStateHandler();

  const updateDateRange = useCallback(
    (newDateRange: DateRange) =>
      setDateRange((oldDateRange: DateRange) => {
        if (
          !oldDateRange ||
          (oldDateRange.start.getTime() !== newDateRange.start.getTime() &&
            oldDateRange.end.getTime() !== newDateRange.end.getTime())
        ) {
          return getDateRangeWithLimits(newDateRange);
        } else {
          return oldDateRange;
        }
      }),
    [getDateRangeWithLimits],
  );

  useEffect(() => {
    let cancelled = false;
    if (!dateRange) return;

    const maxLimit = 200;

    const fetchData = async (start: number, end: number) => {
      const response = await httpsCallable<
        unknown,
        { results: { availableSlots: RangeSerialized[] }[]; debugData: DebugData<RangeSerialized> | undefined }
      >(
        functions,
        'cronofyAvailableSlots',
      )({
        userId: expertId,
        pageId,
        batch: [
          {
            serviceId,
            duration,
          },
        ],
        start,
        end,
        limit: limit ? limit : maxLimit,
        debug,
      });

      const availableSlots = response.data.results[0].availableSlots;
      return [
        availableSlots.map(deserializeRange),
        response.data.debugData ? deserializeDebugData(response.data.debugData) : undefined,
      ] as const;
    };

    const getAvailableSlots = async () => {
      setLoading(true);
      setSlots([]);
      try {
        // // request for time 12 hours before date range because timezone but not earlier then now
        // const dateNow = Date.now();
        // const dateStart = Math.max(dateNow, dateRange.start.getTime() - 12 * 60 * 60 * 1000);
        // // and 12 hours after the end
        // const dateEnd = dateRange.end.getTime() + 12 * 60 * 60 * 1000;
        const newSlots = [];

        let slots: DateRange[];
        let debugData: DebugData<DateRange> | undefined;
        do {
          const dateStartTmp = slots ? Math.max(...slots.map((s) => s.end.getTime())) : dateRange.start.getTime();
          [slots, debugData] = await fetchData(dateStartTmp, dateRange.end.getTime());
          newSlots.push(...slots);
        } while (slots.length >= maxLimit);

        if (!cancelled) {
          setSlots(newSlots);
          setDebugData(debugData);
        }
      } catch (err) {
        if (!cancelled) {
          setError(
            new Error(
              `Failed to fetch available personal slots (dateRange.start: ${
                dateRange && dateRange.start ? dateRange.start : 'undefined'
              }, (dateRange.end: ${dateRange && dateRange.end ? dateRange.end : 'undefined'})): ${err}`,
            ),
          );
        }
      }
      if (!cancelled) {
        setLoading(false);
      }
    };
    void getAvailableSlots();
    return () => {
      cancelled = true;
    };
  }, [dateRange, debug, duration, expertId, functions, limit, pageId, serviceId, setError]);

  return useMemo(
    () => [slots, loading, error, dateRange, updateDateRange, debugData] as const,
    [slots, loading, error, dateRange, updateDateRange, debugData],
  );
};

export default useCronofyAvailablePersonalSlots;
