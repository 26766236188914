import React, { useCallback, useMemo, useState } from 'react';
import useErrorReporter from 'web/hooks/useErrorReporter';
import getTimeZoneOptions, { TimezoneOption } from 'web/components/timezone/getTimeZoneOptions';

type TimeZoneContextType = {
  // IANA time zone name
  timeZone: string;
  timeZoneLabel: string;
  // Current time zone offset in minutes
  offset: number;
  options: Array<TimezoneOption>;
  onTimeZoneChange: (timeZone: string, offset: number) => void;
};

const TimeZoneContext = React.createContext<TimeZoneContextType>({
  timeZone: undefined,
  timeZoneLabel: '',
  offset: 0,
  options: [],
  onTimeZoneChange: undefined,
});

const TimeZoneProvider = ({ initialTimeZone, children }: { initialTimeZone?: string; children: React.ReactNode }) => {
  const errorReporter = useErrorReporter();
  const options = useMemo<TimezoneOption[]>(() => getTimeZoneOptions(errorReporter), [errorReporter]);

  const [state, setState] = useState(
    initialTimeZone
      ? {
          timeZone: initialTimeZone,
          offset: options.find((t) => t.value === initialTimeZone).offset,
        }
      : {
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          offset: -new Date().getTimezoneOffset(),
        },
  );

  const onTimeZoneChange = useCallback(
    (newTimeZone: string, timeZoneOffset: number) => {
      setState({
        timeZone: newTimeZone,
        offset: timeZoneOffset,
      });
    },
    [setState],
  );

  const timeZoneLabel = options?.find((t) => t.value === state.timeZone)?.formatLabel || state.timeZone;

  return (
    <TimeZoneContext.Provider value={{ ...state, onTimeZoneChange, timeZoneLabel, options }}>
      {children}
    </TimeZoneContext.Provider>
  );
};

export { TimeZoneProvider };
export default TimeZoneContext;
