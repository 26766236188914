import { collection, limit, orderBy, query, Timestamp, where } from 'firebase/firestore';
import React, { useContext, useMemo, useState } from 'react';
import { InlineButton, LinkStyled, LinkUnstyled } from 'web/components/elements';
import ListUnstyled from 'web/components/elements/ListUnstyled';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import SessionSlot, { SessionSlotEmpty, SessionSlotLoading } from 'web/components/SessionSlot';
import SessionSlotsList from 'web/components/SessionSlotsList';
import TimeZoneContext from 'web/components/timezone/TimeZoneContext';
import UserContext from 'web/components/UserContext';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useFirestoreCollectionData from 'web/hooks/useFirestoreCollectionData';
import themeClasses from 'web/styles/themeClasses.css';
import { firestoreGroupSessionConverter } from 'web/utils/convert';
import BookingPageUrlContext from './BookingPageUrlContext';
import { DescriptionText, formatDurationMins, formatPriceReadable, GroupSessionSlot } from './common';
import { NoticeCard } from 'web/components/NoticeCard';
import BookedPackageContext from './packages/BookedPackageContext';

const HiddenGroupSessions = ({
  sessions,
  pageId,
  pageOwnerId,
}: {
  sessions: introwise.GroupSession[];
  pageId: string;
  pageOwnerId: string;
}) => {
  const { user } = useContext(UserContext);
  const pageRootUrl = useContext(BookingPageUrlContext);
  const isPageOwner = user?.uid === pageOwnerId;
  const [visibleHiddenSession] = useState(() => {
    let res;
    try {
      const savedHiddenSession = window.sessionStorage.getItem('introwise-hidden-session');
      const [savedPageId, savedSessionId] = savedHiddenSession.split('/');
      res = savedPageId === pageId && sessions.find((s) => s.id === savedSessionId);
    } catch (err) {
      // do nothing
    }
    return res;
  });

  const hiddenGroupSessionsShown = isPageOwner ? sessions : visibleHiddenSession ? [visibleHiddenSession] : [];

  return hiddenGroupSessionsShown.length === 0 ? (
    <></>
  ) : (
    <NoticeCard className={themeClasses({ marginTop: 4 })}>
      <h4 className={themeClasses({ marginTop: 0 })}>Hidden sessions</h4>
      <p>
        {isPageOwner
          ? 'These sessions are not visible to your clients on this booking page. Use a direct link to share a hidden session.'
          : 'This session is not shown on this booking page, but was shared with you directly. Use the link below to book your spot.'}
      </p>
      <ListUnstyled>
        {hiddenGroupSessionsShown.map((session) => (
          <li key={session.id}>
            <LinkStyled to={`${pageRootUrl}sessions/${session.id}`}>{session.title}</LinkStyled>
          </li>
        ))}
      </ListUnstyled>
    </NoticeCard>
  );
};

const defaultLimit = 6;
const nowMinute = () => Math.floor(Date.now() / (60 * 1000)) * 60 * 1000;

const GroupSessionsSlots = ({
  pageId,
  expertId,
  seriesId,
  onClick,
  onSelect,
  onViewAll,
  limit: sessionsLimit,
  expand,
  hideEmpty,
  hideTitle,
  freeSlotsInline,
}: {
  pageId: string;
  expertId: string;
  seriesId?: string;
  onClick?: (session: introwise.Session) => void;
  onSelect: (slot: GroupSessionSlot) => void;
  onViewAll?: () => void;
  limit?: number;
  expand?: boolean;
  hideEmpty?: boolean;
  hideTitle?: boolean;
  freeSlotsInline?: boolean;
}) => {
  const { timeZone } = useContext(TimeZoneContext);

  const now = useMemo(() => Timestamp.fromMillis(nowMinute()), []);
  const firestore = useFirestore();
  const [rawSessions, loading, error] = useFirestoreCollectionData(
    query(
      collection(firestore, 'sessions'),
      where('seriesId', '==', seriesId || null),
      where('status', '==', 'confirmed'),
      where('expert.id', '==', expertId),
      where('type', '==', 'group'),
      where('start', '>', now),
      limit(sessionsLimit || defaultLimit),
      orderBy('start', 'asc'),
    ).withConverter(firestoreGroupSessionConverter),
  );
  useErrorHandler(error);

  const { isSeriesAvailable, isSessionBooked } = useContext(BookedPackageContext);

  if (error) {
    return <SessionSlotEmpty>Failed to load sessions</SessionSlotEmpty>;
  }

  if (loading) {
    return expand || onViewAll ? <></> : <SessionSlotLoading />;
  }

  const sessions = rawSessions.filter((session) => !session.hidden);
  const hiddenSessions = rawSessions.filter((session) => !!session.hidden);

  const onSlotSelect = (session: introwise.GroupSession) =>
    onSelect({
      isGroup: true,
      start: session.start,
      end: session.end,
      timezone: timeZone,
      title: session.title,
      price: session.price,
      currency: session.currency,
      sessionId: session.id,
    });

  return (
    <>
      {sessions.length > 0 ? (
        expand ? (
          <>
            {sessions.map((session) => (
              <div className={themeClasses({ marginTop: 8 })} key={session.id}>
                <h3>
                  <LinkUnstyled to={`sessions/${session.id}`}>{session.title}</LinkUnstyled>
                </h3>
                <p>
                  <b>{formatPriceReadable(session.price, session.currency)}</b>
                  {session.price ? ' per person,' : ','}{' '}
                  {formatDurationMins((session.end.getTime() - session.start.getTime()) / 60000)}
                </p>
                <DescriptionText text={session.description} />
                <SessionSlot
                  slot={{
                    price: session.price,
                    currency: session.currency,
                    start: session.start,
                    end: session.end,
                    units: `per person${
                      session.groupSize.remaining ? `, ${session.groupSize.remaining} spots left` : ''
                    }`,
                    isAvailableFromPackage: isSeriesAvailable(session.seriesId, session.start),
                  }}
                  timeZone={timeZone}
                  buttonText={
                    isSessionBooked(session.id) ? 'Booked' : session.groupSize.remaining > 0 ? 'Book' : 'Full'
                  }
                  buttonPrimary={session.groupSize.remaining > 0 && !isSessionBooked(session.id)}
                  buttonOnClick={() => onSlotSelect(session)}
                  disabled={session.groupSize.remaining === 0 || isSessionBooked(session.id)}
                />
              </div>
            ))}
          </>
        ) : (
          <>
            <SessionSlotsList>
              {sessions.map((session) => (
                <li key={session.id}>
                  <SessionSlot
                    slot={{
                      price: session.price,
                      currency: session.currency,
                      start: session.start,
                      end: session.end,
                      units: `per person${
                        freeSlotsInline && session.groupSize.remaining
                          ? `, ${session.groupSize.remaining} spots left`
                          : ''
                      }`,
                      title: hideTitle ? undefined : session.title,
                      isAvailableFromPackage: isSeriesAvailable(session.seriesId, session.start),
                    }}
                    timeZone={timeZone}
                    onClick={onClick && (() => onClick(session))}
                    buttonText={
                      isSessionBooked(session.id) ? 'Booked' : session.groupSize.remaining > 0 ? 'Book' : 'Full'
                    }
                    subButtonText={
                      freeSlotsInline
                        ? undefined
                        : session.groupSize.remaining
                        ? `${session.groupSize.remaining} spots left`
                        : undefined
                    }
                    buttonPrimary={session.groupSize.remaining > 0 && !isSessionBooked(session.id)}
                    buttonOnClick={() => onSlotSelect(session)}
                    disabled={session.groupSize.remaining === 0 || isSessionBooked(session.id)}
                  />
                </li>
              ))}
            </SessionSlotsList>
            {onViewAll && (
              <div className={themeClasses({ marginTop: 4 })}>
                <InlineButton onClick={onViewAll}>View all sessions</InlineButton>
              </div>
            )}
          </>
        )
      ) : (
        !hideEmpty && <SessionSlotEmpty>No upcoming sessions</SessionSlotEmpty>
      )}
      <HiddenGroupSessions pageId={pageId} pageOwnerId={expertId} sessions={hiddenSessions} />
    </>
  );
};

export default GroupSessionsSlots;
