import React from 'react';
import themeClasses from 'web/styles/themeClasses.css';
import themeVars from 'web/styles/themeVars.css';
import joinClassNames from 'web/utils/joinClassNames';

const TimeBlock = ({
  start,
  end,
  timeZone,
  className,
}: {
  start: Date;
  end: Date;
  timeZone: string;
  className?: string;
}) => {
  const time = `${start.toLocaleTimeString('en', {
    timeZone,
    hour: 'numeric',
    minute: 'numeric',
  })} – ${end.toLocaleTimeString('en', { timeZone, hour: 'numeric', minute: 'numeric' })}`;
  const date = start.toLocaleDateString('en', {
    timeZone,
    month: 'short',
    day: 'numeric',
    weekday: 'short',
  });
  const timeZoneFormat = timeZone.replace(/_/g, ' ');
  return (
    <div className={joinClassNames(className, themeClasses({ marginY: 5 }))}>
      <div style={{ fontSize: '20px' }}>{time}</div>
      <div>{date}</div>
      <div style={{ color: themeVars.color.halfMuted }}>{timeZoneFormat}</div>
    </div>
  );
};

export default TimeBlock;
