import React, { useContext, useEffect } from 'react';
import { LinkButton } from 'web/components/elements';
import ScreenTracker from 'web/components/ScreenTracker';
import BackButton from 'web/components/BackButton';
import BookingPageUrlContext from '../BookingPageUrlContext';
import { Title } from '../common';
import BookedPackageContext from './BookedPackageContext';

const ConfirmationPackage = ({
  bookedPackageId,
  email,
  onBack,
}: {
  bookedPackageId: string;
  email: string;
  onBack: () => void;
}) => {
  const pageRootUrl = useContext(BookingPageUrlContext);
  const packageManageUrl = `${pageRootUrl}packages/manage`;
  const { onPackageBook } = useContext(BookedPackageContext);
  useEffect(() => {
    onPackageBook(bookedPackageId);
  }, [bookedPackageId, onPackageBook]);
  return (
    <div style={{ minHeight: 450 }}>
      <ScreenTracker screenName="ConfirmationPackage" />
      <BackButton onClick={onBack} />
      <Title>Package is booked!</Title>
      <p>
        We{"'"}ve sent you a confirmation email to <b>{email}</b> with a special link to use your package. Use that link
        to access your package and book more sessions at any time.
      </p>
      <p>Start booking sessions included in your package by using the button below.</p>
      <p>
        <LinkButton primary to={packageManageUrl} replace>
          Book sessions
        </LinkButton>
      </p>
    </div>
  );
};

export default ConfirmationPackage;
