import { Query, queryEqual } from 'firebase/firestore';
import { useCollectionData } from 'web/hooks/firebase';
import useQueryCache from './useQueryCache';

type Options<T> = {
  initialData?: T[];
};

// TODO: Queries cache should not be in the global scope
// or there should be a method to reset it after rendering on the server
const firestoreCachedQueries: Query[] = [];

const getFirestoreQueryKey = (query: Query | null) => {
  if (!query) {
    return null;
  }
  const found = firestoreCachedQueries.findIndex((entry) => queryEqual(entry, query));
  const queryIndex = found !== -1 ? found : firestoreCachedQueries.push(query) - 1;
  return `firestore:query:${queryIndex}`;
};

const useFirestoreCollectionData = <T,>(query: Query<T> | null, options?: Options<T>) => {
  const result = useCollectionData<T>(query);
  const cachedResult = useQueryCache(getFirestoreQueryKey(query), result, options?.initialData);

  return cachedResult;
};

export default useFirestoreCollectionData;
