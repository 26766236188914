import React, { useContext, useMemo } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import theme from 'web/styles/theme';
import ThemeContext from './ThemeContext';

const customStyles = {
  overlay: {
    zIndex: 1,
  } as React.CSSProperties,
  content: {
    zIndex: 1,
    inset: '50% auto auto 50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: theme.borderRadius.md,
    boxShadow: theme.boxShadow.md,
    maxHeight: '90vh',
  } as React.CSSProperties,
};

Modal.setAppElement('#root');

const CloseButton = styled.button`
  border: none;
  background: none;
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;

  &:active {
    color: black;
  }
`;

const GenericReactModal = ({
  isOpen,
  onRequestClose,
  children,
  allowOverflow,
  ...rest
}: Modal.Props & { children: React.ReactNode; allowOverflow?: boolean }) => {
  const [themeClass, themeStyle] = useContext(ThemeContext);
  const style = useMemo(
    () => ({
      content: { ...customStyles.content, ...(allowOverflow && { overflow: 'initial' }) },
      overlay: { ...customStyles.overlay, ...themeStyle },
    }),
    [themeStyle, allowOverflow],
  );
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      portalClassName={themeClass || undefined}
      style={style}
      {...rest}
    >
      {onRequestClose && (
        <CloseButton onClick={onRequestClose}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>
      )}
      {children}
    </Modal>
  );
};

export default GenericReactModal;
