import React, { ButtonHTMLAttributes } from 'react';
import joinClassNames from 'web/utils/joinClassNames';
import button, { ButtonVariants, OldButtonVariants } from './button.css';

const Button = ({
  variant,
  size,
  primary,
  secondary,
  xs,
  sm,
  md,
  lg,
  className,
  children,
  ...props
}: React.DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> &
  ButtonVariants &
  OldButtonVariants) => (
  <button
    className={joinClassNames(
      className,
      button({
        variant: variant || (primary ? 'primary' : secondary ? 'secondary' : undefined),
        size: size || (xs ? 'xs' : sm ? 'sm' : md ? 'md' : lg ? 'lg' : undefined),
      }),
    )}
    {...props}
  >
    {children}
  </button>
);

Button.defaultProps = {
  type: 'button' as const,
};

export default Button;
