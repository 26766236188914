import { DocumentReference, DocumentSnapshot, FirestoreError, onSnapshot } from 'firebase/firestore';
import { useEffect, useMemo } from 'react';
import useLoadingState from './useLoadingState';
import useMemoRef from './useMemoRef';

const useDocumentData = <T,>(ref: DocumentReference<T> | null) => {
  const { error, loading, reset, setError, setValue, value } = useLoadingState<DocumentSnapshot<T>, FirestoreError>();
  const memoRef = useMemoRef(ref, reset);
  useEffect(() => {
    if (memoRef) {
      return onSnapshot(memoRef, setValue, setError);
    } else {
      setValue(undefined);
    }
  }, [memoRef, setError, setValue]);
  return useMemo(() => [value?.data(), loading, error] as const, [value, loading, error]);
};

export default useDocumentData;
