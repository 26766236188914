import React from 'react';
import styled, { css } from 'styled-components';
import { InlineButton } from 'web/components/elements';
import themeConstants from 'web/styles/themeConstants';
import themeVars from 'web/styles/themeVars.css';

export const calendarStyles = css`
  tbody > tr {
    height: 40px;
  }

  .fc-col-header {
    border: none;
    background-color: ${themeVars.calendar.primaryBackground};
  }

  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0;
  }

  .fc-timegrid-axis {
    border-bottom: none;
  }

  .fc-col-header-cell {
    border: none;
    white-space: pre-line;
    text-align: left;
  }

  .fc-col-header-cell-cushion {
    border: none;
  }

  .fc-scrollgrid-sync-inner {
    background-color: ${themeVars.calendar.primaryBackground};
  }

  .fc-timegrid-col.fc-day-today {
    background-color: ${themeVars.calendar.todayBackground};
    ${themeConstants.media.sm} {
      position: relative;
      ::before {
        ${themeConstants.typography.hero};
        content: 'TODAY';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) rotate(-90deg);
        opacity: 0.08;
      }
    }
  }

  .fc-list-day-cushion {
    padding: 0;
  }

  .fc-list-event-graphic {
    display: none;
  }

  .fc-timegrid-event {
    border: none;
    border-radius: 0;
    box-shadow: none;
  }

  .fc-timegrid-event .fc-event-main {
    padding: 0;
  }

  .fc-timegrid-col-events {
    margin-left: 0;
    margin-right: 0;
  }

  .fc-event-main {
    display: grid;
  }

  .fc-v-event {
    background-color: transparent;
    border-radius: 0;
  }

  table > colgroup > col:first-child {
    width: 36px !important;
  }
`;

export const CalendarContainer = styled.div.attrs({ primary: true })`
  ${calendarStyles};
  position: relative;
`;

export const CalendarLoading = styled.div`
  ${themeConstants.fake.loading}
  ${themeConstants.fake.animated}
  
  height: 70vh;
  position: absolute;
  left: 0;
  right: 0;
`;

const CalendarEmptyContainer = styled.div`
  position: absolute;
  inset: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 4;

  background: rgba(200, 200, 200, 0.2);
`;

const CalendarEmptyMessage = styled.div`
  background: #fff;
  border-radius: ${themeConstants.borderRadius.md};
  border: 2px solid ${themeConstants.borders.regular};
  padding: 8px 14px;
  text-align: center;
  display: grid;
  gap: 4px;
`;

export const CalendarEmpty = ({ onNext }: { onNext: () => void }) => (
  <CalendarEmptyContainer>
    <CalendarEmptyMessage>
      <div>No open slots on these days</div>
      <div>
        <InlineButton onClick={onNext}>Check next week</InlineButton>
      </div>
    </CalendarEmptyMessage>
  </CalendarEmptyContainer>
);
