import React from 'react';
import ScreenTracker from 'web/components/ScreenTracker';
import ScrollIntoViewOnMount from 'web/components/ScrollIntoViewOnMount';
import TimeZone from 'web/components/timezone/TimeZone';
import BackButton from 'web/components/BackButton';
import { GroupSessionSlot, Title } from './common';
import GroupSessionsSlots from './GroupSessionsSlots';

const GroupSessionsAll = ({
  page,
  onClick,
  onSelect,
}: {
  page: introwise.Page;
  onClick: (session: introwise.Session) => void;
  onSelect: (slot: GroupSessionSlot) => void;
}) => {
  return (
    <>
      <ScreenTracker screenName="GroupSessionsAll" />
      <ScrollIntoViewOnMount />
      <BackButton initialText="To booking page" initialTo="../.." />
      <Title>Group sessions</Title>
      <div style={{ minHeight: 450 }}>
        <GroupSessionsSlots pageId={page.id} expertId={page.ownerId} onClick={onClick} onSelect={onSelect} limit={40} />
      </div>
      <TimeZone />
    </>
  );
};

export default GroupSessionsAll;
