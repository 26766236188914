import { Query, queryEqual } from 'firebase/firestore';
import { useEffect, useRef } from 'react';

const useMemoQuery = <T,>(ref: Query<T> | null, onChange: () => void) => {
  const memoRef = useRef(ref);
  useEffect(() => {
    if (
      (ref === null && memoRef.current !== null) ||
      (memoRef.current && !queryEqual(memoRef.current, ref)) ||
      !memoRef.current
    ) {
      memoRef.current = ref;
      onChange?.();
    }
  }, [onChange, ref]);

  return memoRef.current;
};

export default useMemoQuery;
