import React from 'react';
import { Theme } from 'web/styles/styles';

/**
 * CSS Variables don't follow the rules of React context, which means that theming won't
 * automatically work when rendering to a portal. To handle this, we'll need to ensure
 * that theme class and style vars are available on the context.
 */
const ThemeContext = React.createContext<
  readonly [className: string | null, style: { [cssVarName: string]: string } | null, theme: Theme]
>([null, null, null]);

export default ThemeContext;
