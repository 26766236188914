import React, { useEffect } from 'react';
import { DescriptionText, formatDurationMins, formatPriceReadable, Title } from 'web/App/BookingPage/common';
import { Button, InlineButton } from 'web/components/elements';
import Meta from 'web/components/Meta';
import ScreenTracker from 'web/components/ScreenTracker';
import BackButton from 'web/components/BackButton';
import { NoticeCard } from 'web/components/NoticeCard';
import themeClasses from 'web/styles/themeClasses.css';

const formatCountWithPlural = (num: number) => `${num} session${num === 1 ? '' : 's'}`;

const PackageInfo = ({
  package: pack,
  onSelect,
  onPackageRestore,
  services,
  series,
  pageId,
}: {
  package: introwise.Package;
  onSelect: (pack: introwise.Package) => void;
  onPackageRestore: () => void;
  services: introwise.Page['services'];
  series: introwise.Page['series'];
  pageId: string;
}) => {
  useEffect(() => {
    if (pack.hidden) {
      try {
        window.sessionStorage.setItem('introwise-hidden-package', `${pageId}/${pack.id}`);
      } catch (err) {
        // do nothing
      }
    }
  }, [pack.id, pageId, pack.hidden]);

  const totalSessionsCount = Object.values(pack.personalSessions).reduce((acc, val) => acc + val.count, 0);

  const packServices = pack.personalSessions
    ? Object.keys(pack.personalSessions)
        .map((serviceId) => services[serviceId])
        .sort((a, b) => a.order - b.order)
    : [];
  const packSeries = pack.groupSessionSeries
    ? Object.keys(pack.groupSessionSeries)
        .map((seriesId) => series[seriesId])
        .sort((a, b) => a.order - b.order)
    : [];

  return (
    <div style={{ minHeight: 450 }}>
      <BackButton initialText="To booking page" initialTo="../.." />
      <ScreenTracker screenName="Package" />
      <Meta title={pack.title} description={pack.description} noIndex={pack.hidden} />
      <Title className={themeClasses({ marginBottom: 3 })}>{pack.title}</Title>
      <div>
        <b>
          {formatPriceReadable(
            pack.price * (pack.paymentPlan?.type === 'monthly' ? pack.paymentPlan.count : 1),
            pack.currency,
          )}
        </b>
        {totalSessionsCount > 0 && (
          <>
            {pack.price ? ' for' : ','} {formatCountWithPlural(totalSessionsCount)}
          </>
        )}
        {pack.paymentPlan?.type === 'monthly' && <>, split into {pack.paymentPlan.count} monthly payments</>}
      </div>
      <div>
        <DescriptionText text={pack.description} />
        <InlineButton onClick={() => onSelect(pack)}>Book now</InlineButton>
        <div>
          <h2>What&apos;s included</h2>
          {pack.expiryDays && (
            <NoticeCard className={themeClasses({ marginTop: 4 })}>
              This package expires. All sessions must be scheduled for no later than <b>{pack.expiryDays} days</b> after
              purchasing the package.
            </NoticeCard>
          )}
          {!packServices.length && !packSeries.length && (
            <NoticeCard className={themeClasses({ marginTop: 4 })}>
              This package does not include any sessions.
            </NoticeCard>
          )}
          {packServices.length > 0 &&
            packServices.map((service) => (
              <React.Fragment key={service.id}>
                <h3>{service.title}</h3>
                <p>
                  <b>{formatCountWithPlural(pack.personalSessions[service.id].count)}</b>
                  <span>&nbsp;· </span>
                  {formatDurationMins(service.duration)}
                </p>
                <DescriptionText text={service.description} />
              </React.Fragment>
            ))}
          {packSeries.length > 0 &&
            packSeries.map((includedSeries) => (
              <React.Fragment key={includedSeries.id}>
                <h3>{includedSeries.title}</h3>
                <p>
                  <b>{formatCountWithPlural(pack.groupSessionSeries[includedSeries.id].count)}</b>
                  <span>&nbsp;· </span>
                  {formatDurationMins(includedSeries.duration)}
                </p>
                <DescriptionText text={includedSeries.description} />
              </React.Fragment>
            ))}
        </div>
      </div>
      <div className={themeClasses({ marginTop: 10 })}>
        <Button primary onClick={() => onSelect(pack)}>
          Book this package
        </Button>
      </div>
      <div className={themeClasses({ marginTop: 4, marginBottom: 16 })}>
        <small>
          Already booked a package? <InlineButton onClick={onPackageRestore}>Click here to restore</InlineButton>
        </small>
      </div>
    </div>
  );
};

export default PackageInfo;
