import settings from 'web/utils/settings';
import { isClientSide } from 'web/utils/ssr';

const shouldUseEmulators =
  (isClientSide &&
    (window.location.origin === 'http://localhost:5000' ||
      (settings.debug.useEmulators && window.location.hostname === 'localhost'))) ||
  (!isClientSide && process.env?.['FUNCTIONS_EMULATOR'] === 'true');

export { shouldUseEmulators };
