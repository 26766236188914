import { useState } from 'react';
import useErrorHandler from './useErrorHandler';

const useErrorStateHandler = <T extends Error | string = Error>() => {
  const stateTuple = useState<T>();
  useErrorHandler(stateTuple[0]);
  return stateTuple;
};

export default useErrorStateHandler;
