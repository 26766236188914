import React, { useCallback, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BackButton from 'web/components/BackButton';
import BookingCalendar from 'web/components/calendar/BookingCalendar';
import Meta from 'web/components/Meta';
import ScreenTracker from 'web/components/ScreenTracker';
import ScrollIntoViewOnMount from 'web/components/ScrollIntoViewOnMount';
import TimeZone from 'web/components/timezone/TimeZone';
import { DescriptionText, formatDurationMins, formatPriceReadable, Slot, SlotTimeZoneTimeRange, Title } from './common';
import BookedPackageContext from './packages/BookedPackageContext';

const ServiceInfoCalendar = ({
  page,
  service,
  onSelect,
}: {
  page: introwise.Page;
  service: introwise.Service;
  onSelect: (slot: Slot) => void;
}) => {
  const { currency, ownerId: expertId, id: pageId } = page;
  const { title, duration, price, id: serviceId } = service;
  const { isServiceAvailable } = useContext(BookedPackageContext);

  const onSlotSelect = useCallback(
    (slot: SlotTimeZoneTimeRange) => onSelect({ ...slot, title, price, serviceId, currency }),
    [onSelect, title, price, currency, serviceId],
  );

  return (
    <BookingCalendar
      expertId={expertId}
      pageId={pageId}
      serviceId={serviceId}
      price={({ start }) => (isServiceAvailable(serviceId, start) ? 0 : price)}
      currency={currency}
      duration={duration}
      onSelect={onSlotSelect}
      experimentalTimeSlots={page.features?.experimentalTimeSlots}
    />
  );
};

const ServiceInfoImpl = ({
  page,
  service,
  onSelect,
}: {
  page: introwise.Page;
  service: introwise.Service;
  onSelect: (slot: Slot) => void;
}) => {
  useEffect(() => {
    if (service.hidden) {
      try {
        window.sessionStorage.setItem('introwise-hidden-service', `${page.id}/${service.id}`);
      } catch (err) {
        // do nothing
      }
    }
  }, [page.id, service.id, service.hidden]);

  return (
    <>
      <div style={{ minHeight: 350 }}>
        <Title style={{ marginBottom: 0 }}>{service.title}</Title>
        <p>
          <b>{formatPriceReadable(service.price, page.currency)}</b>
          {service.price ? ' for' : ','} {formatDurationMins(service.duration)}
        </p>
        {service.description && <DescriptionText text={service.description} />}
        <ServiceInfoCalendar page={page} service={service} onSelect={onSelect} />
      </div>
      <TimeZone />
    </>
  );
};

const ServiceInfo = ({ page, onSelect }: { page: introwise.Page; onSelect: (slot: Slot) => void }) => {
  const { serviceId } = useParams();
  const service = page.services[serviceId];

  if (!service) {
    return (
      <>
        <BackButton initialText="To booking page" />
        <Meta title="Not found" />
        <ScreenTracker screenName="ServiceInfo" />
        <ScrollIntoViewOnMount />
        <Title style={{ marginBottom: 0 }}>Not found</Title>
      </>
    );
  }

  return (
    <>
      <BackButton initialText="To booking page" />
      <Meta title={service.title} description={service.description} noIndex={service.hidden} />
      <ScreenTracker screenName="ServiceInfo" />
      <ScrollIntoViewOnMount />
      <ServiceInfoImpl page={page} service={service} onSelect={onSelect} />
    </>
  );
};

export default ServiceInfo;
