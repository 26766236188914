import React, { useContext, useEffect } from 'react';
import { LinkStyled } from 'web/components/elements';
import Meta from 'web/components/Meta';
import ScreenTracker from 'web/components/ScreenTracker';
import ScrollIntoViewOnMount from 'web/components/ScrollIntoViewOnMount';
import SessionSlot from 'web/components/SessionSlot';
import TimeZone from 'web/components/timezone/TimeZone';
import TimeZoneContext from 'web/components/timezone/TimeZoneContext';
import themeVars from 'web/styles/themeVars.css';
import BookingPageUrlContext from './BookingPageUrlContext';
import { DescriptionText, GroupSessionSlot, Title } from './common';

const GroupSessionInfo = ({
  page,
  session,
  onSelect,
}: {
  page: introwise.Page;
  session: introwise.GroupSession;
  onSelect: (slot: GroupSessionSlot) => void;
}) => {
  const { timeZone } = useContext(TimeZoneContext);
  const pageRootUrl = useContext(BookingPageUrlContext);
  const pageId = page.id;

  useEffect(() => {
    if (session.hidden) {
      try {
        window.sessionStorage.setItem('introwise-hidden-session', `${pageId}/${session.id}`);
      } catch (err) {
        // do nothing
      }
    }
  }, [session.id, pageId, session.hidden]);

  return (
    <>
      <Meta title={session.title} description={session.description} />
      <ScreenTracker screenName="GroupSessionInfo" />
      <ScrollIntoViewOnMount />
      <Title style={{ marginBottom: 0 }}>{session.title}</Title>
      {session.seriesId && (
        <div>
          Part of a series{' '}
          <LinkStyled to={`${pageRootUrl}series/${session.seriesId}`}>{page.series[session.seriesId].title}</LinkStyled>
        </div>
      )}
      <div style={{ marginBottom: 12 }}>
        <small style={{ color: themeVars.color.halfMuted }}>
          {session.groupSize.remaining ? `${session.groupSize.remaining} spots left` : 'Session is full'}
        </small>
      </div>
      <DescriptionText text={session.description} />
      <div style={{ minHeight: 350 }}>
        <SessionSlot
          slot={{
            start: session.start,
            end: session.end,
            price: session.price,
            currency: session.currency,
            units: 'per person',
          }}
          timeZone={timeZone}
          buttonOnClick={() =>
            onSelect({
              isGroup: true,
              start: session.start,
              end: session.end,
              timezone: timeZone,
              title: session.title,
              price: session.price,
              currency: session.currency,
              sessionId: session.id,
            })
          }
          buttonText={session.groupSize.remaining > 0 ? 'Book' : 'Full'}
          buttonPrimary={session.groupSize.remaining > 0}
          disabled={session.groupSize.remaining === 0}
        />
      </div>
      <TimeZone />
    </>
  );
};

export default GroupSessionInfo;
