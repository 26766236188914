import React from 'react';
import joinClassNames from 'web/utils/joinClassNames';
import { formControlWithError } from './formControl.css';
import select from './select.css';

const Select = React.forwardRef<
  HTMLSelectElement,
  React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> & { hasError?: boolean }
>(({ className, children, hasError, ...props }, ref) => (
  <select className={joinClassNames(className, select, hasError && formControlWithError)} {...props} ref={ref}>
    {children}
  </select>
));

Select.displayName = 'Select';

export default Select;
