import React, { useCallback, useContext, useEffect } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import BookingCalendar from 'web/components/calendar/BookingCalendar';
import ScreenTracker from 'web/components/ScreenTracker';
import ScrollIntoViewOnMount from 'web/components/ScrollIntoViewOnMount';
import TimeZone from 'web/components/timezone/TimeZone';
import BackButton from 'web/components/BackButton';
import { PersonalSessionSlot, SlotTimeZoneTimeRange, Title } from './common';
import BookedPackageContext from './packages/BookedPackageContext';
import ServiceSelector from './ServiceSelector';

const PersonalSessionsCalendar = ({
  page,
  onSelect,
}: {
  page: introwise.Page;
  onSelect: (slot: PersonalSessionSlot) => void;
}) => {
  const { services, currency } = page;
  const { isServiceAvailable } = useContext(BookedPackageContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const serviceIdFromURL = searchParams.get('serviceId');
  const setServiceId = useCallback(
    (serviceId: string) => {
      setSearchParams(createSearchParams({ serviceId }), { replace: true });
    },
    [setSearchParams],
  );
  const isServiceIdFromURLValid = serviceIdFromURL && !!services[serviceIdFromURL];
  const servicesSorted = Object.keys(services)
    .map((key) => services[key])
    .filter((service) => !service.hidden || isServiceAvailable(service.id))
    .sort((a, b) => a.order - b.order);
  const serviceId = isServiceIdFromURLValid ? serviceIdFromURL : servicesSorted[0].id;

  useEffect(() => {
    if (!isServiceIdFromURLValid) {
      setServiceId(serviceId);
    }
  }, [serviceId, isServiceIdFromURLValid, setServiceId]);

  const { title, duration, price } = services[serviceId];
  const onSlotSelect = useCallback(
    (slot: SlotTimeZoneTimeRange) => onSelect({ ...slot, title, price, serviceId, currency }),
    [onSelect, title, price, currency, serviceId],
  );

  return (
    <>
      <ScrollIntoViewOnMount />
      <ScreenTracker screenName="PersonalSessionsCalendar" />
      <BackButton initialText="To booking page" />
      {servicesSorted.length > 1 ? (
        <div style={{ marginTop: 20 }}>
          <ServiceSelector services={servicesSorted} onChange={setServiceId} defaultValue={serviceId} />
        </div>
      ) : (
        <Title>Choose a personal session time</Title>
      )}
      <BookingCalendar
        expertId={page.ownerId}
        pageId={page.id}
        serviceId={serviceId}
        price={({ start }) => (isServiceAvailable(serviceId, start) ? 0 : price)}
        currency={currency}
        duration={duration}
        onSelect={onSlotSelect}
        experimentalTimeSlots={page.features?.experimentalTimeSlots}
      />
      <TimeZone />
    </>
  );
};

export default PersonalSessionsCalendar;
