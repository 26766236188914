import { RegisterOptions } from 'react-hook-form';

const maxNameLength = 40;

const nameValidationRules: RegisterOptions = {
  required: 'Required',
  minLength: {
    value: 1,
    message: 'Too short, please use at least 1 character',
  },
  maxLength: {
    value: maxNameLength,
    message: `Too long, please limit the name to ${maxNameLength} characters`,
  },
  setValueAs: (v) => v.trim(),
};

const emailValidationRules: RegisterOptions = {
  required: 'Required',
  pattern: {
    value:
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9]))+$/,
    message: 'Invalid email address',
  },
  setValueAs: (v) => v.trim(),
};

export { nameValidationRules, emailValidationRules };
