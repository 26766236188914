import { httpsCallable } from 'firebase/functions';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Title } from 'web/App/BookingPage/common';
import BackButton from 'web/components/BackButton';
import { Button, FormDescription, FormError, FormGroup, Input, Label, LabelText } from 'web/components/elements';
import useFunctions from 'web/components/FirebaseContext/useFunctions';
import ScreenTracker from 'web/components/ScreenTracker';
import Spinner from 'web/components/Spinner';
import useErrorReporter from 'web/hooks/useErrorReporter';
import themeClasses from 'web/styles/themeClasses.css';

type FormValues = {
  email: string;
};

const PackageRestore = ({ pageId }: { pageId: string }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues: { email: '' },
    mode: 'onBlur',
  });

  const functions = useFunctions();
  const [email, setEmail] = useState('');
  const [messageSent, setMessageSent] = useState(false);
  const [submitting, setLoading] = useState(false);
  const errorReporter = useErrorReporter();

  const onSubmit = (values: FormValues) => {
    const sendLink = async () => {
      setLoading(true);

      try {
        const trimmedEmail = values.email.trim();
        await httpsCallable(
          functions,
          'bookedPackagesSendRestoreLink',
        )({
          pageId,
          email: trimmedEmail,
        });

        setMessageSent(true);
        setEmail(trimmedEmail);
      } catch (ex) {
        errorReporter.report('Failed to send restore link', ex);
      }

      setLoading(false);
    };

    return sendLink();
  };

  return (
    <>
      <ScreenTracker screenName="PackageRestore" />
      <BackButton />
      <div style={{ minHeight: 450 }}>
        <Title className={themeClasses({ marginBottom: 3 })}>Restore booked package</Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          {!messageSent && (
            <>
              <FormGroup>
                Enter the email address that you booked a package with and we&apos;ll send you a special link to access
                your package
              </FormGroup>
              <FormGroup>
                <Label>
                  <LabelText>Email</LabelText>
                  <Input
                    {...register('email', {
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Invalid email address',
                      },
                    })}
                    type="text"
                    placeholder="email@example.com"
                    hasError={!!errors.email}
                  />
                </Label>
                <FormDescription>
                  Not sure which email you used? Check your inbox for emails from introwise.com
                </FormDescription>
                {errors.email && <FormError>{errors.email.message}</FormError>}
              </FormGroup>
            </>
          )}
          {messageSent && (
            <FormGroup>
              Check your email <b>{email}</b>. We&apos;ve sent you a link to access your booked package.
            </FormGroup>
          )}
          {!messageSent && (
            <FormGroup>
              <Button primary type="submit" disabled={submitting}>
                {submitting && <Spinner />}
                <span>Send access link</span>
              </Button>
            </FormGroup>
          )}
        </form>
      </div>
    </>
  );
};

export default PackageRestore;
