import React, { useContext, useMemo } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import BackButton from 'web/components/BackButton';
import CheckoutContext from './CheckoutContext';
import { ServiceProduct } from './common';
import SessionBookingForm from './SessionBookingForm';
import useCheckoutInit from './useCheckoutInit';

const PersonalSessionBookingImpl = ({
  page,
  service,
  onConfirmationBack,
  start,
  orderId,
}: {
  page: introwise.Page;
  service: introwise.Service;
  start: Date;
  onConfirmationBack: () => void;
  orderId?: string;
}) => {
  const product: ServiceProduct = useMemo(() => {
    if (!service || !start) {
      return null;
    }
    return {
      type: 'service',
      serviceId: service.id,
      start,
      service,
    };
  }, [service, start]);

  useCheckoutInit(product);
  const [productInitialized] = useContext(CheckoutContext);

  if (!productInitialized) {
    return <></>;
  }

  return <SessionBookingForm page={page} orderId={orderId} onConfirmationBack={onConfirmationBack} />;
};

const PersonalSessionBooking = ({
  page,
  onConfirmationBack,
}: {
  page: introwise.Page;
  onConfirmationBack: () => void;
}) => {
  const { serviceId } = useParams();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId');
  const slotStart = searchParams.get('slotStart');

  const renderInvalidUrl = () => (
    <>
      <BackButton />
      <p>Something went wrong. This URL is invalid - did you copy it correctly?</p>
    </>
  );

  const service = page.services?.[serviceId];
  const startParsed = Date.parse(slotStart);

  if (!service || !startParsed) {
    return renderInvalidUrl();
  }

  const start = new Date(startParsed);

  return (
    <PersonalSessionBookingImpl
      page={page}
      onConfirmationBack={onConfirmationBack}
      service={service}
      start={start}
      orderId={orderId}
    />
  );
};

export default PersonalSessionBooking;
