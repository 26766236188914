import React from 'react';
import loadable from '@loadable/component';
import themeConstants from 'web/styles/themeConstants';
import themeClasses from 'web/styles/themeClasses.css';
import sc from '../styled';

// react-select is not ssr-friendly, and timezone data is not available server-side anyway,
// so we dynamically import it client-side only
const TimezoneSelectFake = sc.div(themeClasses({ borderRadius: 'sm', paddingY: 1 }), themeConstants.fake.default);

const TimezoneSelect = loadable(() => import(/* webpackPreload: true */ 'web/components/timezone/TimezoneSelect'), {
  ssr: false,
  fallback: <TimezoneSelectFake>Loading timezone...</TimezoneSelectFake>,
});

const TimeZone = () => {
  return (
    <div
      className={themeClasses({
        display: { md: 'flex' },
        marginTop: 6,
        marginBottom: 2,
        alignItems: 'center',
      })}
    >
      <div style={{ flex: 'none' }}>Timezone: </div>
      <div style={{ flexGrow: 1 }}>
        <TimezoneSelect />
      </div>
    </div>
  );
};

export default TimeZone;
