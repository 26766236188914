import React from 'react';
import joinClassNames from 'web/utils/joinClassNames';
import { formControlWithError } from './formControl.css';
import input from './input.css';

const Input = React.forwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & { hasError?: boolean }
>(({ className, children, hasError, ...inputProps }, ref) => (
  <input className={joinClassNames(className, input, hasError && formControlWithError)} {...inputProps} ref={ref}>
    {children}
  </input>
));

Input.displayName = 'Input';

export default Input;
