import { CSSObject } from 'styled-components';

const nunitoSansFont = `'Nunito Sans', Arial, sans-serif`;
const nunitoFont = `Nunito, Arial, sans-serif`;

const typography: { [index: string]: CSSObject } = {
  regular: {
    fontFamily: nunitoSansFont,
    fontSize: 16,
    letterSpacing: '1%',
    lineHeight: 1.62,
    fontWeight: 400,
  },
  small: {
    fontSize: '0.75em',
  },
  strong: {
    fontWeight: 'bolder',
  },
  hero: {
    fontFamily: nunitoFont,
    fontWeight: 700,
    lineHeight: 1.2,
    fontSize: 56,
  },
  h1: {
    fontFamily: nunitoFont,
    fontSize: 32,
    fontWeight: 700,
    lineHeight: 1.2,
  },
  h2: {
    fontSize: 26,
    lineHeight: 1.52,
    fontWeight: 400,
  },
  h3: {
    fontSize: 20,
    fontWeight: 700,
  },
  h4: {
    fontFamily: nunitoFont,
    fontSize: 16,
    fontWeight: 700,
  },
  logo: {
    fontFamily: nunitoFont,
    fontWeight: 600,
  },
};

const color = {
  hr: '#d9d9d9',

  buttonPrimary: '#256378',
  buttonAccent: '#cde0c9',
  buttonFocus: 'none',
  muted: '#b5b5b5',
  mutedButton: 'none',
  mutedText: 'none',
  halfMuted: '#777777',
  fake: '#f6f6f6',
  hover: 'none',
  link: '#256378',
  error: '#f93919',
  labelColor: '#827F7C',
};

const buttonBackgrounds = {
  cta: `linear-gradient(252deg, ${color.buttonPrimary}, ${color.buttonAccent})`,
  primary: color.buttonPrimary,
  secondary: '#fff',
  disabledPrimary: color.hr,
  disabledSecondary: '#fff',
  muted: '#f3f3f3',
  hover: `linear-gradient(252deg, ${color.buttonPrimary}, ${color.buttonAccent})`,
  click: `linear-gradient(33deg, ${color.buttonPrimary}, ${color.buttonAccent})`,
  clickPrimary: `linear-gradient(33deg, ${color.buttonPrimary}, ${color.buttonAccent})`,
  clickSecondary: `linear-gradient(33deg, ${color.buttonPrimary}, ${color.buttonAccent})`,
  light: `linear-gradient(66deg, rgba(${color.buttonPrimary}, 0.32), rgba(${color.buttonAccent}, 0.32))`,
};

const buttonBorders = {
  hover: color.buttonPrimary,
  focus: color.buttonAccent,
  primary: color.buttonPrimary,
  secondary: color.buttonAccent,
  disabledPrimary: color.hr,
  disabledSecondary: color.hr,
  click: 'none',
};

const buttonColors = {
  primary: color.buttonPrimary,
  disabled: color.hr,
  muted: '#f3f3f3',
  secondary: 'none',
  hovered: '#ffffff',
  clickSecondaryText: '#ffffff',
  focus: 'none',
};

const button = {
  transition: 'none',
  boxShadow: 'none',
  color: buttonColors,
  background: buttonBackgrounds,
  borderColor: buttonBorders,
  borderRadius: '6px',
  opacity: {
    hover: 0.8,
  },
  font: {
    fontFamily: nunitoFont,
    fontWeight: 700,
  } as CSSObject,
};

const form = {
  color: {
    border: color.hr,
    borderFocus: color.halfMuted,
    placeholder: color.muted,

    inputBorderError: color.error,

    inputLabel: 'none',
    inputLabelHover: 'none',

    inputHover: 'none',
    inputBackground: 'none',
    inputBackgroundDefault: 'none',
    inputBackgroundHover: 'none',
    inputBackgroundActive: 'none',
    inputPlaceholderDisabled: 'none',

    inputColor: 'none',
  },
  inputHeight: 'none',
};

const borderRadius = {
  sm: '6px',
  md: '12px',
  lg: '24px',
  default: '12px',
};

const fakeDefault: CSSObject = {
  color: 'transparent',
  background: color.fake,
  borderRadius: borderRadius.sm,
};

const fake = {
  default: fakeDefault,
  loading: fakeDefault,
  error: {
    color: 'transparent',
    backgroundColor: '#fff',
    borderRadius: borderRadius.default,
    backgroundImage: `url(
      "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23e2d202' fill-opacity='1'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"
    )`,
    border: `2px dashed #e2d202`,
  } as CSSObject,
  notFound: {
    color: 'transparent',
    backgroundColor: color.fake,
    borderRadius: borderRadius.default,
    backgroundImage: `url(
      "data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23000000' fill-opacity='0.1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E"
    )`,
    border: `1px dashed ${color.muted}`,
  } as CSSObject,
};

const nonTextLink = `
  text-decoration: none;
  color: inherit;
  &:hover,
  &:focus {
    text-decoration: none;
    color: initial;
  }
`;

const mixins = {
  nonTextLink,
};

const defaultTheme = {
  borderRadius,
  boxShadow: {
    sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  },
  media: {
    sm: '@media (min-width: 640px)',
    md: '@media (min-width: 768px)',
    lg: '@media (min-width: 1024px)',
    xl: '@media (min-width: 1280px)',
  },
  fake,
  typography,
  button,
  form,
  color,
  mixins,
};

export default defaultTheme;
