import React, { useEffect, useState } from 'react';

const ClientSideOnly = ({
  fallback = null,
  children,
}: {
  fallback?: React.ReactElement;
  children: React.ReactNode;
}) => {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => setIsClient(true), []);
  return isClient ? <>{children}</> : fallback;
};

export default ClientSideOnly;
