import React, { useContext } from 'react';
import BackButton from 'web/components/BackButton';
import ScreenTracker from 'web/components/ScreenTracker';
import CopyLink from 'web/components/share/CopyLink';
import settings from 'web/utils/settings';
import BookingPageUrlContext from './BookingPageUrlContext';
import { Title } from './common';

const Confirmation = ({
  bookedSessionId,
  email,
  onBack,
}: {
  bookedSessionId: string;
  email: string;
  onBack: () => void;
}) => {
  const onCustomDomain = useContext(BookingPageUrlContext) === '/';
  const sessionPath = `/s/${bookedSessionId}`;
  const sessionLink = onCustomDomain ? `${settings.customDomain.appUrl}${sessionPath}` : sessionPath;
  return (
    <>
      <ScreenTracker screenName="Confirmation" />
      <BackButton onClick={onBack} />
      <Title>Session is booked!</Title>
      <div style={{ minHeight: 350 }}>
        <p>
          We{"'"}ve sent you a confirmation email to <b>{email}</b> with a link to join and a calendar invite.
        </p>
        <p>Your session will happen at this link:</p>
        <CopyLink to={sessionLink} external={onCustomDomain} />
      </div>
    </>
  );
};

export default Confirmation;
