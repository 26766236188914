import React from 'react';
import { useRoutes } from 'react-router-dom';
import { BackButtonProvider } from 'web/components/BackButtonContext';
import ErrorBoundary from 'web/components/ErrorBoundary';
import PageTracker from 'web/components/PageTracker';
import { TrackingProvider } from 'web/components/TrackingContext';
import { RouteObjectWithLoader } from 'web/data/types';
import 'web/styles/GlobalStyle';
import BookingPageCustomDomain from './BookingPage/BookingPageCustomDomain';

export const routes: RouteObjectWithLoader[] = [
  {
    path: '/*',
    element: <BookingPageCustomDomain />,
    loader: (match, origin) =>
      import('web/data/loaders').then(({ bookingPageLoaderCustomDomain }) =>
        bookingPageLoaderCustomDomain(match, origin),
      ),
  },
];

const CustomDomainApp = () => {
  const element = useRoutes(routes);
  return (
    <TrackingProvider>
      <BackButtonProvider>
        <PageTracker />
        <ErrorBoundary>{element}</ErrorBoundary>
      </BackButtonProvider>
    </TrackingProvider>
  );
};

export default CustomDomainApp;
