import React from 'react';
import { Helmet } from 'react-helmet-async';

const shrinkString = (string: string, length: number) => {
  const trimmed = string.trim();
  if (trimmed.length <= length) {
    return trimmed;
  }
  if (length < 0) {
    return trimmed;
  }
  if (length < 3) {
    return trimmed.substr(0, length);
  }
  return `${trimmed.substr(0, length - 1)}\u2026`;
};

const Meta = ({
  title,
  description,
  image,
  canonicalUrl,
  noIndex,
}: {
  title: string;
  description?: string;
  image?: string;
  canonicalUrl?: string;
  noIndex?: boolean;
}) => (
  <Helmet>
    <title>{title}</title>
    {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
    {noIndex && <meta name="robots" content="noindex,nofollow" />}
    <meta name="title" content={title} />
    {description && <meta name="description" content={shrinkString(description, 300)} />}
    <meta property="og:type" content="website" />
    {canonicalUrl && <meta property="og:url" content={canonicalUrl} />}
    <meta property="og:title" content={title} />
    {description && <meta property="og:description" content={shrinkString(description, 300)} />}
    {image && <meta property="og:image" content={image} />}
    <meta property="twitter:card" content={image ? 'summary_large_image' : 'summary'} />
    {canonicalUrl && <meta property="twitter:url" content={canonicalUrl} />}
    <meta property="twitter:title" content={title} />
    {description && <meta property="twitter:description" content={shrinkString(description, 300)} />}
    {image && <meta property="twitter:image" content={image} />}
  </Helmet>
);

export default Meta;
