import React from 'react';
import PayPalProvider from 'web/components/PayPalProvider';
import StripeProvider from 'web/components/StripeProvider';
import useOrderStateMachine from './useOrderStateMachine';

type OrderContextType = ReturnType<typeof useOrderStateMachine>;

const OrderContext = React.createContext<OrderContextType>(null);

const OrderProvider = ({
  children,
  page,
  orderId,
}: {
  children: React.ReactNode;
  page: introwise.Page;
  orderId?: string;
}) => {
  const machine = useOrderStateMachine({ page, initialOrderId: orderId });

  const [state] = machine;

  const order = state.context.order;

  const renderWithPaymentContext = (children: React.ReactNode) => {
    if (order?.payment?.gateway === 'paypal') {
      return (
        <PayPalProvider currency={order.currency} merchantId={order.payment.paypalMerchantId}>
          {children}
        </PayPalProvider>
      );
    } else if (order?.payment?.gateway === 'stripe') {
      return (
        <StripeProvider accountId={order.payment.stripeAccountId} clientSecret={order.payment.stripeClientSecret}>
          {children}
        </StripeProvider>
      );
    } else {
      return <>{children}</>;
    }
  };

  return <OrderContext.Provider value={machine}>{renderWithPaymentContext(children)}</OrderContext.Provider>;
};

export default OrderContext;
export { OrderProvider };
