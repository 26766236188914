import React, { useContext, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import OrderForm, { OrderFormFake } from 'web/App/BookingPage/OrderForm';
import GenericReactModal from 'web/components/GenericReactModal';
import ScreenTracker from 'web/components/ScreenTracker';
import ScrollIntoViewOnMount from 'web/components/ScrollIntoViewOnMount';
import ScrollToTopOnMount from 'web/components/ScrollToTopOnMount';
import Spinner from 'web/components/Spinner';
import TimeZoneContext from 'web/components/timezone/TimeZoneContext';
import themeClasses from 'web/styles/themeClasses.css';
import BackButton from 'web/components/BackButton';
import CheckoutContext from '../CheckoutContext';
import { BookingDetails, PackageProduct, Title } from '../common';
import { BookingError, RetryDialog, RetryPaymentDialog } from '../orderCommon';
import OrderContext, { OrderProvider } from '../OrderContext';
import useCheckoutInit from '../useCheckoutInit';
import ConfirmationPackage from './ConfirmationPackage';

const PackageCheckoutForm = ({
  discountCodeEnabled,
  displayName,
}: {
  discountCodeEnabled: boolean;
  displayName: string;
}) => {
  const [state, send] = useContext(OrderContext);
  const { timeZone } = useContext(TimeZoneContext);
  const { order, error } = state.context;

  const [initialFormValues] = useState<{
    firstName: string;
    lastName: string;
    email: string;
    message: string;
    code: string;
  } | null>(() =>
    order
      ? {
          firstName: order.client?.firstName,
          lastName: order.client?.lastName,
          email: order.client?.email,
          message: order.message,
          code: order.discount ? order.discount.code : order.partner ? order.partner.code : undefined,
        }
      : null,
  );

  const paymentGateway = order?.payment?.gateway;
  const withPaymentDue = order?.amountDue > 0 && paymentGateway !== 'none';
  const isPartnerOrder = order?.partner;
  const withoutPaymentWithConfirmation = paymentGateway === 'none' && order?.amountTotal !== 0 && !isPartnerOrder;
  const isPackagePaid = order?.amountSubtotal > 0;
  const withCode = discountCodeEnabled && isPackagePaid;

  const orderReady = !state.matches('preparing');
  const submitting = state.matches('submitting');

  const book = async (bookingDetails: BookingDetails) => {
    const order = {
      client: {
        email: bookingDetails.email,
        firstName: bookingDetails.firstName,
        lastName: bookingDetails.lastName,
        timezone: timeZone,
      },
      message: bookingDetails.message,
    };

    send('SUBMIT', { order });
  };

  return (
    <>
      <ScreenTracker screenName="PackageBookingForm" />
      <ScrollToTopOnMount />
      <BackButton initialText="To booking page" initialTo="../.." />
      <Title>Booking details</Title>
      <GenericReactModal isOpen={state.matches('submitting.confirmedUnpaid')}>
        <RetryPaymentDialog onRetry={() => send('RETRY')} error={error} />
      </GenericReactModal>
      <GenericReactModal isOpen={state.matches('submitting.confirmRetry')}>
        <RetryDialog onRetry={() => send('RETRY')} error={error} />
      </GenericReactModal>
      <OrderForm
        defaultValues={initialFormValues}
        onSubmit={book}
        submitting={submitting}
        bookErrorMessage={error}
        paymentGateway={paymentGateway}
        withoutPayment={!withPaymentDue}
        withoutPaymentWithConfirmation={withoutPaymentWithConfirmation}
        withCode={withCode}
        orderReady={orderReady}
        displayName={displayName}
        paymentPlan={order?.paymentPlan}
      />
    </>
  );
};

const PackageCheckoutOrderContainer = ({
  page,
  onConfirmationBack,
}: {
  page: introwise.Page;
  onConfirmationBack: () => void;
}) => {
  const [state, send] = useContext(OrderContext);
  const { error } = state.context;

  // const isPagePartnerEligible = (page as { partnerEligible?: boolean }).partnerEligible;
  const isPageWithDiscounts = (page as { hasDiscounts?: boolean }).hasDiscounts;

  if (state.matches('error')) {
    return <BookingError error={error} />;
  } else if (state.matches('completed')) {
    return (
      <ConfirmationPackage
        onBack={onConfirmationBack}
        email={state.context.order.client.email}
        bookedPackageId={(state.context.order?.fulfillment as introwise.OrderPackageFulfillment).bookedPackageId}
      />
    );
  } else if (state.matches('preparing')) {
    return (
      <>
        <ScreenTracker screenName="PackageBookingForm" />
        <ScrollIntoViewOnMount />
        <BackButton initialText="To booking page" initialTo="../.." />
        <Title>Booking details</Title>
        <OrderFormFake />
      </>
    );
  } else if (state.matches('loading')) {
    return (
      <>
        <GenericReactModal isOpen={state.matches('loading.loadRetry')}>
          <RetryDialog onRetry={() => send('RETRY')} error={error} />
        </GenericReactModal>
        <div
          className={themeClasses({ display: 'grid', justifyContent: 'center', alignItems: 'center' })}
          style={{ height: 400 }}
        >
          <Spinner />
        </div>
      </>
    );
  } else {
    return <PackageCheckoutForm discountCodeEnabled={isPageWithDiscounts} displayName={page.displayName} />;
  }
};

const PackageCheckout = ({
  page,
  package: pack,
  onConfirmationBack,
}: {
  page: introwise.Page;
  package: introwise.Package;
  onConfirmationBack: () => void;
}) => {
  const product: PackageProduct = useMemo(
    () => ({
      type: 'package',
      packageId: pack.id,
      package: pack,
    }),
    [pack],
  );

  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId');

  useCheckoutInit(product);
  const [productSelected] = useContext(CheckoutContext);

  if (!productSelected) {
    return <></>;
  }

  return (
    <OrderProvider page={page} orderId={orderId}>
      <PackageCheckoutOrderContainer page={page} onConfirmationBack={onConfirmationBack} />
    </OrderProvider>
  );
};

export default PackageCheckout;
