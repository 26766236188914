import React from 'react';
import joinClassNames from 'web/utils/joinClassNames';
import { lazyImgBlur } from './lazyImg.css';

const LazyImg = ({
  lqip,
  style,
  className,
  onLoad,
  as: RenderComponent = 'img',
  ...rest
}: {
  lqip?: string;
  as?: React.ComponentType<JSX.IntrinsicElements['img']> | 'img';
} & JSX.IntrinsicElements['img']) => {
  const [loaded, setLoaded] = React.useState(false);
  return (
    <RenderComponent
      style={{
        ...(lqip && !loaded && { background: `url(${lqip}) center/cover no-repeat` }),
        ...style,
      }}
      className={joinClassNames(className, lqip && !loaded && lazyImgBlur)}
      onLoad={(e) => {
        setLoaded(true);
        onLoad?.(e);
      }}
      loading="lazy"
      {...rest}
    />
  );
};

export default LazyImg;
