import React from 'react';
import styled, { css } from 'styled-components';
import themeConstants from 'web/styles/themeConstants';

type GradientProp = { from: string; to: string };

export const LogoImage = styled(({ className, gradient }: { className?: string; gradient?: GradientProp }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 35 48">
    <path d="M17.4874 0C7.82205 0.00704093 -0.00705757 7.73395 4.77425e-06 17.2583C-0.00137652 20.3477 0.840913 23.379 2.43651 26.0273C5.26398 30.5334 8.9695 34.428 13.3337 37.4809C12.62 38.1289 12.0726 38.9381 11.7372 39.8407C11.402 40.7432 11.2886 41.7128 11.4067 42.668C11.7749 45.6735 14.3729 48.002 17.5207 48C20.6705 47.998 23.2644 45.6654 23.6286 42.659C23.7451 41.7038 23.6304 40.7347 23.2938 39.8328C22.9574 38.9311 22.4089 38.1228 21.6945 37.4758C26.0173 34.4442 29.692 30.5857 32.5049 26.1249C34.1425 23.4472 35.006 20.3703 35 17.2342C34.9929 7.71484 27.1598 0.00301754 17.5025 0H17.4874ZM14.4808 32.0201C11.3192 29.8082 8.63467 26.9865 6.58615 23.7219C5.43006 21.8033 4.81972 19.6072 4.82056 17.369C4.81652 10.4689 10.4886 4.87131 17.4904 4.86629C24.4922 4.86126 30.1723 10.4507 30.1784 17.3519C30.1831 19.8408 29.4322 22.273 28.0244 24.3284C25.373 28.3341 21.7592 31.6154 17.5116 33.8739C16.4645 33.3184 15.4528 32.6993 14.4818 32.0201H14.4808ZM15.8731 42.0062C15.8731 41.111 16.6076 40.3867 17.5156 40.3857C18.4236 40.3847 19.1601 41.11 19.1601 42.0041C19.1591 42.2181 19.1157 42.4298 19.0326 42.6271C18.9496 42.8244 18.8282 43.0035 18.6758 43.154C18.5232 43.3046 18.3424 43.4237 18.1438 43.5047C17.945 43.5855 17.7322 43.6267 17.5176 43.6256C16.6096 43.6256 15.8741 42.9004 15.8731 42.0062Z" />
    <defs>
      <linearGradient id="linear" x1="17.5" y1="-24" x2="-28.2013" y2="9.32389" gradientUnits="userSpaceOnUse">
        <stop stopColor={gradient?.from || 'currentColor'} />
        <stop offset="1" stopColor={gradient?.to || 'currentColor'} />
      </linearGradient>
    </defs>
  </svg>
))<{ color?: string; gradient?: GradientProp }>`
  height: calc(48 / 34 * 1em);
  fill: ${({ color, gradient }) => color || (gradient ? 'url(#linear)' : 'currentColor')};
`;

export const LogoText = styled.div<{ color?: string; gradient?: GradientProp }>`
  ${themeConstants.typography.logo};
  ${({ color }) => color && `color: ${color};`}
  ${({ gradient }) =>
    gradient &&
    css`
      color: ${gradient.to};
      background-image: linear-gradient(225deg, ${gradient.from} 0%, ${gradient.to} 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `}
  text-align: center;
`;

const logoContainer = css`
  font-size: 34px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  > * {
    flex: none;
  }

  ${LogoText} {
    margin-left: calc(12 / 34 * 1em);
  }
`;

const Logo = styled(
  ({
    color,
    gradient,
    className,
    style,
  }: {
    color?: string;
    gradient?: GradientProp;
    className?: string;
    style?: React.CSSProperties;
  }) => (
    <div className={className} style={style}>
      <LogoImage color={color} gradient={gradient} />
      <LogoText color={color} gradient={gradient} className="notranslate">
        introwise
      </LogoText>
    </div>
  ),
)`
  ${logoContainer}
`;

export default Logo;
