import React from 'react';
import { useContext } from 'react';
import { Navigate } from 'react-router';
import BookingPageUrlContext from '../BookingPageUrlContext';
import BookedPackageContext from './BookedPackageContext';

const PackageUse = () => {
  const { loading, bookedPackage } = useContext(BookedPackageContext);
  const pageRootUrl = useContext(BookingPageUrlContext);

  if (loading) {
    return <>Loading package...</>;
  }

  if (!bookedPackage) {
    return <>Package not found</>;
  }

  return <Navigate to={`${pageRootUrl}packages/manage`} state={{ afterUse: true }} />;
};

export default PackageUse;
