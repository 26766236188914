import sanityClient from '@sanity/client';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import settings from 'web/utils/settings';

const config = {
  projectId: settings.sanity.projectId,
  dataset: settings.sanity.dataset,
  apiVersion: '2021-05-03',
};

const getSanity = () =>
  sanityClient({
    ...config,
    useCdn: true,
  });

const useSanity = () => {
  const [search] = useSearchParams();
  const preview = search.has('preview');
  const client = useMemo(
    () =>
      sanityClient({
        ...config,
        ...(preview
          ? {
              useCdn: false,
              withCredentials: true,
            }
          : {
              useCdn: true,
            }),
      }),
    [preview],
  );
  return client;
};

export { getSanity };
export default useSanity;
