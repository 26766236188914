import { logEvent } from 'firebase/analytics';
import { useMemo } from 'react';
import useAnalytics from 'web/components/FirebaseContext/useAnalytics';
import errorReporter from 'web/utils/error-reporter';

const useErrorReporter = () => {
  const analytics = useAnalytics();
  const reporter: typeof errorReporter = useMemo(
    () => ({
      setUser: (userId) => errorReporter.setUser(userId),
      report: analytics
        ? (error: string | Error, fatal?: boolean) => {
            errorReporter.report(error);
            logEvent(analytics, 'exception', { description: error.toString(), fatal: !!fatal });
          }
        : errorReporter.report,
    }),
    [analytics],
  );
  return reporter;
};

export default useErrorReporter;
