import { collection, limit, query, where } from 'firebase/firestore';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import CenterPageContainer from 'web/components/CenterPageContainer';
import { AnchorStyled, LinkStyled } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import Meta from 'web/components/Meta';
import PageLoadingFallback from 'web/components/PageLoadingFallback';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useFirestoreCollectionData from 'web/hooks/useFirestoreCollectionData';
import useInitialRouteData from 'web/hooks/useInitialRouteData';
import { firestorePageConverter } from 'web/utils/convert';
import AffiliateTracker from './AffiliateTracker';
import BookingPageDisplay from './BookingPageDisplay';
import BookingPageUrlContext from './BookingPageUrlContext';

const BookingPageLoaded = ({ page, onCustomDomain }: { page: introwise.Page; onCustomDomain?: boolean }) => {
  const title = `${page.displayName}`;
  const description = `Book a session with ${title} on Introwise. ${page.headline}`;
  const image = page.metaImage;
  const noIndex = page.noIndex || page.disabled;

  // TODO: we might want to set canonical url here to the root url of the booking page
  // so all the nested urls (calendar, booking urls) are treated the same
  // We also might set some of them as no-index (or add exclusion to robots.txt)

  return (
    <>
      <Meta title={title} description={description} image={image} noIndex={noIndex} />
      <AffiliateTracker page={page} onCustomDomain={onCustomDomain} />
      <BookingPageUrlContext.Provider value={onCustomDomain ? '/' : `/${page.slug}/`}>
        <BookingPageDisplay page={page} onCustomDomain={onCustomDomain} />
      </BookingPageUrlContext.Provider>
    </>
  );
};

export const BookingPageWithData = ({
  page,
  loading,
  error,
  onCustomDomain,
}: {
  page: introwise.Page;
  loading: boolean;
  error: Error;
  onCustomDomain?: boolean;
}) => {
  if (error) {
    return (
      <>
        <Helmet title={`Error`} />
        <CenterPageContainer>
          <div style={{ textAlign: 'center' }}>
            <h1>Error</h1>
            <p>Something went wrong. Please try again.</p>
          </div>
        </CenterPageContainer>
      </>
    );
  }

  if (loading) {
    return <PageLoadingFallback />;
  }

  // TODO: customize the not found message for custom domains
  if (!page) {
    return (
      <>
        <Helmet title={`Not Found`} />
        <CenterPageContainer>
          <div style={{ textAlign: 'center' }}>
            <h1>Not Found</h1>
            <p>This page doesn{"'"}t exist.</p>
            {onCustomDomain ? (
              <p style={{ marginTop: 64, maxWidth: 500 }}>
                Are you the owner of this page?
                <br />
                Please complete your custom domain setup process in the{' '}
                <AnchorStyled href="https://introwise.com/dashboard/booking">booking page settings</AnchorStyled>.
              </p>
            ) : (
              <p style={{ marginTop: 64 }}>
                But it could!
                <br />
                <LinkStyled to="/get-started">Sign up for free</LinkStyled> and claim this URL.
              </p>
            )}
          </div>
        </CenterPageContainer>
      </>
    );
  }

  if (page.disabled) {
    return (
      <>
        <Helmet title={`Not Available`} />
        <CenterPageContainer>
          <div style={{ textAlign: 'center' }}>
            <h1>Not Available</h1>
            <p>This page is not available.</p>
          </div>
        </CenterPageContainer>
      </>
    );
  }

  return <BookingPageLoaded page={page} onCustomDomain={onCustomDomain} />;
};

const BookingPage = () => {
  const { slug } = useParams();
  const firestore = useFirestore();
  const initialPage = useInitialRouteData<introwise.Page>();
  const [snapshot, loading, error] = useFirestoreCollectionData(
    query(collection(firestore, 'pages'), where('slug', '==', slug), limit(1)).withConverter(firestorePageConverter),
    {
      initialData: initialPage && [initialPage],
    },
  );
  useErrorHandler(error);

  return (
    <BookingPageWithData
      page={snapshot && snapshot.length === 1 ? snapshot[0] : null}
      loading={loading}
      error={error}
    />
  );
};

export default BookingPage;
