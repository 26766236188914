import React from 'react';
import themeClasses from 'web/styles/themeClasses.css';
import { icon, radio } from './radio.css';

const Icon = () => (
  <svg
    className={icon}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <circle cx="12" cy="12" r="10" />
    </g>
  </svg>
);

const Radio = React.forwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
>(({ children, type, ...inputProps }, ref) => (
  <label className={radio}>
    <input style={{ width: 0, height: 0, opacity: 0 }} type="radio" {...inputProps} ref={ref} />
    <Icon />
    <span style={{ paddingLeft: 32 }} className={themeClasses({ whiteSpace: 'nowrap' })}>
      {children}
    </span>
  </label>
));

Radio.displayName = 'Radio';
export default Radio;
