import React from 'react';
import { Button, FormError, LinkStyled } from 'web/components/elements';
import ScreenTracker from 'web/components/ScreenTracker';
import ScrollIntoViewOnMount from 'web/components/ScrollIntoViewOnMount';
import BackButton from 'web/components/BackButton';
import { Title } from './common';

export const BookingError = ({ error }: { error?: string }) => (
  <>
    <ScreenTracker screenName="BookingError" />
    <ScrollIntoViewOnMount />
    <BackButton />
    <Title>Something went wrong</Title>
    <>
      <p>We cannot process this order at this time.</p>
      {error && (
        <p>
          <FormError>{error}</FormError>
        </p>
      )}
      <p>
        If the issue persists, please{' '}
        <LinkStyled to="/contact" target="_blank">
          contact the support
        </LinkStyled>
        .
      </p>
    </>
  </>
);

export const RetryDialog = ({ onRetry, error }: { onRetry: () => void; error?: string }) => (
  <div style={{ maxWidth: 440 }}>
    <h3 style={{ marginTop: 0 }}>Cannot confirm your order</h3>
    <p>We cannot confirm your order at this time. It might still be in process.</p>
    {error && (
      <p>
        <FormError>{error}</FormError>
      </p>
    )}
    <p>
      You can re-check the status by clicking the button below. If the issue persists, please{' '}
      <LinkStyled to="/contact" target="_blank">
        contact the support
      </LinkStyled>
      .
    </p>
    <div>
      <Button variant="primary" size="md" onClick={onRetry}>
        Retry
      </Button>
    </div>
  </div>
);

export const RetryPaymentDialog = ({ onRetry, error }: { onRetry: () => void; error?: string }) => (
  <div style={{ maxWidth: 440 }}>
    <h3 style={{ marginTop: 0 }}>Payment required</h3>
    <p>Your order requires a payment.</p>
    {error && (
      <p>
        <FormError>{error}</FormError>
      </p>
    )}
    <p>
      You can retry the payment by clicking the button below. If you have any questions, please{' '}
      <LinkStyled to="/contact" target="_blank">
        contact the support
      </LinkStyled>
      .
    </p>
    <div>
      <Button variant="primary" size="md" onClick={onRetry}>
        Retry
      </Button>
    </div>
  </div>
);
