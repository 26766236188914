import { useEffect } from 'react';
import useErrorReporter from './useErrorReporter';

const useErrorHandler = (error: Error | string | void) => {
  const errorReporter = useErrorReporter();

  useEffect(() => {
    if (error) {
      errorReporter.report(error);
    }
  }, [error, errorReporter]);
};

export default useErrorHandler;
