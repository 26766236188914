import React, { useContext, useMemo } from 'react';
import { BookedPackageProvider } from 'web/App/BookingPage/packages/BookedPackageContext';
import CustomThemeProvider from 'web/components/CustomThemeProvider';
import { LinkUnstyled } from 'web/components/elements';
import { TimeZoneProvider } from 'web/components/timezone/TimeZoneContext';
import BodyBackground from 'web/styles/BodyBackground';
import makeCustomTheme from 'web/styles/customTheme';
import peachTheme from 'web/styles/peachTheme';
import BookingPageLayout from './BookingPageLayout';
import BookingPageUrlContext from './BookingPageUrlContext';
import { CheckoutProvider } from './CheckoutContext';
import { Cover, CoverImg, Layout, Photo, PhotoImg } from './common';
import MainController from './MainController';

const defaultBackgroundColor = 'rgba(0, 128, 128, 0.5)';

const PageRender = ({ page }: { page: introwise.Page }) => {
  const pageRootUrl = useContext(BookingPageUrlContext);
  return (
    <BookedPackageProvider pageId={page.id}>
      <TimeZoneProvider>
        <Layout>
          <Cover>
            <CoverImg src={page.cover} alt="" />
          </Cover>
          <Photo>
            <LinkUnstyled to={pageRootUrl}>
              <PhotoImg src={page.photo} alt="" />
            </LinkUnstyled>
          </Photo>
          <CheckoutProvider>
            <MainController page={page} />
          </CheckoutProvider>
        </Layout>
      </TimeZoneProvider>
    </BookedPackageProvider>
  );
};

const BookingPageDisplay = ({ page, onCustomDomain }: { page: introwise.Page; onCustomDomain?: boolean }) => {
  const branding = {
    accentColor: page.branding?.accentColor || page.dynamicBranding?.accentColor,
    backgroundColor: page.branding?.backgroundColor || page.dynamicBranding?.backgroundColor || defaultBackgroundColor,
  };

  const theme = useMemo(
    () => (branding.accentColor ? makeCustomTheme({ accentColor: branding?.accentColor }) : peachTheme),
    [branding.accentColor],
  );

  return (
    <>
      <BodyBackground color={branding.backgroundColor} />
      <BookingPageLayout onCustomDomain={onCustomDomain}>
        <CustomThemeProvider theme={theme}>
          <PageRender page={page} />
        </CustomThemeProvider>
      </BookingPageLayout>
    </>
  );
};

const BookingPageDisplayPreview = ({ page }: { page: introwise.Page }) => {
  const theme = useMemo(
    () => (page.branding?.accentColor ? makeCustomTheme({ accentColor: page.branding?.accentColor }) : peachTheme),
    [page.branding?.accentColor],
  );

  return (
    <BookingPageLayout withoutFooter backgroundColor={page.branding?.backgroundColor || defaultBackgroundColor}>
      <CustomThemeProvider theme={theme}>
        <PageRender page={page} />
      </CustomThemeProvider>
    </BookingPageLayout>
  );
};

export { BookingPageDisplayPreview };
export default BookingPageDisplay;
