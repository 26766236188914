import { AnalyticsBrowser } from '@segment/analytics-next';
import React from 'react';
import CommonRoomPlugin from 'web/components/TrackingContext/CommonRoomPlugin';
import settings from 'web/utils/settings';

const SegmentContext = React.createContext<AnalyticsBrowser>(undefined);

type Props = {
  writeKey: string;
  children: React.ReactNode;
};
const SegmentProvider = ({ children }: Props) => {
  const segment = React.useMemo(
    () =>
      settings.segment.writeKey
        ? AnalyticsBrowser.load({ writeKey: settings.segment.writeKey, plugins: [CommonRoomPlugin] })
        : undefined,
    [],
  );
  return <SegmentContext.Provider value={segment}>{children}</SegmentContext.Provider>;
};

export { SegmentProvider };
export default SegmentContext;
