import { isClientSide } from 'web/utils/ssr';

type QueryResult<T> = readonly [T, boolean, Error];

// TODO: Queries cache should not be in the global scope
// or there should be a method to reset it after rendering on the server
const cachedQueries: Map<string, QueryResult<unknown>> = new Map();

const defaultQueryResult: QueryResult<unknown> = [undefined, true, undefined];

const preloadQuery = <T,>(queryKey: string, initialData: T) => {
  const found = cachedQueries.get(queryKey);
  let result: QueryResult<T>;
  if (found) {
    result = found as QueryResult<T>;
  } else {
    result = initialData ? [initialData, false, undefined] : (defaultQueryResult as QueryResult<T>);
  }
  return result;
};

const cacheQuery = <T,>(queryKey: string, result: QueryResult<T>) => {
  cachedQueries.set(queryKey, result);
};

const useQueryCache = <T,>(queryKey: string | null, result: QueryResult<T>, initialData: T) => {
  if (!queryKey) {
    return result;
  }
  const cachedResult = preloadQuery(queryKey, initialData);
  const hasFreshData = !result[1];
  if (hasFreshData && isClientSide) {
    cacheQuery(queryKey, result);
  }
  return hasFreshData ? result : cachedResult;
};

export default useQueryCache;
