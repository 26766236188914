import { useContext } from 'react';
import FirebaseContext from './FirebaseContext';
import { getFirestore } from './getFirestore';

const useFirestore = () => {
  const firebase = useContext(FirebaseContext);
  return getFirestore(firebase);
};

export default useFirestore;
