import React from 'react';
import { BaseHTMLAttributes } from 'react';
import joinClassNames from 'web/utils/joinClassNames';
import { radioButtonGroup } from './radioButton.css';

const RadioButtonGroup = ({
  children,
  className,
  ...props
}: React.DetailedHTMLProps<BaseHTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
  <div role="radiogroup" className={joinClassNames(className, radioButtonGroup)} {...props}>
    {children}
  </div>
);

export default RadioButtonGroup;
