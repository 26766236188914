import React, { useContext, useEffect, useState } from 'react';
import RewardfulContext from 'web/components/TrackingContext/RewardfulContext';
import settings from 'web/utils/settings';

const AffiliateTrackerCustomDomain = () => {
  const rewardful = useContext(RewardfulContext);
  const appUrl = settings.customDomain.appUrl;
  const apiKey = settings.rewardful.apiKey;
  const referral = rewardful?.current()?.referral;
  const [delayExpired, setDelayExpired] = useState(false);

  // We don't know how long it takes for Rewardful to track the referral,
  // so we add an arbitraty delay here before rendering an iframe.
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelayExpired(true);
    }, 5 * 1000);
    return () => clearTimeout(timeoutId);
  }, []);

  return referral && delayExpired ? (
    <iframe
      style={{ position: 'absolute', border: 'none', width: 0, height: 0 }}
      src={`${appUrl}/rewardful-iframe.html?referral=${referral}&apiKey=${apiKey}`}
    />
  ) : (
    <></>
  );
};

const AffiliateTracker = ({ page, onCustomDomain }: { page: introwise.Page; onCustomDomain: boolean }) => {
  const { affiliateCode } = page;

  const rewardful = useContext(RewardfulContext);

  useEffect(() => {
    if (affiliateCode) {
      rewardful?.track('source', affiliateCode);
    }
  }, [affiliateCode, rewardful]);

  return affiliateCode && onCustomDomain ? <AffiliateTrackerCustomDomain /> : <></>;
};

export default AffiliateTracker;
