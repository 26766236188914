import { palette } from './palette';
import { calendar } from './themeConstants';

const accentHoverColor = palette.peach[500];

const color = {
  accent: palette.peach[550],
  accentOutline: palette.peach[550],
  accentBackground: palette.peach[100],

  focus: palette.seaBlue[500],

  muted: palette.gray[600],
  halfMuted: palette.black[800],
  hr: palette.gray[800],
  fake: palette.gray[100],
  error: palette.red[700],
  notification: palette.red[500],

  navLink: palette.peach[700],
  navLinkActive: palette.peach[700],
};

const backgrounds = {
  header: '#9ACF95',
  mobileNavHeader: color.accentBackground,
  content: '#9ACF95',
  footer: 'transparent',
  sessionSlot: color.accentBackground,
};

const buttonBackgrounds = {
  primary: color.accentOutline,
  primaryHover: accentHoverColor,
  primaryActive: palette.peach[600],
  primaryFocus: color.accentOutline,
  primaryDisabled: palette.lemonade[500],
  secondary: '#fff',
  secondaryHover: '#fff',
  secondaryActive: palette.lemonade[200],
  secondaryFocus: '#fff',
  secondaryDisabled: '#fff',
};

const buttonBorders = {
  primary: color.accentOutline,
  primaryHover: accentHoverColor,
  primaryActive: palette.peach[550],
  primaryFocus: color.focus,
  primaryDisabled: 'transparent',
  secondary: color.accentOutline,
  secondaryHover: accentHoverColor,
  secondaryActive: palette.peach[550],
  secondaryFocus: color.focus,
  secondaryDisabled: palette.lemonade[600],
};

const buttonColors = {
  primary: '#fff',
  primaryHover: '#fff',
  primaryActive: '#fff',
  primaryFocus: '#fff',
  primaryDisabled: palette.lemonade[600],
  secondary: color.accentOutline,
  secondaryHover: accentHoverColor,
  secondaryActive: palette.peach[550],
  secondaryFocus: color.accentOutline,
  secondaryDisabled: palette.lemonade[600],
};

const button = {
  boxShadow: `-1px 9px 19px -11px ${color.accent}`,
  color: buttonColors,
  background: buttonBackgrounds,
  borderColor: buttonBorders,
};

const form = {
  color: {
    borderFocus: palette.peach[300],
    placeholder: palette.gray[600],
    description: palette.gray[700],

    inputBorderError: palette.red[400],

    inputLabel: palette.gray[700],
    inputLabelHover: palette.gray[900],

    inputBackground: palette.gray[100],
    inputBackgroundHover: palette.gray[200],
    inputBackgroundFocus: palette.lemonade[200],
    inputPlaceholderDisabled: palette.gray[500],
  },
};

const peachCalendar = {
  ...calendar,
  primaryBackground: backgrounds.sessionSlot,
  sessionSlotBackground: color.accent,
  recurringSlotBackground: color.accent,
  onetimeSlotBackground: palette.peach[300],
};

const peachTheme = {
  button,
  form,
  color,
  backgrounds,
  calendar: peachCalendar,
};

export default peachTheme;
