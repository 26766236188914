import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import joinClassNames from 'web/utils/joinClassNames';
import button, { ButtonVariants, OldButtonVariants } from './button.css';
import linkReset from './linkReset.css';

const LinkButton = ({
  variant,
  size,
  primary,
  secondary,
  xs,
  sm,
  md,
  lg,
  className,
  children,
  ...props
}: LinkProps & ButtonVariants & OldButtonVariants) => (
  <Link
    className={joinClassNames(
      className,
      linkReset,
      button({
        variant: variant || (primary ? 'primary' : secondary ? 'secondary' : undefined),
        size: size || (xs ? 'xs' : sm ? 'sm' : md ? 'md' : lg ? 'lg' : undefined),
      }),
    )}
    {...props}
  >
    {children}
  </Link>
);

export default LinkButton;
