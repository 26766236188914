import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Meta from 'web/components/Meta';
import ScreenTracker from 'web/components/ScreenTracker';
import ScrollIntoViewOnMount from 'web/components/ScrollIntoViewOnMount';
import GroupSessionsSlots from '../GroupSessionsSlots';
import BackButton from 'web/components/BackButton';
import { DescriptionText, formatDurationMins, formatPriceReadable, Slot, Title } from '../common';
import TimeZone from 'web/components/timezone/TimeZone';

const SeriesInfoImpl = ({
  page,
  series,
  onGroupSelect,
}: {
  page: introwise.Page;
  series: introwise.Series;
  onGroupSelect: (slot: Slot) => void;
}) => {
  useEffect(() => {
    if (series.hidden) {
      try {
        window.sessionStorage.setItem('introwise-hidden-series', `${page.id}/${series.id}`);
      } catch (err) {
        // do nothing
      }
    }
  }, [page.id, series.id, series.hidden]);

  return (
    <>
      <div style={{ minHeight: 350 }}>
        <Title style={{ marginBottom: 0 }}>{series.title}</Title>
        <p>
          <b>{formatPriceReadable(series.price, page.currency)}</b>
          {series.price ? ' per person,' : ','} {formatDurationMins(series.duration)}
        </p>
        <DescriptionText text={series.description} />
        <GroupSessionsSlots
          pageId={page.id}
          expertId={page.ownerId}
          seriesId={series.id}
          onSelect={onGroupSelect}
          limit={40}
          hideTitle
          freeSlotsInline
        />
      </div>
      <TimeZone />
    </>
  );
};

const SeriesInfo = ({ page, onSelect }: { page: introwise.Page; onSelect: (slot: Slot) => void }) => {
  const { seriesId } = useParams();
  const series = page.series[seriesId];

  return (
    <>
      <BackButton initialText="To booking page" />
      {!series && (
        <>
          <Meta title="Not found" />
          <ScreenTracker screenName="SeriesInfo" />
          <ScrollIntoViewOnMount />
          <div style={{ minHeight: 350 }}>
            <Title style={{ marginBottom: 0 }}>Not found</Title>
          </div>
        </>
      )}
      {series && (
        <>
          <Meta title={series.title} description={series.description} noIndex={series.hidden} />
          <ScreenTracker screenName="SeriesInfo" />
          <ScrollIntoViewOnMount />
          <SeriesInfoImpl page={page} series={series} onGroupSelect={onSelect} />
        </>
      )}
    </>
  );
};

export default SeriesInfo;
