import React, { ButtonHTMLAttributes } from 'react';
import joinClassNames from 'web/utils/joinClassNames';
import inlineButton from './inlineButton.css';

const InlineButton = ({
  className,
  children,
  ...props
}: React.DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => (
  <button className={joinClassNames(className, inlineButton)} {...props}>
    {children}
  </button>
);

InlineButton.defaultProps = {
  type: 'button',
};

export default InlineButton;
