import React from 'react';
import { FormProvider, RegisterOptions, useForm, useFormContext } from 'react-hook-form';
import { Button, Checkbox, FormError, FormGroup, Input, Label, LabelText, TextArea } from 'web/components/elements';
import Fake from 'web/components/elements/Fake';
import FormDescription from 'web/components/elements/FormDescription';
import PhoneNumberField from 'web/components/form-fields/PhoneNumberField';
import Spinner from 'web/components/Spinner';
import { emailValidationRules, nameValidationRules } from 'web/utils/formValidation';
import DiscountCodeForm from './DiscountCodeForm';
import PayPalForm from './PayPalForm';
import StripeForm from './StripeForm';

const maxMessageLength = 800;

const messageValidationRules: RegisterOptions = {
  setValueAs: (v) => v.trim(),
  maxLength: {
    value: maxMessageLength,
    message: `Too long, please limit the message to ${maxMessageLength} characters`,
  },
};

export type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
  phoneNumber?: string;
  card?: {
    complete: boolean;
  };
  paypal?: {
    complete: boolean;
  };
  code?: string;
  withoutPaymentConfirmed?: boolean;
};
type SubmitValues = FormValues;

const ClientForm = ({ withPhone }: { withPhone?: boolean }) => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext<FormValues>();

  return (
    <>
      <FormGroup>
        <Label>
          <LabelText>First name</LabelText>
          <Input
            {...register('firstName', nameValidationRules)}
            hasError={!!errors.firstName}
            autoComplete="given-name"
          />
        </Label>
        {errors.firstName && <FormError>{errors.firstName.message}</FormError>}
      </FormGroup>
      <FormGroup>
        <Label>
          <LabelText>Last name</LabelText>
          <Input
            {...register('lastName', nameValidationRules)}
            hasError={!!errors.lastName}
            autoComplete="family-name"
          />
        </Label>
        {errors.lastName && <FormError>{errors.lastName.message}</FormError>}
      </FormGroup>
      <FormGroup>
        <Label>
          <LabelText>Email</LabelText>
          <Input {...register('email', emailValidationRules)} hasError={!!errors.email} autoComplete="email" />
        </Label>
        {errors.email && <FormError>{errors.email.message}</FormError>}
      </FormGroup>
      {withPhone && <PhoneNumberField control={control} name="phoneNumber" />}
    </>
  );
};

const MessageForm = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<FormValues>();

  return (
    <>
      <FormGroup>
        <Label>
          <LabelText>Message</LabelText>
          <TextArea {...register('message', messageValidationRules)} rows={4} hasError={!!errors.message} />
        </Label>
        {errors.message && <FormError>{errors.message.message}</FormError>}
        <FormDescription>Anything you want to share before the session</FormDescription>
      </FormGroup>
    </>
  );
};

const OrderFormFake = () => (
  <>
    {[1, 2, 3].map((idx) => (
      <FormGroup key={idx}>
        <Fake height={16} animated>
          Loading...
        </Fake>
      </FormGroup>
    ))}
    <FormGroup>
      <Fake height={32} animated>
        Loading...
      </Fake>
    </FormGroup>
  </>
);

const OrderForm = ({
  defaultValues,
  onSubmit,
  submitting,
  bookErrorMessage,
  withoutClient,
  withoutPayment,
  withoutPaymentWithConfirmation,
  withCode,
  withCodeOpen,
  withPhone,
  paymentGateway,
  orderReady,
  displayName,
  paymentPlan,
}: {
  defaultValues?: FormValues;
  submitting: boolean;
  onSubmit: (values: SubmitValues) => void;
  bookErrorMessage?: string;
  withoutClient?: boolean;
  withoutPayment?: boolean;
  withoutPaymentWithConfirmation?: boolean;
  withCode?: boolean;
  withCodeOpen?: boolean;
  withPhone?: boolean;
  paymentGateway?: 'stripe' | 'paypal' | 'none';
  orderReady?: boolean;
  displayName?: string;
  paymentPlan?: introwise.PaymentPlanTemplate;
}) => {
  const defaultValuesInit = {
    ...(!withoutClient && {
      firstName: defaultValues?.firstName || '',
      lastName: defaultValues?.lastName || '',
      email: defaultValues?.email || '',
    }),
    ...(withPhone && {
      phoneNumber: defaultValues?.phoneNumber || '',
    }),
    message: defaultValues?.message || '',
    ...(!withoutPayment && {
      card: {
        complete: false,
      },
    }),
    ...(withCode && {
      code: defaultValues?.code || '',
    }),
    ...(withoutPaymentWithConfirmation && {
      withoutPaymentConfirmed: false,
    }),
  };

  const methods = useForm<FormValues>({ defaultValues: defaultValuesInit });
  const withoutPaymentConfirmed = methods.watch('withoutPaymentConfirmed');
  const submitDisabled = withoutPaymentWithConfirmation && !withoutPaymentConfirmed;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <fieldset disabled={submitting}>
          {!withoutClient && <ClientForm withPhone={withPhone} />}
          <MessageForm />
          {withCode && <DiscountCodeForm submitting={submitting} initiallyOpen={withCodeOpen} />}
          <>
            {orderReady ? (
              <>
                {!withoutPayment && paymentGateway === 'stripe' && <StripeForm submitting={submitting} />}
                {!withoutPayment && paymentGateway === 'paypal' && <PayPalForm submitting={submitting} />}
                {withoutPaymentWithConfirmation && (
                  <FormGroup>
                    <Checkbox {...methods.register('withoutPaymentConfirmed', { shouldUnregister: true })}>
                      I understand that this is a paid booking and agree to pay the full amount as requested by the
                      expert.
                    </Checkbox>
                  </FormGroup>
                )}
                {paymentPlan && (
                  <FormGroup>
                    <small>
                      The full amount will be charged automatically to your payment method over {paymentPlan.count}{' '}
                      equal payments. The first payment is due today and the remaining payments will be charged on the
                      same day every month.
                    </small>
                  </FormGroup>
                )}
                <FormGroup>
                  <small>
                    Clicking &quot;Confirm and book&quot; means that you agree to the {displayName} terms and the
                    Introwise{' '}
                    <a href="/terms-of-service" target="_blank" rel="noopener noreferrer">
                      Terms of Service
                    </a>{' '}
                    and{' '}
                    <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                      Privacy Policy
                    </a>
                    {!withoutPayment && (
                      <>
                        {' '}
                        and authorize {displayName} to charge your payment method{' '}
                        {paymentPlan ? ' for future payments' : ''} in accordance with that agreement
                      </>
                    )}
                    .
                  </small>
                </FormGroup>
                <FormGroup>
                  <Button primary type="submit" disabled={submitting || submitDisabled} style={{ width: '100%' }}>
                    {submitting && <Spinner />}
                    <span>Confirm & Book</span>
                  </Button>
                </FormGroup>
              </>
            ) : (
              <>
                <FormGroup>
                  <Fake height={16} animated>
                    Loading...
                  </Fake>
                </FormGroup>
                <FormGroup>
                  <Fake height={8} animated>
                    Loading...
                  </Fake>
                </FormGroup>
                <FormGroup>
                  <Fake height={16} animated>
                    Loading...
                  </Fake>
                </FormGroup>
              </>
            )}
          </>
        </fieldset>
        {bookErrorMessage && (
          <FormGroup>
            <FormError>{bookErrorMessage}</FormError>
          </FormGroup>
        )}
      </form>
    </FormProvider>
  );
};

export { OrderFormFake };
export default OrderForm;
