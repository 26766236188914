import type { EventClickArg } from '@fullcalendar/common';
import React, { useContext } from 'react';
import { DateRange } from 'web/components/calendar/common/types';
import TimeZoneContext from 'web/components/timezone/TimeZoneContext';
import useCronofyAvailablePersonalSlots from 'web/hooks/useCronofyAvailablePersonalSlots';
import { formatCurrencyAmount } from 'web/utils/currency';
import { formatSessionTime } from 'web/utils/dateFormat';
import Calendar from './Calendar';

const BookingCalendar = ({
  expertId,
  pageId,
  serviceId,
  price,
  endDateLimit,
  currency,
  duration,
  onSelect,
  className,
  experimentalTimeSlots,
}: {
  expertId: string;
  pageId: string;
  serviceId: string;
  price: number | ((slot: { start: Date }) => number);
  endDateLimit?: Date;
  currency: introwise.Currency;
  duration: number;
  onSelect: (slot: { start: Date; end: Date; timezone: string }) => void;
  className?: string;
  experimentalTimeSlots?: boolean;
}) => {
  const { timeZone } = useContext(TimeZoneContext);

  const [slots, loading, error, dateRange, updateDateRange] = useCronofyAvailablePersonalSlots({
    expertId,
    pageId,
    serviceId,
    duration,
    endDateLimit,
  });

  const onEventSelect = ({ event }: EventClickArg) => {
    const { start, end } = event;
    onSelect({ start, end, timezone: timeZone });
  };

  // Wrap in setTimeout to avoid nested date range updates
  const onDateRangeChange = (dateRange: DateRange) => setTimeout(() => updateDateRange(dateRange), 0);

  const events = slots?.map(({ start, end }, index) => {
    const priceAdjusted = typeof price === 'function' ? price({ start }) : price;
    return {
      id: index.toString(),
      start,
      end,
      title: experimentalTimeSlots
        ? formatSessionTime(start)
        : priceAdjusted
        ? `Book for ${formatCurrencyAmount(priceAdjusted, currency)}`
        : 'Book for free',
      extendedProps: {
        mobileTitle: experimentalTimeSlots
          ? formatSessionTime(start)
          : priceAdjusted
          ? formatCurrencyAmount(priceAdjusted, currency)
          : 'free',
        recurring: false,
      },
    };
  });

  if (error) {
    return <p>Failed to load available slots</p>;
  }

  return (
    <Calendar
      saving={false}
      saved={true}
      dateRange={dateRange}
      onDateRangeChange={onDateRangeChange}
      events={events}
      onEventSelect={onEventSelect}
      loading={loading}
      className={className}
    />
  );
};

export default BookingCalendar;
