import React, { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import BookingCalendar from 'web/components/calendar/BookingCalendar';
import Meta from 'web/components/Meta';
import ScreenTracker from 'web/components/ScreenTracker';
import ScrollIntoViewOnMount from 'web/components/ScrollIntoViewOnMount';
import TimeZone from 'web/components/timezone/TimeZone';
import BackButton from 'web/components/BackButton';
import { Slot, SlotTimeZoneTimeRange, Title } from './common';
import BookedPackageContext from './packages/BookedPackageContext';

const ServiceCalendar = ({ page, onSelect }: { page: introwise.Page; onSelect: (slot: Slot) => void }) => {
  const { services, currency } = page;
  const { serviceId } = useParams();
  const service = services[serviceId];
  const { title, duration, price } = service || {};
  const { isServiceAvailable } = useContext(BookedPackageContext);

  const onSlotSelect = useCallback(
    (slot: SlotTimeZoneTimeRange) => onSelect({ ...slot, title, price, serviceId, currency }),
    [onSelect, title, price, currency, serviceId],
  );

  if (!service) {
    return (
      <>
        <BackButton initialText="To service" />
        <Meta title="Not found" />
        <ScreenTracker screenName="ServiceInfo" />
        <ScrollIntoViewOnMount />
        <Title style={{ marginBottom: 0 }}>Not found</Title>
      </>
    );
  }

  return (
    <>
      <BackButton initialText="To service" />
      <Meta title={service.title} description={service.description} />
      <ScreenTracker screenName="ServiceInfo" />
      <ScrollIntoViewOnMount />
      <Title>{service.title}</Title>
      <BookingCalendar
        expertId={page.ownerId}
        pageId={page.id}
        serviceId={serviceId}
        price={({ start }) => (isServiceAvailable(serviceId, start) ? 0 : price)}
        currency={currency}
        duration={duration}
        onSelect={onSlotSelect}
        experimentalTimeSlots={page.features?.experimentalTimeSlots}
      />
      <TimeZone />
    </>
  );
};

export default ServiceCalendar;
