import { PortableText, PortableTextReactComponents } from '@portabletext/react';
import { PortableTextBlock } from '@portabletext/types';
import React, { useMemo } from 'react';
import { defaultSerializers } from 'web/components/portable-text/default-serializers';
import CloudAppSerializer from './CloudAppSerializer';
import ImageSerializer from './ImageSerializer';
import { LinksContext } from './LinkSerializer';
import SendsparkSerializer from './SendsparkSerializer';
import VimeoSerializer from './VimeoSerializer';
import YouTubeSerializer from './YouTubeSerializer';

const blogSerializers: Partial<PortableTextReactComponents> = {
  types: {
    image: ImageSerializer,
    youtube: YouTubeSerializer,
    cloudapp: CloudAppSerializer,
    vimeo: VimeoSerializer,
    sendspark: SendsparkSerializer,
  },
  marks: {
    ...defaultSerializers.marks,
  },
};

const RichTextContent = ({ blocks, userGenerated }: { blocks: PortableTextBlock[]; userGenerated?: boolean }) => {
  const linksContextValue = useMemo(() => ({ userGenerated }), [userGenerated]);
  return (
    <LinksContext.Provider value={linksContextValue}>
      <PortableText value={blocks} components={blogSerializers} />
    </LinksContext.Provider>
  );
};

export default RichTextContent;
