// This is a workaround to remove any existing service workers.
// Normally there should not be any, but browsers that visited
// 'old' introwise may have the previous version installed
// which may lead to some unwanted caching issues

if ('serviceWorker' in navigator) {
  void navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (const registration of registrations) {
      void registration.unregister();
    }
  });
}
