import { assign, createMachine } from 'xstate';

type OrderContext = {
  initialOrderId: string | null;
  order: introwise.Order | null;
  payAction: (order: introwise.Order) => Promise<void> | null;
  error: string | null;
  paymentStatus: 'unpaid' | 'payment-required' | 'paid';
  discountError: string | null;
};

const initialContext: OrderContext = {
  initialOrderId: null,
  order: null,
  payAction: null,
  error: null,
  paymentStatus: 'unpaid',
  discountError: null,
};

const orderAssign = assign((_, event: { order: introwise.Order }) => ({
  order: event.order,
}));

const errorAssign = assign((_, event: { error: string }) => ({
  error: event.error,
}));

const orderStateMachine = createMachine<OrderContext>({
  id: 'order',
  initial: 'init',
  context: initialContext,
  on: {
    SET_PAYACTION: {
      actions: assign<OrderContext, { type: 'SET_PAYACTION'; payAction: OrderContext['payAction'] }>(
        (context, event) => ({
          payAction: event.payAction,
        }),
      ),
    },
  },
  states: {
    init: {
      always: [
        {
          target: 'loading',
          cond: (context) => context.initialOrderId !== null,
        },
        {
          target: 'preparing',
        },
      ],
    },
    preparing: {
      entry: ['prepare'],
      on: {
        PREPARE_SUCCESS: {
          target: 'draft',
          actions: orderAssign,
        },
        PREPARE_FAILED: { target: 'error', actions: errorAssign },
      },
    },
    loading: {
      id: 'loading',
      initial: 'loading',
      states: {
        loading: {
          entry: ['load'],
          on: {
            LOAD_SUCCESS: { target: 'loaded', actions: orderAssign },
            LOAD_FAILED: { target: 'loadRetry', actions: errorAssign },
            ERROR: { target: '#order.error', actions: errorAssign },
          },
        },
        loaded: {
          always: [
            {
              target: '#order.completed',
              cond: (context: OrderContext) => context.order?.status === 'completed',
            },
            {
              target: 'loadRetry',
              cond: (context: OrderContext) => context.order?.status === 'submitted',
            },
            {
              target: '#order.draft',
              cond: (context: OrderContext) => context.order?.status === 'draft',
            },
            {
              target: '#order.error',
              actions: assign({ error: 'Order is in invalid state' }),
            },
          ],
        },
        // loadedUnpaid: {
        //   on: {
        //     RETRY: { target: '#order.draft' },
        //   },
        // },
        loadRetry: {
          on: {
            RETRY: { target: 'loading', actions: assign(() => ({ error: null })) },
          },
        },
      },
    },
    draft: {
      initial: 'idle',
      states: {
        idle: {
          on: {
            SUBMIT: {
              target: '#order.submitting',
              actions: assign<OrderContext>({ error: null, paymentStatus: 'unpaid' }),
            },
            APPLY_DISCOUNT: {
              target: 'applyingDiscount',
              actions: assign<OrderContext>({ discountError: null }),
            },
          },
        },
        applyingDiscount: {
          entry: ['applyDiscount'],
          on: {
            APPLY_DISCOUNT_SUCCESS: {
              target: 'idle',
              actions: [assign<OrderContext>({ discountError: null }), orderAssign],
            },
            APPLY_DISCOUNT_FAILED: {
              target: 'idle',
              actions: assign((_, event) => {
                const { error } = event as unknown as { error: string };
                return { discountError: error };
              }),
            },
          },
        },
      },
    },
    submitting: {
      id: 'submitting',
      initial: 'submitting',
      states: {
        submitting: {
          entry: ['submit'],
          on: {
            SUBMIT_SUCCESS: { target: 'submitted', actions: orderAssign },
            SUBMIT_FAILED: { target: '#order.draft', actions: errorAssign },
          },
        },
        submitted: {
          always: [
            {
              target: '#order.completed',
              cond: (context) => context.order?.status === 'completed',
            },
            {
              target: 'paying',
              cond: (context) => context.order?.status === 'submitted',
            },
            {
              target: '#order.error',
              actions: assign({ error: 'Order is in invalid state' }),
            },
          ],
        },
        paying: {
          entry: ['pay'],
          on: {
            PAYMENT_SUCCESS: { target: 'confirming', actions: assign<OrderContext>({ paymentStatus: 'paid' }) },
            PAYMENT_FAILED: { target: 'resetting', actions: errorAssign },
            ERROR: { target: '#order.error', actions: errorAssign },
          },
        },
        resetting: {
          entry: ['reset'],
          on: {
            RESET_SUCCESS: { target: 'reset', actions: orderAssign },
            RESET_FAILED: { target: '#order.error', actions: errorAssign },
          },
        },
        reset: {
          always: [
            {
              target: 'confirmedUnpaid',
              cond: (context: OrderContext) => context.order?.status === 'draft',
            },
            {
              target: '#order.error',
              actions: assign({ error: 'Order is in invalid state' }),
            },
          ],
        },
        confirming: {
          entry: ['confirm'],
          on: {
            CONFIRM_SUCCESS: { target: 'confirmed', actions: orderAssign },
            CONFIRM_FAILED: { target: 'confirmRetry', actions: errorAssign },
          },
        },
        confirmed: {
          always: [
            {
              target: '#order.completed',
              cond: (context: OrderContext) => context.order?.status === 'completed',
            },
            {
              target: 'confirmRetry',
              cond: (context: OrderContext) => context.order?.status === 'submitted',
            },
            {
              target: 'confirmedUnpaid',
              cond: (context: OrderContext) => context.order?.status === 'draft',
            },
            {
              target: '#order.error',
              actions: assign({ error: 'Order is in invalid state' }),
            },
          ],
        },
        confirmedUnpaid: {
          on: {
            RETRY: { target: '#order.draft' },
          },
        },
        confirmRetry: {
          on: {
            RETRY: { target: 'confirming', actions: assign(() => ({ error: null })) },
          },
        },
      },
    },
    completed: {},
    error: {},
  },
});

export default orderStateMachine;
