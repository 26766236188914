import React, { useEffect, useState } from 'react';
import Spinner from 'web/components/Spinner';
import CenterPageContainer from './CenterPageContainer';

const PageLoadingFallback = ({ delay = 600 }: { delay?: number }) => {
  const [showSpinner, setShowSpinner] = useState(delay === 0);
  useEffect(() => {
    if (delay !== 0) {
      const timeout = setTimeout(() => setShowSpinner(true), delay);
      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <CenterPageContainer>{showSpinner && <Spinner />}</CenterPageContainer>;
};

export default PageLoadingFallback;
