import { doc, runTransaction, serverTimestamp } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import BackButton from 'web/components/BackButton';
import {
  Button,
  FormError,
  FormFootnote,
  FormGroup,
  Label,
  LabelText,
  LinkButton,
  TextArea,
} from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import FormValueLength from 'web/components/form-fields/FormValueLength';
import ScreenTracker from 'web/components/ScreenTracker';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import themeClasses from 'web/styles/themeClasses.css';
import { firestorePersonalSessionConverter } from 'web/utils/convert';
import { Title } from '../common';
import ChangeBookingContext from './ChangeBookingContext';

type FormValues = {
  message: string;
};

const maxMessageLength = 800;

const CancellationForm = ({
  onSubmit,
  submitting,
}: {
  onSubmit: (values: FormValues) => void;
  submitting: boolean;
}) => {
  const { register, control, handleSubmit } = useForm<FormValues>({ defaultValues: { message: '' } });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset disabled={submitting}>
        <FormGroup>You can include a message with your cancellation.</FormGroup>
        <FormGroup>
          <Label>
            <LabelText>Message (optional)</LabelText>
            <TextArea
              name="message"
              rows={4}
              {...register('message', {
                setValueAs: (v) => v.trim(),
                maxLength: {
                  value: maxMessageLength,
                  message: `Too long, please limit the message to ${maxMessageLength} characters`,
                },
              })}
            />
          </Label>
          <FormFootnote>
            <FormValueLength control={control} name="message" maxLength={maxMessageLength} />
          </FormFootnote>
        </FormGroup>
        <FormGroup>
          <Button type="submit" variant="primary" className={themeClasses({ width: '100%' })}>
            Confirm cancellation
          </Button>
        </FormGroup>
      </fieldset>
    </form>
  );
};

const ChangeBookingCancel = ({ continueTo }: { continueTo: string }) => {
  const { session, booking, retrieve } = useContext(ChangeBookingContext);
  const firestore = useFirestore();

  const [error, setError] = useErrorStateHandler();

  const [isCancelled, setIsCancelled] = useState(!!session.cancelledAt);
  const [submitting, setSubmitting] = useState(false);

  const cancel = async (msg: string) => {
    setSubmitting(true);

    try {
      const sessionRef = doc(firestore, 'sessions', session.id).withConverter(firestorePersonalSessionConverter);
      await runTransaction(firestore, async (t) => {
        const sessionDoc = await t.get(sessionRef);
        if (!sessionDoc.exists) {
          throw new Error(`Session doesn't exist`);
        }

        if (sessionDoc.data().status === 'cancelled') {
          throw new Error(`Session is already cancelled`);
        }
        return t.update(sessionRef, {
          status: 'cancelled',
          cancelledAt: serverTimestamp(),
          cancelledBy: booking.id,
          cancellationMessage: msg,
        });
      });

      setIsCancelled(true);
      await retrieve();
    } catch (err) {
      setError(err);
    }

    setSubmitting(false);
  };

  return (
    <>
      <ScreenTracker screenName="ChangeBookingCancel" />
      <BackButton initialTo=".." />
      <Title>Cancel your booking</Title>
      {isCancelled ? (
        <>
          <p>Session has been cancelled.</p>
          <p>We&apos;ve notified the expert and have sent you a cancellation confirmation.</p>
          <p>
            <LinkButton to={continueTo} size="md">
              Book another session
            </LinkButton>
          </p>
        </>
      ) : (
        <>
          <CancellationForm
            onSubmit={({ message }: FormValues) => {
              cancel(message);
            }}
            submitting={submitting}
          />
          {error && <FormError>Something went wrong. Please try again later.</FormError>}
        </>
      )}
    </>
  );
};

export default ChangeBookingCancel;
