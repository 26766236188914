import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe, StripeElementsOptions } from '@stripe/stripe-js';
import React, { useContext } from 'react';
import peachTheme from 'web/styles/peachTheme';
import themeConstants from 'web/styles/themeConstants';
import settings from 'web/utils/settings';
import ThemeContext from './ThemeContext';

const stripePromises: { [accountId: string]: Promise<Stripe> } = {};

const getStripePromise = (accountId?: string) => {
  if (!stripePromises[accountId]) {
    stripePromises[accountId] = loadStripe(settings.stripe.apiKey, {
      locale: 'en',
      ...(accountId && { stripeAccount: accountId }),
    });
  }
  return stripePromises[accountId];
};

const useStripeAppearance = () => {
  const [, , contextTheme] = useContext(ThemeContext);

  const theme = contextTheme || peachTheme;

  const appearance: StripeElementsOptions['appearance'] = {
    theme: 'none',
    labels: 'floating',
    variables: {
      fontFamily: themeConstants.typography.regular.fontFamily,
      colorDanger: theme.color.error,
      colorTextSecondary: theme.form.color.inputLabel,
      colorTextPlaceholder: theme.form.color.placeholder,
      colorBackground: theme.form.color.inputBackground,
      spacingGridRow: themeConstants.spaces[4],
      spacingGridColumn: themeConstants.spaces[4],
    },
    rules: {
      '.Label': {
        color: theme.form.color.inputLabel,
      },
      '.Input': {
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingBottom: '13.6px',
      },
      '.Input:focus': {
        outline: 'none',
        boxShadow: `0 0 0 1px ${theme.form.color.borderFocus}`,
        backgroundColor: theme.form.color.inputBackgroundFocus,
      },
      '.Input:hover': {
        backgroundColor: theme.form.color.inputBackgroundHover,
      },
      '.Input--invalid': {
        boxShadow: `0 0 0 2px ${theme.form.color.inputBorderError}`,
      },
      '.Tab--selected': {
        boxShadow: `0 0 0 2px ${theme.color.accentOutline}`,
      },
    },
  };

  return appearance;
};

const StripeProvider = ({
  children,
  accountId,
  clientSecret,
}: {
  children: React.ReactNode;
  accountId?: string;
  clientSecret?: string;
}) => {
  const stripePromise = getStripePromise(accountId);

  const appearance = useStripeAppearance();

  const options: StripeElementsOptions = {
    ...(clientSecret && { clientSecret }),
    appearance,
    // TODO: check if Stripe Payment Element loaders would work better here
    loader: 'never',
  };

  return (
    <Elements stripe={stripePromise} options={options} key={`${accountId}`}>
      {children}
    </Elements>
  );
};
export default StripeProvider;
