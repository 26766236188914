import React from 'react';
import { Control, FieldPath, useWatch } from 'react-hook-form';
import themeVars from 'web/styles/themeVars.css';

const FormValueLength = <TFieldValues,>({
  control,
  name,
  maxLength,
}: {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
  maxLength?: number;
}) => {
  const value = useWatch({ control, name });
  const length = typeof value === 'string' ? value.length : 0;
  const overLimit = maxLength && length > maxLength;
  const style = overLimit
    ? {
        color: themeVars.color.error,
      }
    : undefined;
  return (
    <span style={style}>
      {length}
      {maxLength && <>/{maxLength}</>}
    </span>
  );
};

export default FormValueLength;
