import React from 'react';
import { formatSessionDate, formatSessionTime } from 'web/utils/dateFormat';

const getDaysRemaining = (date: Date) => (date.getTime() - Date.now()) / (1000 * 3600 * 24);

const ExpiryNotice = ({ expiresAt }: { expiresAt: Date }) =>
  expiresAt > new Date() ? (
    <>
      Your package expires in{' '}
      <b>
        {((days) => (days < 1 ? `less than a day` : days < 1.5 ? `one day` : `${Math.round(days)} days`))(
          getDaysRemaining(expiresAt),
        )}
      </b>
      . Sessions from this package must be scheduled for before {formatSessionDate(expiresAt)},{' '}
      {formatSessionTime(expiresAt)}.
    </>
  ) : (
    <>Your package has expired. You can still book extra sessions for a regular price.</>
  );

export default ExpiryNotice;
