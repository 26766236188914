import { httpsCallable } from 'firebase/functions';
import { useEffect, useMemo, useState } from 'react';
import useFunctions from 'web/components/FirebaseContext/useFunctions';
import useErrorReporter from 'web/hooks/useErrorReporter';
import useQueryCache from 'web/hooks/useQueryCache';
import { SlotTimeRange } from './common';

export type AvailableSlotsBatch = SlotTimeRange[][];

const useAvailableSlotsBatch = ({
  expertId,
  pageId,
  services,
}: {
  expertId: string;
  pageId: string;
  services: introwise.Service[];
}) => {
  const now = useMemo(() => new Date(), []);
  const [state, setState] = useState<[AvailableSlotsBatch | null, boolean, Error | null]>([null, true, null]);
  const cachedResult = useQueryCache(
    `cronofyAvailableSlots:${expertId}:${services.map(({ duration, id }) => `${id}+${duration}`).join('-')}`,
    state,
    null,
  );
  const functions = useFunctions();
  const errorReporter = useErrorReporter();
  useEffect(() => {
    let cancelled = false;
    const fetch = async () => {
      try {
        const res = await httpsCallable<
          {
            userId: string;
            pageId: string;
            batch: { serviceId: string }[];
            start: number;
            end: number;
            limit: number;
          },
          { results: { availableSlots: { start: string; end: string }[] }[] }
        >(
          functions,
          'cronofyAvailableSlots',
        )({
          userId: expertId,
          pageId,
          batch: services.map((service) => ({ serviceId: service.id })),
          start: now.getTime(),
          end: now.getTime() + 60 * 24 * 60 * 60 * 1000,
          limit: 3,
        });
        if (!cancelled) {
          const converted = res.data.results.map((batchPart) =>
            batchPart.availableSlots.map((slot) => ({ start: new Date(slot.start), end: new Date(slot.end) })),
          );
          setState([converted, false, null]);
        }
      } catch (err) {
        if (!cancelled) {
          setState([null, false, err]);
        }
        errorReporter.report(err);
      }
    };
    void fetch();
    return () => {
      cancelled = true;
    };
  }, [functions, now, expertId, services, errorReporter, pageId]);
  return cachedResult;
};

export default useAvailableSlotsBatch;
