import type { DocumentReference } from 'firebase/firestore';
import { useDocumentData } from 'web/hooks/firebase';
import useQueryCache from './useQueryCache';

type Options<T> = {
  initialData?: T;
};

const getFirestoreDocumentKey = (ref: DocumentReference | null) => {
  if (!ref) {
    return null;
  }
  return `firestore:doc:${ref.path}`;
};

const useFirestoreDocumentData = <T,>(ref: DocumentReference<T> | null, options?: Options<T>) => {
  const result = useDocumentData<T>(ref);
  const cachedResult = useQueryCache(getFirestoreDocumentKey(ref), result, options?.initialData);

  return cachedResult;
};

export default useFirestoreDocumentData;
