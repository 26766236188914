import { useRef, useEffect, useContext } from 'react';
import CheckoutContext from './CheckoutContext';
import { Product } from './common';

const useCheckoutInit = (product: Product) => {
  const [, setProduct, , setDiscount] = useContext(CheckoutContext);
  const mounted = useRef(true);
  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);
  useEffect(() => {
    setProduct(product || null);
    return () => {
      if (!mounted.current) {
        setProduct(null);
        setDiscount(null);
      }
    };
  }, [product, setDiscount, setProduct]);
};

export default useCheckoutInit;
