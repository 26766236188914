import React from 'react';
import styled from 'styled-components';
import { InlineButton } from 'web/components/elements';
import { DateRange, ViewType } from 'web/components/calendar/common/types';
import themeConstants from 'web/styles/themeConstants';
import Spinner from 'web/components/Spinner';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  position: relative;

  // margins copied from GlobalStyle.tsx for h2
  margin-top: 30px;
  margin-bottom: 8px;
  ${themeConstants.media.sm} {
    flex-direction: row;
    margin-top: 44px;
  }
`;

const ControlContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-top: 10px;
  width: 100%;

  ${themeConstants.media.sm} {
    margin-top: 0;
    width: auto;
    font-size: 12px;
    justify-content: flex-end;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 4px;
`;

const Title = styled.h2<{ isMobile?: boolean }>`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  display: inline;

  ${themeConstants.media.md} {
    font-size: 20px;
    line-height: 1.2;
  }
`;

const ChevronLeft = () => (
  <svg viewBox="0 0 7 10" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.36231 10.0003C5.10031 10.0003 4.83931 9.8983 4.64331 9.6953L0.780314 5.69528C0.402314 5.30228 0.407314 4.67927 0.793314 4.29327L4.79331 0.293251C5.18331 -0.0977505 5.81631 -0.0977505 6.20731 0.293251C6.59731 0.684253 6.59731 1.31626 6.20731 1.70726L2.90231 5.01228L6.08131 8.30529C6.46531 8.70329 6.45431 9.3363 6.05731 9.7193C5.86231 9.9073 5.61231 10.0003 5.36231 10.0003Z"
      fill="currentColor"
    />
  </svg>
);

const ChevronRight = () => (
  <svg viewBox="0 0 7 10" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.6377 3.53365e-05C1.8997 3.53594e-05 2.1607 0.102036 2.3567 0.305037L6.2197 4.30506C6.5977 4.69806 6.5927 5.32106 6.2067 5.70706L2.2067 9.70708C1.8167 10.0981 1.1837 10.0981 0.7927 9.70708C0.4027 9.31608 0.4027 8.68408 0.7927 8.29308L4.0977 4.98806L0.9187 1.69504C0.5347 1.29704 0.545701 0.664039 0.942701 0.281037C1.1377 0.0930359 1.3877 3.53146e-05 1.6377 3.53365e-05"
      fill="currentColor"
    />
  </svg>
);

const SaveMark = styled.div<{ show: boolean }>`
  margin-left: 10px;
  display: ${({ show }) => (show ? 'block' : 'none')};
  font-size: 14px;
`;

const StyledInlineButton = styled(InlineButton)`
  margin-right: 10px;
`;

const PrevNextContainer = styled.div`
  display: flex;
  align-items: flex-end;

  ${themeConstants.media.sm} {
    margin-bottom: unset;
  }

  svg {
    width: 24px;
    height: 8px;
  }
`;

const CalendarControlsContainer = styled.div`
  display: flex;
  align-items: center;

  ${themeConstants.media.sm} {
    align-items: flex-end;
  }
`;

const formatDate = (dateRange?: DateRange) => {
  if (!dateRange) return '';

  const { start, end } = dateRange;
  const startFormat = {
    day: 'numeric' as const,
    month: 'short' as const,
    year: start.getFullYear() !== end.getFullYear() ? ('numeric' as const) : undefined,
  };
  const first = start.toLocaleString('en', startFormat);
  const second = end.toLocaleString('en', { day: 'numeric', month: 'short', year: 'numeric' });
  return `${first} – ${second}`;
};

export type ToolbarProps = {
  dateRange: DateRange;
  onlyWeekView?: boolean;
  onViewChanged: (viewType: ViewType) => void;
  onToday: () => void;
  onPrev: () => void;
  onNext: () => void;
  loading: boolean;
  saving?: boolean;
  saved?: boolean;
};

const Toolbar = ({
  dateRange,
  onViewChanged,
  onToday,
  onPrev,
  onNext,
  onlyWeekView,
  loading,
  saving,
}: ToolbarProps) => {
  const disablePrevButton = !dateRange || dateRange.start < new Date();
  return (
    <Container>
      <TitleContainer>
        <Title>{formatDate(dateRange)}</Title>
        <SaveMark show={saving}>
          <Spinner />
        </SaveMark>
      </TitleContainer>
      <ControlContainer>
        <PrevNextContainer>
          <StyledInlineButton onClick={onPrev} disabled={disablePrevButton || loading || saving}>
            <ChevronLeft />
          </StyledInlineButton>
          <StyledInlineButton onClick={onNext} disabled={loading || saving}>
            <ChevronRight />
          </StyledInlineButton>
        </PrevNextContainer>

        <CalendarControlsContainer>
          <StyledInlineButton onClick={onToday} disabled={saving}>
            Today
          </StyledInlineButton>
          {!onlyWeekView && (
            <>
              <StyledInlineButton onClick={() => onViewChanged('timeGridWeek')}>Week</StyledInlineButton>
              <StyledInlineButton onClick={() => onViewChanged('listWeek')}>List</StyledInlineButton>
            </>
          )}
        </CalendarControlsContainer>
      </ControlContainer>
    </Container>
  );
};

export default Toolbar;
