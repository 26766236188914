import { CSSObject } from 'styled-components';
import { palette } from './palette';

const manropeFont = `'Manrope', Arial, sans-serif`;
// const ceraBoldFont = `'Cera Bold', Arial, sans-serif`;
const montserratBoldFont = `'Montserrat', Arial, sans-serif`;
const nunitoFont = `Nunito, Arial, sans-serif`;

const typography: { [index: string]: CSSObject } = {
  regular: {
    fontFamily: manropeFont,
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '1.62',
    letterSpacing: '0.01em',
  },
  small: {
    fontSize: '0.75em',
  },
  strong: {
    fontWeight: 'bolder',
  },
  hero: {
    fontFamily: montserratBoldFont,
    fontSize: '56px',
    fontWeight: '700',
    lineHeight: '1.1',
    letterSpacing: '-0.02em',
  },
  h1: {
    fontFamily: montserratBoldFont,
    fontSize: '36px',
    fontWeight: '700',
    lineHeight: '1.2',
    letterSpacing: '-0.02em',
  },
  h2: {
    fontFamily: manropeFont,
    fontSize: '26px',
    lineHeight: '1.52',
    fontWeight: '400',
    letterSpacing: '0.01em',
  },
  h3: {
    fontFamily: montserratBoldFont,
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '1.62',
    letterSpacing: '0.01em',
  },
  h4: {
    fontFamily: montserratBoldFont,
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '1.62',
    letterSpacing: '0.01em',
  },
  logo: {
    fontFamily: nunitoFont,
    fontWeight: '600',
  },
  button: {
    fontFamily: manropeFont,
    fontWeight: 700,
    letterSpacing: '0.01em',
  },
};

const borders = {
  light: palette.gray[200],
  regular: palette.gray[300],
  heavy: palette.gray[800],
};

const spaces = {
  px: '1px',
  0: '0px',
  0.5: '0.125rem',
  1: '0.25rem',
  1.5: '0.375rem',
  2: '0.5rem',
  2.5: '0.625rem',
  3: '0.75rem',
  3.5: '0.875rem',
  4: '1rem',
  5: '1.25rem',
  6: '1.5rem',
  7: '1.75rem',
  8: '2rem',
  9: '2.25rem',
  10: '2.5rem',
  11: '2.75rem',
  12: '3rem',
  14: '3.5rem',
  16: '4rem',
  20: '5rem',
  24: '6rem',
  28: '7rem',
  32: '8rem',
  36: '9rem',
  40: '10rem',
  44: '11rem',
  48: '12rem',
  52: '13rem',
  56: '14rem',
  60: '15rem',
  64: '16rem',
  72: '18rem',
  80: '20rem',
  96: '24rem',
};

const borderRadius = {
  sm: '0.25rem',
  md: '0.5rem',
  lg: '0.75rem',
  default: '0.5rem',
  full: '50%',
};

const fakeDefault = {
  color: 'transparent',
  background: palette.gray[100],
  borderRadius: borderRadius.sm,
};

const fake = {
  default: fakeDefault,
  loading: fakeDefault,
  error: {
    color: 'transparent',
    backgroundColor: '#fff',
    borderRadius: borderRadius.default,
    backgroundImage: `url(
      "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23e2d202' fill-opacity='1'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"
    )`,
    border: `2px dashed #e2d202`,
  },
  notFound: {
    color: 'transparent',
    backgroundColor: palette.gray[100],
    borderRadius: borderRadius.default,
    backgroundImage: `url(
      "data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23000000' fill-opacity='0.1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E"
    )`,
    border: `1px dashed ${borders.heavy}`,
  },
  animated: {
    position: 'relative',
    overflow: 'hidden',
    '::after': {
      position: 'absolute',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      transform: 'translateX(-100%)',
      backgroundImage: `linear-gradient(
        90deg,
        rgba(220, 220, 220, 0) 0,
        rgba(220, 220, 220, 0.2) 20%,
        rgba(220, 220, 220, 0.5) 80%,
        rgba(220, 220, 220, 0)
      )`,
      animation: 'shimmer 2s infinite',
      content: "''",
    },
  },
};

const nonTextLink = `
  text-decoration: none;
  color: inherit;
  &:hover,
  &:focus {
    text-decoration: none;
    color: initial;
  }
`;

const mixins = {
  nonTextLink,
};

const boxShadow = {
  sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
  md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
};

const breakpoints = {
  sm: 'screen and (min-width: 640px)',
  md: 'screen and (min-width: 768px)',
  lg: 'screen and (min-width: 1024px)',
  xl: 'screen and (min-width: 1280px)',
};

const media = {
  sm: `@media ${breakpoints.sm}`,
  md: `@media ${breakpoints.md}`,
  lg: `@media ${breakpoints.lg}`,
  xl: `@media ${breakpoints.xl}`,
};

const call = {
  callBackgroundColor: palette.gray[900],
  overlayBackgroundColor: palette.gray[800],
  overlayColor: '#fff',
  toastBackgroundColor: palette.gray[900],
  toastColor: '#fff',
  mutedIconColor: palette.red[500],
};

const calendar = {
  todayHeader: palette.red[600],
  todayBackground: palette.yellow[100] + '80', // opacity: 0.5
  primaryBackground: palette.gray[50],
  sessionSlotBackground: palette.gray[100],
  sessionSlotColor: palette.black[100],
  recurringSlotBackground: palette.gray[300],
  onetimeSlotBackground: palette.gray[100],
  blockedSlotBackground: palette.red[300],
};

const transition = {
  button: 'all 0.2s ease',
};

const themeConstants = {
  manropeFont,
  nunitoFont,
  montserratBoldFont,
  typography,
  borders,
  borderRadius,
  fake,
  mixins,
  boxShadow,
  palette,
  media,
  call,
  calendar,
  transition,
  spaces,
  breakpoints,
};

export {
  manropeFont,
  nunitoFont,
  montserratBoldFont,
  typography,
  borders,
  borderRadius,
  fake,
  mixins,
  boxShadow,
  palette,
  media,
  call,
  calendar,
  transition,
  spaces,
  breakpoints,
};
export default themeConstants;
