import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentIntent } from '@stripe/stripe-js';
import { doc } from 'firebase/firestore';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Title } from 'web/App/BookingPage/common';
import BookedPackageContext from 'web/App/BookingPage/packages/BookedPackageContext';
import BackButton from 'web/components/BackButton';
import {
  Button,
  FormDescription,
  FormError,
  FormGroup,
  InlineButton,
  LinkStyled,
  Table,
} from 'web/components/elements';
import Fake from 'web/components/elements/Fake';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import Flare from 'web/components/Flare';
import PaymentElementInput from 'web/components/form-fields/PaymentElementInput';
import ScreenTracker from 'web/components/ScreenTracker';
import Spinner from 'web/components/Spinner';
import StripeProvider from 'web/components/StripeProvider';
import { useDocumentData } from 'web/hooks/firebase';
import useLoadingState from 'web/hooks/firebase/useLoadingState';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import themeClasses from 'web/styles/themeClasses.css';
import { firestorePaymentPlanConverter } from 'web/utils/convert';
import { formatCurrencyAmount } from 'web/utils/currency';
import { formatSessionDateLong, formatSessionTime } from 'web/utils/dateFormat';
import BookingPageUrlContext from '../BookingPageUrlContext';

// const paymentMethodToString = (paymentMethod: string | PaymentMethod) => {
//   if (typeof paymentMethod === 'string') {
//     return 'Unknown';
//   }
//   if (paymentMethod.type === 'card') {
//     return `${paymentMethod.card.brand} ${paymentMethod.card.last4}`;
//   }
//   return paymentMethod.type;
// };

const PaymentPlanPaymentStripe = ({
  stripePayment,
  payment,
}: {
  stripePayment: introwise.PaymentStripe;
  payment: introwise.PaymentPlanPayment;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const state = useLoadingState<PaymentIntent, Error>();
  const { setError, setValue, value: paymentIntent, loading, error } = state;

  const [updatePaymentMethod, setUpdatePaymentMethod] = useState(false);
  const [paymentElementComplete, setPaymentElementComplete] = useState(false);
  const [paymentElementError, setPaymentElementError] = useState<Error | null>(null);

  useEffect(() => {
    (async () => {
      if (!stripe) {
        return;
      }
      try {
        const res = await stripe.retrievePaymentIntent(stripePayment.stripeClientSecret);
        if (res.error) throw new Error(res.error.message);
        setValue(res.paymentIntent);
        setUpdatePaymentMethod(res.paymentIntent.payment_method === null);
      } catch (e) {
        setError(new Error(`Failed to load the payment: ${e}`));
      }
    })();
  }, [stripePayment.stripeClientSecret, setValue, setError, stripe]);

  useErrorHandler(error);

  const [confirming, setConfirming] = useState(false);
  const [errorConfirming, setErrorConfirming] = useErrorStateHandler<Error | null>();

  const retryPayment = useCallback(async () => {
    if (!stripe) {
      throw new Error('Stripe not loaded');
    }
    if (!elements) {
      return;
    }
    if (!paymentIntent) {
      return;
    }
    setConfirming(true);
    try {
      let res;
      if (updatePaymentMethod) {
        if (!paymentElementComplete) {
          setPaymentElementError(new Error('Please fill in your payment details'));
          setConfirming(false);
          return;
        }

        const returnUrlStr = window.location.href;
        res = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: returnUrlStr,
          },
          redirect: 'if_required',
        });
      } else {
        res = await stripe.confirmCardPayment(stripePayment.stripeClientSecret, {
          payment_method: stripePayment.stripePaymentMethodId,
        });
      }
      if (res.error) throw new Error(res.error.message);
    } catch (e) {
      setErrorConfirming(e);
      setConfirming(false);
    }
  }, [
    stripe,
    elements,
    paymentIntent,
    updatePaymentMethod,
    paymentElementComplete,
    stripePayment.stripeClientSecret,
    stripePayment.stripePaymentMethodId,
    setErrorConfirming,
  ]);

  return (
    <>
      {loading && <Fake>Loading...</Fake>}
      {error && <p>Error: {error.message}</p>}
      {!loading && paymentIntent && (
        <>
          {!updatePaymentMethod && (
            <>
              <p>
                You can retry your payment of <b>{formatCurrencyAmount(payment.amount, payment.currency)}</b>
                {' for '}
                <b>{formatSessionDateLong(payment.dueAt)}</b> using the saved payment method.
              </p>
              <p>
                If you want to use a different payment method, you can{' '}
                <InlineButton onClick={() => setUpdatePaymentMethod(true)}>update your payment details</InlineButton>.
              </p>
            </>
          )}
          {updatePaymentMethod && (
            <>
              <p>
                Provide the new payment details for your payment of{' '}
                <b>{formatCurrencyAmount(payment.amount, payment.currency)}</b>
                {' for '}
                <b>{formatSessionDateLong(payment.dueAt)}</b>.
              </p>
              <PaymentElementInput
                onChange={(event) => {
                  setPaymentElementComplete(event.complete);
                  if (paymentElementError && event.complete) {
                    setPaymentElementError(null);
                  }
                }}
                disabled={confirming}
                hasErrors={!!paymentElementError}
              />
              {paymentElementError && (
                <FormGroup>
                  <FormError>{paymentElementError.message}</FormError>
                </FormGroup>
              )}
              <FormDescription className={themeClasses({ marginTop: 1 })}>
                <FontAwesomeIcon icon={faLock} fixedWidth /> We handle your card details securely using{' '}
                <a
                  href="https://stripe.com/customers"
                  style={{ color: 'inherit' }}
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                >
                  Stripe
                </a>
                , a global online payment processing trusted by millions of businesses.
              </FormDescription>
              <FormGroup>
                <small>
                  Clicking &quot;Confirm and retry&quot; means that you authorize to charge and save your payment method
                  for future payments in this payment plan.
                </small>
              </FormGroup>
            </>
          )}
          <FormGroup>
            <Button onClick={retryPayment} disabled={confirming}>
              {confirming && <Spinner />}
              <span>{updatePaymentMethod ? 'Confirm and retry' : 'Retry with the saved payment'}</span>
            </Button>
          </FormGroup>
          {errorConfirming && (
            <FormGroup>
              <FormError>Failed to retry the payment: {errorConfirming.message}</FormError>
            </FormGroup>
          )}
        </>
      )}
    </>
  );
};

const PaymentPlanPaymentRetry = ({ payment }: { payment: introwise.PaymentPlanPayment }) => {
  return (
    <>
      <h3>Retry payment</h3>
      {payment.status === 'paid' && <p>Your payment has been processed successfully.</p>}
      {payment.status !== 'paid' && payment.processing?.gateway === 'stripe' && (
        <StripeProvider
          accountId={payment.processing.stripeAccountId}
          clientSecret={payment.processing.stripeClientSecret}
        >
          <PaymentPlanPaymentStripe payment={payment} stripePayment={payment.processing} />
        </StripeProvider>
      )}
    </>
  );
};

const PaymentPlanPaymentContainer = ({
  paymentPlan,
  paymentId,
}: {
  paymentPlan: introwise.PaymentPlan;
  paymentId: string;
}) => {
  const payment = paymentPlan.payments[paymentId];
  if (!payment) {
    return <></>;
  }
  return <PaymentPlanPaymentRetry payment={payment} />;
};

const PaymentPlanTable = ({ paymentPlan }: { paymentPlan: introwise.PaymentPlan }) => {
  const paymentsSorted = useMemo(
    () =>
      paymentPlan?.payments
        ? Object.entries(paymentPlan.payments)
            .map(([, e]) => e)
            .sort((a, b) => a.dueAt.getTime() - b.dueAt.getTime())
        : [],
    [paymentPlan],
  );

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Due</th>
            <th>Amount</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {paymentsSorted.map((payment) => (
            <tr key={payment.id}>
              <td>{formatSessionDateLong(payment.dueAt)}</td>
              <td>{formatCurrencyAmount(payment.amount, payment.currency)}</td>
              <td>
                {payment.status === 'paid' && <Flare variant="success">Paid</Flare>}
                {payment.status === 'pending' && <Flare variant="disabled">Pending</Flare>}
                {payment.status === 'failed' && <Flare variant="error">Failed</Flare>}
              </td>
              <td>
                {payment.status === 'paid' && (
                  <>
                    Paid on {formatSessionDateLong(payment.processedAt)} at {formatSessionTime(payment.processedAt)}
                  </>
                )}
                {payment.status === 'pending' && <>Payment is pending</>}
                {payment.status === 'failed' && (
                  <>
                    Payment attempt failed on {formatSessionDateLong(payment.processedAt)} at{' '}
                    {formatSessionTime(payment.processedAt)}
                    <br />
                    <LinkStyled to={{ search: `?paymentId=${payment.id}` }} replace>
                      Fix the payment
                    </LinkStyled>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

const PaymentPlanView = ({ paymentPlan }: { paymentPlan: introwise.PaymentPlan }) => {
  const [params] = useSearchParams();
  const paymentId = params.get('paymentId');
  return (
    <>
      <p>
        Payment plan: <b>{paymentPlan.status}</b>
      </p>
      <p>
        Total amount: <b>{formatCurrencyAmount(paymentPlan.amountTotal, paymentPlan.currency)}</b>
      </p>
      <p>
        Amount paid: <b>{formatCurrencyAmount(paymentPlan.amountPaid, paymentPlan.currency)}</b>
      </p>
      <PaymentPlanTable paymentPlan={paymentPlan} />
      {paymentId && <PaymentPlanPaymentContainer paymentPlan={paymentPlan} paymentId={paymentId} />}
    </>
  );
};

const ManagePaymentPlan = ({ pageId, paymentPlanScheduledId }: { pageId: string; paymentPlanScheduledId: string }) => {
  const firestore = useFirestore();
  const [paymentPlan, loading, error] = useDocumentData(
    doc(firestore, 'pages', pageId, 'paymentPlans', paymentPlanScheduledId).withConverter(
      firestorePaymentPlanConverter,
    ),
  );
  useErrorHandler(error);

  return (
    <>
      {loading && <Fake>Loading...</Fake>}
      {error && <p>Something went wrong. Please try again later.</p>}
      {!loading && !error && !paymentPlan && <p>Payment plan not found.</p>}
      {!loading && !error && paymentPlan && <PaymentPlanView paymentPlan={paymentPlan} />}
    </>
  );
};

const ManagePackagePayments = ({ page }: { page: introwise.Page }) => {
  const { bookedPackage, loading } = useContext(BookedPackageContext);
  const pageRootUrl = useContext(BookingPageUrlContext);
  const navigate = useNavigate();
  const location = useLocation();

  if (loading) {
    return <>Loading package...</>;
  }

  if (!bookedPackage) return <Navigate to={pageRootUrl} />;

  return (
    <>
      <ScreenTracker screenName="ManagePackagePayments" />
      <BackButton
        onClick={() => {
          // This is a workaround for the initial navigation, when after consuming the package id
          // the back button points to 'use' route again
          if ((location.state as { afterUse?: boolean } | undefined)?.afterUse) {
            navigate('..', { replace: true });
          } else {
            navigate(-1);
          }
        }}
      />
      <Title>{bookedPackage.package.title}</Title>
      {bookedPackage.paymentPlanScheduledId ? (
        <ManagePaymentPlan pageId={page.id} paymentPlanScheduledId={bookedPackage.paymentPlanScheduledId} />
      ) : (
        <p>This package doesn&apos;t have a payment plan scheduled.</p>
      )}
    </>
  );
};

export default ManagePackagePayments;
