import React from 'react';
import { checkbox, icon } from './checkbox.css';

const Icon = () => (
  <svg
    className={icon}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="M16.2954 9.606L11.7274 15.606C11.5394 15.853 11.2484 15.999 10.9384 16.001H10.9314C10.6244 16.001 10.3344 15.859 10.1444 15.617L7.7124 12.51C7.3724 12.076 7.4484 11.447 7.8834 11.107C8.3184 10.766 8.9464 10.842 9.2874 11.278L10.9204 13.364L14.7044 8.395C15.0384 7.956 15.6664 7.87 16.1064 8.205C16.5454 8.54 16.6304 9.167 16.2954 9.606ZM18.0004 3.00098H6.0004C4.3454 3.00098 3.0004 4.34598 3.0004 6.00098V18.001C3.0004 19.655 4.3454 21.001 6.0004 21.001H18.0004C19.6544 21.001 21.0004 19.655 21.0004 18.001V6.00098C21.0004 4.34598 19.6544 3.00098 18.0004 3.00098Z" />
    </g>
  </svg>
);

const Checkbox = React.forwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
>(({ children, type, ...inputProps }, ref) => (
  <label className={checkbox}>
    <input style={{ width: 0, height: 0, opacity: 0 }} type="checkbox" {...inputProps} ref={ref} />
    <Icon />
    <span style={{ paddingLeft: 30 }}>{children}</span>
  </label>
));

Checkbox.displayName = 'Checkbox';
export default Checkbox;
