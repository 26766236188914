import { collection, doc } from 'firebase/firestore';
import React from 'react';
import { Route, Routes, useParams } from 'react-router';
import { Title } from 'web/App/BookingPage/common';
import { makeFake } from 'web/components/elements/Fake';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import { SessionSlotLoading } from 'web/components/SessionSlot';
import useFirestoreDocumentData from 'web/hooks/useFirestoreDocumentData';
import { firestorePackageConverter } from 'web/utils/convert';
import PackageCheckout from './PackageCheckout';
import PackageInfo from './PackageInfo';

const FakeTitle = makeFake(Title, true);

const PackageLoading = () => (
  <>
    <FakeTitle>Loading...</FakeTitle>
    <div style={{ minHeight: 350 }}>
      <SessionSlotLoading />
    </div>
  </>
);
const PackageNotFound = () => <p>The package doesn&apos;t exist. Did you copy the URL correctly?</p>;

const Package = ({
  page,
  onSelect,
  onConfirmationBack,
  onPackageRestore,
}: {
  page: introwise.Page;
  onSelect: (pack: introwise.Package) => void;
  onConfirmationBack: () => void;
  onPackageRestore: () => void;
}) => {
  const { packageId } = useParams();

  const firestore = useFirestore();
  const [pack, loading, error] = useFirestoreDocumentData(
    doc(collection(firestore, 'pages', page.id, 'packages'), packageId).withConverter(firestorePackageConverter),
  );

  return (
    <>
      {error && <p>Failed to load the session.</p>}
      {!error && loading && <PackageLoading />}
      {!error && !loading && !pack && <PackageNotFound />}
      {!error && !loading && pack && (
        <Routes>
          <Route
            index
            element={
              <PackageInfo
                package={pack}
                onSelect={onSelect}
                onPackageRestore={onPackageRestore}
                services={page.services}
                series={page.series}
                pageId={page.id}
              />
            }
          />
          <Route
            path="checkout"
            element={<PackageCheckout package={pack} page={page} onConfirmationBack={onConfirmationBack} />}
          />
        </Routes>
      )}
    </>
  );
};

export default Package;
