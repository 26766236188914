import { getAnalytics } from 'firebase/analytics';
import { useContext } from 'react';
import { isClientSide } from 'web/utils/ssr';
import FirebaseContext from './FirebaseContext';

const useAnalytics = () => {
  const firebase = useContext(FirebaseContext);
  return isClientSide && getAnalytics(firebase);
};

export default useAnalytics;
