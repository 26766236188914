import { DocumentReference, refEqual } from 'firebase/firestore';
import { useEffect, useState } from 'react';

const useMemoRef = <T,>(ref: DocumentReference<T> | null, onChange: () => void) => {
  const [memoRef, setMemoRef] = useState(ref);
  useEffect(() => {
    if ((ref === null && memoRef !== null) || (memoRef && !refEqual(memoRef, ref)) || !memoRef) {
      setMemoRef(ref);
      onChange?.();
    }
  }, [memoRef, onChange, ref]);

  return memoRef;
};

export default useMemoRef;
