import chroma from 'chroma-js';
import { palette } from './palette';
import { calendar } from './themeConstants';

const makeCustomTheme = (branding: { accentColor: string }) => {
  const initialChroma = chroma(branding.accentColor);
  const initialLuminance = initialChroma.luminance();
  const hoverLuminance = initialLuminance < 0.4 ? initialLuminance + 0.1 : initialLuminance - 0.1;
  const activeLuminance = initialLuminance < 0.5 ? initialLuminance + 0.2 : initialLuminance - 0.2;

  const accentBackgroundColor = initialChroma.luminance(0.85).hex();
  const accentHoverColor = initialChroma.luminance(hoverLuminance).hex();
  const accentActiveColor = initialChroma.luminance(activeLuminance).hex();

  const maxDarkLuminance = (initialLuminance * 2.0) / 9 + 1.0 / 90 - 0.05;
  const darkAccentChroma = initialChroma.luminance(Math.max(0.1, Math.min(0.2, maxDarkLuminance)));
  const darkAccentColor = darkAccentChroma.hex();
  const darkAccentHoverColor = darkAccentChroma.luminance(0.25).hex();
  const darkAccentActiveColor = darkAccentChroma.luminance(0.05).hex();
  const whiteContrast = chroma.contrast(branding.accentColor, 'white');
  const darkContrast = chroma.contrast(branding.accentColor, darkAccentChroma);
  const swapColors = whiteContrast < 4.5 && darkContrast > whiteContrast;

  const accentOutlineColor = swapColors ? darkAccentColor : branding.accentColor;

  const buttonOutlineColor = accentOutlineColor;
  const buttonOutlineHoverColor = swapColors ? darkAccentHoverColor : accentHoverColor;
  const buttonOutlineActiveColor = swapColors ? darkAccentActiveColor : accentHoverColor;

  const color = {
    accent: branding.accentColor,
    accentOutline: accentOutlineColor,
    accentBackground: accentBackgroundColor,

    focus: palette.seaBlue[500],
    muted: palette.gray[600],
    halfMuted: palette.black[800],
    hr: palette.gray[800],
    fake: palette.gray[100],
    error: palette.red[700],
    notification: palette.red[500],

    navLink: buttonOutlineColor,
    navLinkActive: buttonOutlineActiveColor,
  };

  const backgrounds = {
    header: '#9ACF95',
    mobileNavHeader: color.accentBackground,
    content: '#9ACF95',
    footer: 'transparent',
    sessionSlot: color.accentBackground,
  };

  const buttonBackgrounds = {
    primary: branding.accentColor,
    primaryHover: accentHoverColor,
    primaryActive: accentActiveColor,
    primaryFocus: branding.accentColor,
    primaryDisabled: accentHoverColor,
    secondary: '#fff',
    secondaryHover: accentHoverColor,
    secondaryActive: accentActiveColor,
    secondaryFocus: '#fff',
    secondaryDisabled: '#fff',
  };

  const buttonBorders = {
    primary: swapColors ? buttonOutlineColor : branding.accentColor,
    primaryHover: buttonOutlineHoverColor,
    primaryActive: buttonOutlineActiveColor,
    primaryFocus: color.focus,
    primaryDisabled: 'transparent',
    secondary: buttonOutlineColor,
    secondaryHover: buttonOutlineHoverColor,
    secondaryActive: buttonOutlineActiveColor,
    secondaryFocus: color.focus,
    secondaryDisabled: buttonOutlineHoverColor,
  };

  const buttonColors = {
    primary: swapColors ? buttonOutlineColor : '#fff',
    primaryHover: swapColors ? buttonOutlineHoverColor : '#fff',
    primaryActive: swapColors ? buttonOutlineActiveColor : '#fff',
    primaryFocus: swapColors ? buttonOutlineColor : '#fff',
    primaryDisabled: color.hr,
    secondary: buttonOutlineColor,
    secondaryHover: buttonOutlineHoverColor,
    secondaryActive: buttonOutlineActiveColor,
    secondaryFocus: buttonOutlineColor,
    secondaryDisabled: buttonOutlineHoverColor,
  };

  const button = {
    boxShadow: `-1px 9px 19px -11px ${color.accent}`,
    color: buttonColors,
    background: buttonBackgrounds,
    borderColor: buttonBorders,
  };

  const form = {
    color: {
      borderFocus: palette.peach[300],
      placeholder: palette.gray[600],
      description: palette.gray[700],

      inputBorderError: palette.red[400],

      inputLabel: palette.gray[700],
      inputLabelHover: palette.gray[900],

      inputBackground: palette.gray[100],
      inputBackgroundHover: palette.gray[200],
      inputBackgroundFocus: palette.lemonade[200],
      inputPlaceholderDisabled: palette.gray[500],
    },
  };

  const peachCalendar = {
    ...calendar,
    primaryBackground: backgrounds.sessionSlot,
    sessionSlotBackground: color.accent,
    recurringSlotBackground: color.accent,
    onetimeSlotBackground: palette.peach[300],
  };

  return {
    button,
    form,
    color,
    backgrounds,
    calendar: peachCalendar,
  };
};

export default makeCustomTheme;
