import { httpsCallable } from 'firebase/functions';
import { useCallback } from 'react';
import useFunctions from 'web/components/FirebaseContext/useFunctions';

const usePartnerCode = () => {
  const functions = useFunctions();

  const verifyPartnerCode = useCallback(
    async (code: string, pageId: string) => {
      const result = await httpsCallable<
        { code: string; pageId: string },
        {
          partnerId?: string;
          partnerCodeId?: string;
          discount?: introwise.DiscountCodeFixed | introwise.DiscountCodePercentage;
          error?: string;
        }
      >(
        functions,
        'partnersVerifyCode',
      )({ code, pageId });
      return result.data;
    },
    [functions],
  );

  return verifyPartnerCode;
};

export default usePartnerCode;
