import React, { useContext } from 'react';
import { isClientSide } from 'web/utils/ssr';

const OriginUrlContext = React.createContext(isClientSide ? window.location.origin : null);
const OriginUrlProvider = OriginUrlContext.Provider;

const useOriginUrl = (pathName?: string) => {
  const originUrl = useContext(OriginUrlContext);
  if (!originUrl) {
    throw new Error(`Origin URL is not available`);
  }
  return `${originUrl}${pathName || ''}`;
};

export { OriginUrlProvider, useOriginUrl };
export default OriginUrlContext;
