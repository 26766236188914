export const palette = {
  green: {
    100: '#E2F8E8',
    200: '#C6F1D1',
    300: '#A9EABA',
    400: '#8DE3A3',
    500: '#70DC8C',
    600: '#5AB070',
    700: '#438454',
    800: '#2D5838',
    900: '#162C1C',
  },
  blue: {
    100: '#ECF7FA',
    200: '#DAF0F6',
    300: '#C7E8F1',
    400: '#B5E1ED',
    500: '#A2D9E8',
    600: '#82AEBA',
    700: '#61828B',
    800: '#41575D',
    900: '#202B2E',
  },
  lemonade: {
    100: '#FFFBF9',
    200: '#FFF7F3',
    300: '#FFF3EC',
    400: '#FFEFE6',
    500: '#FFEBE0',
    600: '#CCBCB3',
    700: '#998D86',
    800: '#665E5A',
    900: '#332F2D',
  },
  mint: {
    100: '#E1F3F3',
    200: '#C3E7E7',
    300: '#A4DADA',
    400: '#86CECE',
    500: '#68C2C2',
    600: '#539B9B',
    700: '#3E7474',
    800: '#2A4E4E',
    900: '#152727',
  },
  peach: {
    100: '#FFE9E4',
    200: '#FFD3CA',
    300: '#FFBCAF',
    400: '#FFA695',
    500: '#FF907A',
    550: '#E4755F',
    600: '#CC7362',
    700: '#995649',
    800: '#663A31',
    900: '#331D18',
  },
  red: {
    100: '#FFE2E2',
    200: '#FFC4C5',
    300: '#FDA498',
    400: '#FF898A',
    500: '#FF6C6D',
    600: '#EB3E3F',
    700: '#994141',
    800: '#662B2C',
    900: '#331616',
  },
  darkGreen: {
    100: '#CCE0E5',
    200: '#99C1CB',
    300: '#66A1B2',
    400: '#338298',
    500: '#00637E',
    600: '#004F65',
    700: '#003B4C',
    800: '#002832',
    900: '#001419',
  },
  darkMint: {
    100: '#CCF1EC',
    200: '#99E4DA',
    300: '#66D6C7',
    400: '#33C9B5',
    500: '#00BBA2',
    600: '#009682',
    700: '#007061',
    800: '#004B41',
    900: '#002520',
  },
  yellow: {
    100: '#FFF4E9',
    200: '#FFF3C8',
    300: '#FFEDAD',
    400: '#FFD4A6',
    500: '#D7C259',
    600: '#DBBE2B',
    700: '#997956',
    800: '#66503A',
    900: '#33281D',
  },
  seaBlue: {
    100: '#CCE9F0',
    200: '#99D3E1',
    300: '#66BDD3',
    400: '#33A7C4',
    500: '#0091B5',
    600: '#007491',
    700: '#00576D',
    800: '#003A48',
    900: '#001D24',
  },
  black: {
    100: '#FFFFFF',
    600: '#CCCCCC',
    700: '#999999',
    800: '#666666',
    900: '#333333',
  },
  gray: {
    50: '#FAFAFA',
    100: '#F7F6F5',
    200: '#F0EFED',
    300: '#E8E6E4',
    400: '#E1DDDA',
    500: '#D9D6D2',
    600: '#AEA9A3',
    700: '#827F7C',
    800: '#3D352C',
    900: '#2B2116',
  },
};
