import React, { useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import CheckoutContext from './CheckoutContext';
import { SessionProduct } from './common';
import SessionBookingForm from './SessionBookingForm';
import useCheckoutInit from './useCheckoutInit';

const GroupSessionBooking = ({
  page,
  session,
  onConfirmationBack,
}: {
  page: introwise.Page;
  session: introwise.GroupSession;
  onConfirmationBack: () => void;
}) => {
  const product: SessionProduct = useMemo(
    () => ({
      type: 'session',
      sessionId: session.id,
      session,
    }),
    [session],
  );

  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId');

  useCheckoutInit(product);
  const [productInitialized] = useContext(CheckoutContext);

  if (!productInitialized) {
    return <></>;
  }

  return <SessionBookingForm page={page} orderId={orderId} onConfirmationBack={onConfirmationBack} />;
};

export default GroupSessionBooking;
