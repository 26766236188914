import React from 'react';
import Linkify, { Props } from 'react-linkify';
import { AnchorStyled } from 'web/components/elements';

const linkDecorator = (href: string, text: string, key: number) => (
  <AnchorStyled href={href} key={key} target="_blank" rel="noopener noreferrer ugc">
    {text}
  </AnchorStyled>
);

const LinkifyStyled = (props: Omit<Props, 'componentDecorator'>) => (
  <Linkify componentDecorator={linkDecorator} {...props}>
    {props.children}
  </Linkify>
);

export default LinkifyStyled;
