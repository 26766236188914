import React from 'react';

type ChangeBookingContextType = {
  session: introwise.PersonalSession;
  booking: introwise.Booking;
  bookedPackage: introwise.BookedPackage;
  retrieve: () => void;
};

const ChangeBookingContext = React.createContext<ChangeBookingContextType>({
  session: null,
  booking: null,
  bookedPackage: null,
  retrieve: null,
});

export { ChangeBookingContextType };
export default ChangeBookingContext;
